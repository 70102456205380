import RestService from "./restService";
import { IMPREST } from "../../url/index";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class ImprestService extends RestService {
  constructor(enforcer) {
    super(IMPREST);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct SUBSISTENCE RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ImprestService(singletonEnforcer);
    }
    return this[singleton];
  }
  approve(imprestId, payload) {
    if (imprestId && payload && typeof payload === "object") {
      return this._api_connector.put(IMPREST.uri("processes").uri(imprestId).url, payload);
    } else throw new Error("Save API call requires Payload!");
  }
}

export default ImprestService;
