import gql from "graphql-tag";

export const WEB_TERMINAL = gql`
  query filterTerminals($criteria: TerminalFilterCriteria!) {
    filterTerminals(criteria: $criteria) {
      clockInOutAllowed
      clockInOutDescriptionRequired
      dutyInOutAllowed
      dutyInOutDescriptionRequired
      breakInOutAllowed
      breakInOutDescriptionRequired
      lunchInOutAllowed
      lunchInOutDescriptionRequired
    }
  }
`;

export const WEB_TERMINAL_STATUS = gql`
  query filterTerminals($criteria: TerminalFilterCriteria!) {
    filterTerminals(criteria: $criteria) {
      disabled
    }
  }
`;

export const PENDING_FEEDBACKS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      pendingFeedbacks {
        id
        feedback {
          id
          required
        }
      }
    }
  }
`;

export const EMPLOYEE = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      gdprAccepted
      profile {
        superAdmin
      }
    }
  }
`;

export const ORGANIZATION_DETAIL = gql`
  query organizationDetail {
    organizationDetail {
      id
      shiftSelection
      gdprClarificationTextURL
      publicURL
      isMaxImprestValueCheckEnabled
      maxImprestAmount
      maxImprestCode
      ssoCredentials {
        type
        tenantId
        clientId
        userHash
      }
      mobileSettings {
        mobileDisabled
        indoorTrackingDisabled
        timePageDisabled
        hrPageDisabled
        timesheetDisabled
        clockInOutDisabled
        breakInOutDisabled
        shiftDisabled
        leaveDisabled
        messageDisabled
        recordDisabled
        contactDisabled
        overtimeDisabled
        expenseDisabled
        imprestDisabled
        paperDisabled
        performanceDisabled
        documentDisabled
        serviceDisabled
        healthDisabled
        assetDisabled
        payrollDisabled
        activityDisabled
        trainingDisabled
        suggestionDisabled
        menuDisabled
        travelDisabled
        surveyDisabled
        debitTicketDisabled
        payrollPasswordRequired
      }
    }
  }
`;
