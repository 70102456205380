import HttpStatusEventBus from "../httpStatusEventBus";
import { CREATED } from "http-status-codes";
import { eventBus } from "../../../../plugins/event-bus";

export default (function() {
  HttpStatusEventBus.$on(CREATED.toString(), response => {
    let notification_credentials;
    if (response.config.method === "post" && response.config.url.includes("/import")) return;
    if (response.config.url.includes("/messages")) {
      return;
    } else {
      notification_credentials = {
        text: "saved_successfully",
        color: "success",
        type: "success"
      };
    }

    eventBus.$emit("snack-bar-notification", notification_credentials);
  });
})();
