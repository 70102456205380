import RestService from "./restService";
import { PAPER } from "../../url/index";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class PaperService extends RestService {
  constructor(enforcer) {
    super(PAPER.paper);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Paper RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new PaperService(singletonEnforcer);
    }
    return this[singleton];
  }

  savePaperType(payload) {
    return this._api_connector.post(PAPER.paperTypes.url, payload);
  }

  updatePaperType(payload) {
    return this._api_connector.put(PAPER.paperTypes.uri(payload.id).url, payload);
  }

  removePaperType(id) {
    if (!id) {
      throw new Error("Delete API call requires ID parameter !");
    }
    return this._api_connector.delete(PAPER.paperTypes.uri(id).url);
  }

  approve(id, payload) {
    return this._api_connector.put(PAPER.paperRequestProcess.uri(id).url, payload);
  }

  approveMultiple(id, payload) {
    return this._api_connector.put(PAPER.paperRequestProcess.uri(id).uri("multiple").url, payload);
  }

  cancel(id, payload) {
    return this._api_connector.put(PAPER.paperRequest.uri(id).uri("cancel").url, payload);
  }
}

export default PaperService;
