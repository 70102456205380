import services from "./resources/services";
import endpoints from "./resources/endpoints";
import URL from "./generators/rest_url";

export const CHANNEL_TYPE = new URL(services.channel_type, endpoints.channel_type);
export const CHANNELS = new URL(services.channels, endpoints.channels);
export const SKILL = new URL(services.skills, endpoints.skills);
export const POSITION_LEVEL = new URL(services.position_level, endpoints.position_level);
export const JOB_POSTING = new URL(services.job_posting, endpoints.job_posting);
export const JOB_POSTING_CHANNELS = new URL(services.job_posting_channels, endpoints.job_posting_channels);
export const APPLICANTS = new URL(services.applicants, endpoints.applicants);
export const ASSESSMENT = new URL(services.assessment, endpoints.assessment);
export const ASSESSMENT_DETAIL = new URL(services.assessment_detail, endpoints.assessment_detail);
export const BLACK_LIST = new URL(services.black_list, endpoints.black_list);
export const INTERVIEW = new URL(services.interview, endpoints.interview);
export const STAGES = new URL(services.stages, endpoints.stages);
export const BOARDING = new URL(services.boarding, endpoints.boarding);
export const BLACK_LIST_MANAGER = new URL(services.blacklist_manager, endpoints.blacklist_manager);
export const APPLICANT_EVALUATOR = new URL(services.applicant_evaluator, endpoints.applicant_evaluator);
