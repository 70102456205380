const LANGUAGE = Object.freeze({
  tr: "TURKISH",
  en: "ENGLISH",
  de: "GERMAN"
});

const GENDER = Object.freeze({
  male: "MALE",
  female: "FEMALE",
  other: "OTHER"
});

const LEAVE_REQUEST_DAY_TYPE = Object.freeze({
  ON_LEAVE: "ON_LEAVE",
  OFFDAY: "OFFDAY",
  HOLIDAY: "HOLIDAY",
  BREAK: "BREAK"
});

const NOTIFICATIONS = {
  TYPES: {
    EMAIL: "EMAIL",
    SMS: "SMS",
    IN_APP: "IN_APP"
  },
  IMPORTANCE: {
    LOW: "LOW",
    MEDIUM: "MEDIUM",
    HIGH: "HIGH"
  },
  COUNTS: {
    SMS: 160,
    EMAIL: 4096,
    IN_APP: 255
  }
};

const SHIFT_TYPES = {
  FIX: "FIX",
  FLEXIBLE: "FLEXIBLE",
  DYNAMIC: "DYNAMIC",
  OFF_DAY: "OFF_DAY",
  BREAK: "BREAK",
  OVERTIME: "OVERTIME"
};

const PERFORMANCE_ITEM_TYPE = {
  KPI: "KPI",
  COMPETENCE: "COMPETENCE"
};

const PERFORMANCE_EVALUATOR_TYPE = {
  SELF: "SELF",
  SAME: "SAME",
  SUPERIOR_DEPTH_1: "SUPERIOR_DEPTH_1",
  SUPERIOR_DEPTH_2: "SUPERIOR_DEPTH_2",
  SUPERIOR_DEPTH_3: "SUPERIOR_DEPTH_3",
  SUPERIOR_DEPTH_4: "SUPERIOR_DEPTH_4",
  SUPERIOR_DEPTH_5: "SUPERIOR_DEPTH_5",
  SUBORDINATE_DEPTH_1: "SUBORDINATE_DEPTH_1",
  SUBORDINATE_DEPTH_2: "SUBORDINATE_DEPTH_2",
  SUBORDINATE_DEPTH_3: "SUBORDINATE_DEPTH_3",
  SUBORDINATE_DEPTH_4: "SUBORDINATE_DEPTH_4",
  SUBORDINATE_DEPTH_5: "SUBORDINATE_DEPTH_5",
  FREE_STYLE: "FREE_STYLE"
};

const SKILL_TYPES = {
  TECHNICAL: "TECHNICAL",
  SOFT: "SOFT",
  QUESTION: "QUESTION",
  LINK: "LINK"
};
const EDUCATION_LANGUAGE = Object.freeze({
  TURKISH: "TURKISH",
  ENGLISH: "ENGLISH",
  GERMAN: "GERMAN",
  FRENCH: "FRENCH",
  DUTCH: "DUTCH",
  SPANISH: "SPANISH",
  ITALIAN: "ITALIAN",
  ARABIC: "ARABIC",
  RUSSIAN: "RUSSIAN",
  PERSIAN: "PERSIAN",
  CHINESE: "CHINESE",
  JAPANESE: "JAPANESE",
  HINDI: "HINDI",
  LATIN: "LATIN",
  PORTUGUESE: "PORTUGUESE",
  KOREAN: "KOREAN"
});

const INTERVIEW_STATUS = {
  PLANNED: "PLANNED",
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
  CANCELLED: "CANCELLED"
};

const IMPORTANCE = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH"
};

const FEEDBACK_ANSWER_TYPE = {
  SINGLE_SELECTION: "SINGLE_SELECTION",
  MULTI_SELECTION: "MULTI_SELECTION"
};

const FEELING = {
  BAD: "BAD",
  SAD: "SAD",
  NORMAL: "NORMAL",
  HAPPY: "HAPPY",
  ECSTATIC: "ECSTATIC"
};

const LEAVE_REQUEST_STATUS = Object.freeze({
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DENIED: "DENIED",
  CANCELLED: "CANCELLED",
  NEED_EXTRA_DETAIL: "NEED_EXTRA_DETAIL"
});

const PROFILE_SETTINGS = Object.freeze({
  PROFILE: "PROFILE",
  LANGUAGE_AND_ROLE: "LANGUAGE_AND_ROLE",
  CONTACT: "CONTACT",
  PERSONAL: "PERSONAL",
  FAMILY: "FAMILY",
  EDUCATION: "EDUCATION",
  ASSIGNMENT: "ASSIGNMENT",
  FINANCIAL: "FINANCIAL",
  SOCIAL_MEDIA: "SOCIAL_MEDIA",
  BODY_DIMENSIONS: "BODY_DIMENSIONS",
  MOBILE_DEVICES: "MOBILE_DEVICES"
});

const SOCIAL_SECURITY_TYPE = Object.freeze({
  SS_4A: "SS_4A",
  SS_4B: "SS_4B",
  SS_4C: "SS_4C"
});

const MOBILE_SETTINGS = Object.freeze({
  TIME_PAGE_DISABLED: "timePageDisabled",
  HR_PAGE_DISABLED: "hrPageDisabled",
  TIMESHEET_DISABLED: "timesheetDisabled",
  CLOCK_IN_OUT_DISABLED: "clockInOutDisabled",
  BREAK_IN_OUT_DISABLED: "breakInOutDisabled",
  SHIFT_DISABLED: "shiftDisabled",
  LEAVE_DISABLED: "leaveDisabled",
  MESSAGE_DISABLED: "messageDisabled",
  RECORD_DISABLED: "recordDisabled",
  CONTACT_DISABLED: "contactDisabled",
  OVERTIME_DISABLED: "overtimeDisabled",
  EXPENSE_DISABLED: "expenseDisabled",
  IMPREST_DISABLED: "imprestDisabled",
  PAPER_DISABLED: "paperDisabled",
  PERFORMANCE_DISABLED: "performanceDisabled",
  DOCUMENT_DISABLED: "documentDisabled",
  SERVICE_DISABLED: "serviceDisabled",
  HEALTH_DISABLED: "healthDisabled",
  ASSET_DISABLED: "assetDisabled",
  PAYROLL_DISABLED: "payrollDisabled",
  ACTIVITY_DISABLED: "activityDisabled",
  TRAINING_DISABLED: "trainingDisabled",
  SUGGESTION_DISABLED: "suggestionDisabled",
  MENU_DISABLED: "menuDisabled",
  TRAVEL_DISABLED: "travelDisabled",
  SURVEY_DISABLED: "surveyDisabled",
  DEBIT_TICKET_DISABLED: "debitTicketDisabled"
});

const DEBIT_REQUEST_STATUS = Object.freeze({
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DENIED: "DENIED",
  CANCELLED: "CANCELLED",
  NEED_EXTRA_DETAIL: "NEED_EXTRA_DETAIL",
  REVISION: "REVISION"
});

const SSO_IDENTITY_PROVIDER = Object.freeze({
  MICROSOFT: "microsoft",
  GOOGLE: "google",
  AMAZON: "amazon",
  VEDUBOX: "vedubox" 
});

export {
  LANGUAGE,
  GENDER,
  NOTIFICATIONS,
  SHIFT_TYPES,
  PERFORMANCE_ITEM_TYPE,
  PERFORMANCE_EVALUATOR_TYPE,
  SKILL_TYPES,
  EDUCATION_LANGUAGE,
  INTERVIEW_STATUS,
  IMPORTANCE,
  FEEDBACK_ANSWER_TYPE,
  FEELING,
  LEAVE_REQUEST_STATUS,
  PROFILE_SETTINGS,
  SOCIAL_SECURITY_TYPE,
  MOBILE_SETTINGS,
  DEBIT_REQUEST_STATUS,
  LEAVE_REQUEST_DAY_TYPE,
  SSO_IDENTITY_PROVIDER
};
