import VueRouter from "vue-router";
import store from "@store";
import { MOBILE_SETTINGS } from "../helpers/enums";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/app-layout"),
    redirect: { name: "dashboard" },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ "@pages/dashboard"),
        meta: {
          requiresAuth: true,
          sidebar: false
        }
      },
      {
        path: "human-resources",
        name: "human_resources",
        component: () => import("@pages/hr"),
        redirect: { name: "employee" },
        meta: {
          requiresAuth: true,
          sidebar: true,
          mobileSetting: MOBILE_SETTINGS.HR_PAGE_DISABLED
        },
        children: [
          {
            path: "employee",
            name: "employee",
            redirect: { name: "overview" },
            component: () => import("@pages/hr/profile"),
            meta: {
              requiresAuth: true
            },
            children: [
              {
                path: "overview",
                name: "overview",
                component: () => import("@pages/hr/profile/overview"),
                meta: {
                  layout: "app-layout",
                  requiresAuth: true
                }
              },
              {
                path: "profile",
                name: "profile",
                component: () => import("@pages/hr/profile/profile"),
                meta: {
                  layout: "app-layout",
                  requiresAuth: true
                }
              },
              {
                path: "language-role",
                name: "language_role",
                component: () => import("@pages/hr/profile/language_role"),
                meta: {
                  layout: "app-layout",
                  requiresAuth: true
                }
              },
              {
                path: "contact",
                name: "contact",
                component: () => import("@pages/hr/profile/contact"),
                meta: {
                  layout: "app-layout",
                  requiresAuth: true
                }
              },
              {
                path: "personal",
                name: "personal",
                component: () => import("@pages/hr/profile/personal"),
                meta: {
                  layout: "app-layout",
                  requiresAuth: true
                }
              },
              {
                path: "family",
                name: "family",
                component: () => import("@pages/hr/profile/family"),
                meta: {
                  layout: "app-layout",
                  requiresAuth: true
                }
              },
              {
                path: "education",
                name: "education",
                component: () => import("@pages/hr/profile/education"),
                meta: {
                  layout: "app-layout",
                  requiresAuth: true
                }
              },
              {
                path: "employment",
                name: "employment",
                component: () => import("@pages/hr/profile/employment"),
                meta: {
                  layout: "app-layout",
                  requiresAuth: true
                }
              },
              {
                path: "financial",
                name: "financial",
                component: () => import("@pages/hr/profile/financial"),
                meta: {
                  layout: "app-layout",
                  requiresAuth: true
                }
              },
              {
                path: "social-media",
                name: "social_media",
                component: () => import("@pages/hr/profile/social_media"),
                meta: {
                  layout: "app-layout",
                  requiresAuth: true
                }
              },
              {
                path: "body-size",
                name: "body_size",
                component: () => import("@pages/hr/profile/body_size"),
                meta: {
                  layout: "app-layout",
                  requiresAuth: true
                }
              },
              {
                path: "mobile-device",
                name: "mobile_device",
                component: () => import("@pages/hr/profile/mobile_device"),
                meta: {
                  layout: "app-layout",
                  requiresAuth: true
                }
              },
              {
                path: "managers",
                name: "managers",
                component: () => import("@pages/hr/profile/managers"),
                meta: {
                  layout: "app-layout",
                  requiresAuth: true
                }
              }
            ]
          },
          {
            path: "performances",
            component: () => import("@pages/hr/performance"),
            name: "performances",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.PERFORMANCE_DISABLED
            },
            children: [
              {
                path: ":id/evaluatee",
                component: () => import("@/pages/hr/performance/evaluatee"),
                name: "performance_evaluatee",
                props: true,
                meta: {
                  requiresAuth: true
                }
              },
              {
                path: ":id/result",
                component: () => import("@/pages/hr/performance/result"),
                name: "performance_result",
                props: true,
                meta: {
                  requiresAuth: true
                },
                beforeEnter: (to, from, next) => {
                  if (from.name !== "performances") {
                    next({ name: "performances" });
                  } else {
                    next();
                  }
                }
              }
            ]
          },
          {
            path: "hiring",
            component: () => import("@pages/hr/hiring"),
            name: "hiring",
            meta: {
              requiresAuth: true
            },
            children: [
              {
                path: ":id/result",
                component: () => import("@/pages/hr/hiring/result"),
                name: "hiring_result",
                props: true,
                meta: {
                  requiresAuth: true
                }
              },
              {
                path: ":id/evaluatee",
                component: () => import("@/pages/hr/hiring/evaluatee"),
                name: "hiring_evaluatee",
                props: true,
                meta: {
                  requiresAuth: true
                }
              }
            ]
          },
          {
            path: "training/trainings",
            component: () => import("@pages/hr/training/trainings"),
            name: "trainings",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.TRAINING_DISABLED
            },
            children: [
              {
                name: "trainings.detail",
                path: ":id/detail",
                component: () => import("@pages/hr/training/trainings/detail"),
                props: true
              }
            ]
          },
          {
            path: "training/requests",
            component: () => import("@pages/hr/training/requests"),
            name: "training_request",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.TRAINING_DISABLED
            },
            children: [
              {
                path: "create",
                name: "training_request_create",
                component: () => import("@pages/hr/training/requests/create"),
                meta: {
                  requiresAuth: true
                }
              }
            ]
          },
          {
            path: "training/request-history",
            component: () => import("@pages/hr/training/approval-requests"),
            name: "training_request_history",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.TRAINING_DISABLED
            }
          },
          {
            path: "expense/expenses",
            component: () => import("@pages/hr/expense/expense"),
            name: "expenses",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.EXPENSE_DISABLED
            },
            children: [
              {
                path: "create",
                name: "expense_create",
                component: () => import("@pages/hr/expense/expense/create"),
                meta: {
                  requiresAuth: true
                }
              }
            ]
          },
          {
            path: "expense/request-history",
            component: () => import("@pages/hr/expense/request-history"),
            name: "expense_request_history",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.EXPENSE_DISABLED
            }
          },
          {
            path: "imprest/imprests",
            component: () => import("@pages/hr/imprest/imprest"),
            name: "imprests",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.IMPREST_DISABLED
            },
            children: [
              {
                path: "create",
                name: "imprest_create",
                component: () => import("@pages/hr/imprest/imprest/create"),
                meta: {
                  requiresAuth: true
                }
              }
            ]
          },
          {
            path: "imprest/request-history",
            component: () => import("@pages/hr/imprest/request-history"),
            name: "imprest_request_history",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.IMPREST_DISABLED
            }
          },
          {
            path: "paper/papers",
            component: () => import("@pages/hr/paper/papers"),
            name: "papers",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.PAPER_DISABLED
            },
            children: [
              {
                path: "create",
                component: () => import("@/pages/hr/paper/papers/create"),
                name: "papers_create",
                props: true,
                meta: {
                  requiresAuth: true
                }
              }
            ]
          },
          {
            path: "paper/request-history",
            component: () => import("@pages/hr/paper/request-history"),
            name: "paper_request_history",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.PAPER_DISABLED
            }
          },
          {
            path: "documents",
            component: () => import("@pages/hr/documents"),
            name: "documents",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.DOCUMENT_DISABLED
            },
            children: [
              {
                path: "create",
                component: () => import("@/pages/hr/documents/create"),
                name: "document_create",
                props: true,
                meta: {
                  requiresAuth: true
                }
              }
            ]
          },

          {
            path: "health",
            component: () => import("@pages/hr/health"),
            name: "health",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.HEALTH_DISABLED
            },
            children: [
              {
                path: ":id/detail",
                name: "health.detail",
                component: () => import("@pages/hr/health/detail"),
                props: true
              }
            ]
          },
          {
            path: "debit-ticket/debits",
            component: () => import("@pages/hr/debit-ticket/debits"),
            name: "debit_tickets",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.DEBIT_TICKET_DISABLED
            }
          },
          {
            path: "debit-ticket/vehicles",
            component: () => import("@pages/hr/debit-ticket/vehicles"),
            name: "vehicles",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.DEBIT_TICKET_DISABLED
            }
          },
          {
            path: "payroll",
            component: () => import("@pages/hr/payroll"),
            name: "payroll",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.PAYROLL_DISABLED
            }
          },
          {
            path: "events",
            component: () => import("@pages/hr/events"),
            name: "events",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.ACTIVITY_DISABLED
            }
          },
          {
            path: "expense/pending-requests",
            component: () => import("@pages/hr/expense/pending-requests"),
            name: "expense_approval",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.EXPENSE_DISABLED
            }
          },
          {
            path: "expense/all-pending-requests",
            component: () => import("@pages/hr/expense/all-pending-requests"),
            name: "expense_approval_all",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.EXPENSE_DISABLED
            }
          },
          {
            path: "imprest/pending-requests",
            component: () => import("@pages/hr/imprest/pending-requests"),
            name: "imprest_approval",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.IMPREST_DISABLED
            }
          },
          {
            path: "imprest/all-pending-requests",
            component: () => import("@pages/hr/imprest/all-pending-requests"),
            name: "imprest_approval_all",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.IMPREST_DISABLED
            }
          },
          {
            path: "messages",
            component: () => import("@pages/hr/message"),
            name: "messages",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.MESSAGE_DISABLED
            },
            redirect: { name: "message_inbox" },
            children: [
              {
                path: "inbox",
                component: () => import("@pages/hr/message/inbox"),
                name: "message_inbox",
                meta: {
                  requiresAuth: true
                }
              },
              {
                path: "outbox",
                component: () => import("@pages/hr/message/outbox"),
                name: "message_outbox",
                meta: {
                  requiresAuth: true
                }
              }
            ]
          },
          {
            path: "suggestions",
            component: () => import("@pages/hr/suggestion"),
            name: "suggestions",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.SUGGESTION_DISABLED
            },
            children: [
              {
                path: "create",
                name: "suggestion_create",
                component: () => import("@pages/hr/suggestion/create"),
                meta: {
                  requiresAuth: true
                }
              }
            ]
          },
          {
            path: "notes",
            component: () => import("@pages/hr/note"),
            name: "notes",
            meta: {
              requiresAuth: true
            },
            children: [
              {
                path: "create",
                name: "note_create",
                component: () => import("@pages/hr/note/create"),
                meta: {
                  requiresAuth: true
                }
              }
            ]
          },
          {
            path: "travel/travels",
            component: () => import("@pages/hr/travel/travel"),
            name: "travels",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.TRAVEL_DISABLED
            },
            children: [
              {
                path: "create",
                name: "travel_create",
                component: () => import("@pages/hr/travel/travel/create"),
                meta: {
                  requiresAuth: true
                }
              }
            ]
          },
          {
            path: "travel/request-history",
            component: () => import("@pages/hr/travel/request-history"),
            name: "travel_request_history",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.TRAVEL_DISABLED
            }
          },
          {
            path: "travel/pending-requests",
            component: () => import("@pages/hr/travel/pending-requests"),
            name: "travel_approval",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.TRAVEL_DISABLED
            }
          },
          {
            path: "travel/all-pending-requests",
            component: () => import("@pages/hr/travel/all-pending-requests"),
            name: "travel_approval_all",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.TRAVEL_DISABLED
            }
          },
          {
            path: "menus",
            component: () => import("@pages/hr/menu"),
            name: "menus",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.MENU_DISABLED
            }
          },
          {
            path: "contact",
            component: () => import("@pages/hr/contact"),
            name: "contacts",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.CONTACT_DISABLED
            }
          },
          {
            path: "debit-ticket/pending-requests",
            component: () => import("@pages/hr/debit-ticket/pending-requests"),
            name: "debit_ticket_transfer_approval",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.DEBIT_TICKET_DISABLED
            }
          },
          {
            path: "training/pending-requests",
            component: () => import("@pages/hr/training/pending-requests"),
            name: "training_approval",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.TRAINING_DISABLED
            }
          },
          {
            path: "training/all-pending-requests",
            component: () => import("@pages/hr/training/all-pending-requests"),
            name: "training_approval_all",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.TRAINING_DISABLED
            }
          },
          {
            path: "paper/pending-requests",
            component: () => import("@pages/hr/paper/pending-requests"),
            name: "paper_approval",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.PAPER_DISABLED
            }
          },
          {
            path: "paper/all-pending-requests",
            component: () => import("@pages/hr/paper/all-pending-requests"),
            name: "paper_approval_all",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.PAPER_DISABLED
            }
          },
          {
            path: "surveys/pending",
            component: () => import("@pages/hr/survey/pending"),
            name: "survey_pending",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.SURVEY_DISABLED
            },
            children: [
              {
                path: ":id/start",
                name: "survey_pending_start",
                component: () => import("@pages/hr/survey/pending/start"),
                props: true
              }
            ]
          },
          {
            path: "surveys/completed",
            component: () => import("@pages/hr/survey/completed"),
            name: "survey_completed",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.SURVEY_DISABLED
            }
          },
          {
            path: "surveys/expired",
            component: () => import("@pages/hr/survey/expired"),
            name: "survey_expired",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.SURVEY_DISABLED
            }
          }
        ]
      },
      {
        path: "time",
        component: () => import("@pages/time"),
        name: "time",
        redirect: { name: "shifts" },
        meta: {
          requiresAuth: true,
          sidebar: true,
          mobileSetting: MOBILE_SETTINGS.TIME_PAGE_DISABLED
        },
        children: [
          {
            path: "shifts",
            component: () => import("@pages/time/shifts"),
            name: "shifts",
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.SHIFT_DISABLED
            }
          },
          {
            path: "timesheets",
            name: "timesheets",
            component: () => import("@pages/time/timesheet"),
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.TIMESHEET_DISABLED
            }
          },
          {
            path: "overtime-request",
            name: "overtime_request",
            component: () => import("@pages/time/overtime_request"),
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.OVERTIME_DISABLED
            },
            children: [
              {
                path: "create",
                component: () => import("@pages/time/overtime_request/create"),
                name: "overtime_request_create",
                meta: {
                  requiresAuth: true
                }
              }
            ]
          },
          {
            path: "overtime/pending-requests",
            name: "overtime_approval",
            component: () => import("@pages/time/overtime/pending-requests"),
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.OVERTIME_DISABLED
            }
          },
          {
            path: "overtime/all-pending-requests",
            name: "overtime_approval_all",
            component: () => import("@pages/time/overtime/all-pending-requests"),
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.OVERTIME_DISABLED
            }
          },
          {
            path: "shif/pending-requests",
            name: "shift_approval",
            component: () => import("@pages/time/shift/pending-requests"),
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.OVERTIME_DISABLED
            }
          },
          {
            path: "shif/all-pending-requests",
            name: "shift_approval_all",
            component: () => import("@pages/time/shift/all-pending-requests"),
            meta: {
              requiresAuth: true,
              mobileSetting: MOBILE_SETTINGS.OVERTIME_DISABLED
            }
          }
        ]
      },
      {
        path: "/leave",
        name: "leave",
        redirect: { name: "leave_requests" },
        component: () => import("@pages/leave"),
        meta: {
          requiresAuth: true,
          sidebar: true,
          mobileSetting: MOBILE_SETTINGS.LEAVE_DISABLED
        },
        children: [
          {
            path: "leave-accruals",
            component: () => import("@pages/leave/accruals"),
            name: "leave_accruals",
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "leave-requests",
            component: () => import("@pages/leave/requests"),
            name: "leave_requests",
            meta: {
              requiresAuth: true
            },
            children: [
              {
                path: "create",
                name: "create_leave_request",
                component: () => import("@pages/leave/requests/create"),
                meta: {
                  requiresAuth: true
                }
              }
            ]
          },
          {
            path: "pending-requests",
            component: () => import("@pages/leave/pending-requests"),
            name: "leave_approvals",
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "all-pending-requests",
            component: () => import("@pages/leave/all-pending-requests"),
            name: "leave_approvals_all",
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: "/page-not-found",
        name: "pageNotFound",
        component: () => import("@pages/page/not_found"),
        meta: {
          layout: "app-layout",
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/auth/",
    component: () => import("@/layouts/auth-layout"),
    redirect: { name: "login" },
    children: [
      {
        path: "login",
        name: "login",
        props: true,
        component: () => import(/* webpackChunkName: "login" */ "@pages/auth/login"),
        meta: {
          layout: "auth-layout",
          requiresAuth: false
        }
      },
      {
        path: "forgot-password",
        name: "forgot",

        component: () => import(/* webpackChunkName: "forgot-password" */ "@pages/auth/forgot-password"),
        meta: {
          layout: "auth-layout",
          requiresAuth: false
        }
      },
      {
        path: "logout",
        name: "logout",
        component: () => import(/* webpackChunkName: "logout" */ "@pages/auth/forgot-password"),
        meta: {
          layout: "auth-layout",
          requiresAuth: true
        }
      },
      {
        path: "first-login",
        name: "first_login",
        component: () => import(/* webpackChunkName: "first-login" */ "@pages/auth/first-login"),
        meta: {
          layout: "auth-layout",
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: "/pending-feedbacks",
    component: () => import("@/layouts/app-layout"),
    redirect: { name: "pending_feedbacks" },
    children: [
      {
        path: "",
        name: "pending_feedbacks",
        component: () => import("@pages/pending-feedbacks"),
        meta: {
          requiresAuth: true,
          sidebar: false
        }
      }
    ]
  },
  {
    path: "/page",
    component: () => import("@/layouts/page-layout"),
    redirect: { name: "download" },
    meta: {
      layout: "page-layout",
      sidebar: false,
      requiresAuth: true
    },
    children: [
      {
        path: "/download",
        component: () => import("@/pages/download"),
        name: "download",
        props: true,
        meta: {
          layout: "page-layout",
          sidebar: false,
          requiresAuth: true
        }
      }
    ]
  }
];

const findRouteByName = (name, routes) => {
  for (let route of routes) {
    if (route.name === name) return route;
    if (Array.isArray(route.children)) {
      const tempRoute = findRouteByName(name, route.children);
      if (tempRoute) return tempRoute;
    }
  }
  return null;
};

export const router = new VueRouter({
  mode: "history",
  routes
});
const monkeyPatchingMethods = ["push", "replace"];
monkeyPatchingMethods.forEach((method) => {
  const routerMethod = VueRouter.prototype[method];
  VueRouter.prototype[method] = (location, onComplete, onAbort) => {
    if (!onComplete && !onAbort && typeof Promise !== "undefined") {
      return routerMethod.call(router, location, onComplete, onAbort).catch((error) => {});
    } else {
      routerMethod.call(router, location, onComplete, onAbort);
    }
  };
});

let redirectHistory = [];
const checkedRouteNames = ["human_resources", "time", "leave"];

router.beforeEach((to, from, next) => {
  const disabled = to.matched.some((record) => store.getters["auth/routeDisabled"](record.meta.mobileSetting));
  const required = to.matched.some((record) => record.meta.requiresAuth);
  const loggedIn = store.state.auth.loggedIn;
  const requiredPendingFeedbackCount = store.state.auth.requiredPendingFeedbackCount;

  if (required && !loggedIn) {
    return next({
      name: "login",
      params: { redirect: to.fullPath }
    });
  } else {
    if (store.state.auth.passwordExpired) {
      if (to.name === "first_login") return next();
      return next({ name: "first_login" });
    }
    if (requiredPendingFeedbackCount > 0 && to.name !== "pending_feedbacks") {
      return next({ name: "pending_feedbacks" });
    } else if (disabled && loggedIn) {
      const matchedRoute = to.matched.find((route) => checkedRouteNames.includes(route.name));
      if (matchedRoute && matchedRoute.redirect) {
        redirectHistory.push(matchedRoute.redirect.name);
        const route = findRouteByName(matchedRoute.name, routes);
        if (route && Array.isArray(route.children)) {
          for (let childRoute of route.children) {
            if (!redirectHistory.includes(childRoute.name)) {
              redirectHistory.push(childRoute.name);
              return next({ name: childRoute.name });
            }
          }
        }
      }
      redirectHistory = [];
      return next({
        name: "dashboard"
      });
    } else if (!disabled) {
      redirectHistory = [];
    }
    next();
  }

  if (loggedIn && !required) {
    if (requiredPendingFeedbackCount > 0 && to.name !== "pending_feedbacks") {
      return next({ name: "pending_feedbacks" });
    }
    return next({
      name: "dashboard"
    });
  }
});
export default ({ app, Vue }) => {
  app["router"] = router;
  Vue.use(VueRouter);
};
