import RestService from "./restService";
import { CLOCK_RECORDS } from "../../url";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class ClockRecordService extends RestService {
  constructor(enforcer) {
    super(CLOCK_RECORDS);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Clock Records RestService");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ClockRecordService(singletonEnforcer);
    }
    return this[singleton];
  }

  saveClock(payload) {
    return this._api_connector.post(this._service_api.uri("mobile-terminal").url, payload);
  }
}

export default ClockRecordService;
