import RestService from "./restService";
import { EMPLOYEE_SERVICES, PERFORMANCE_SERVICES } from "../../url/index";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class PerformanceService extends RestService {
  constructor(enforcer) {
    super(PERFORMANCE_SERVICES.performanceGroups);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Performance RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new PerformanceService(singletonEnforcer);
    }
    return this[singleton];
  }

  saveScore(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(PERFORMANCE_SERVICES.performanceScoreSettings.url, payload);
    } else throw new Error("Save API call requires Payload!");
  }

  updateScore(payload) {
    if (typeof payload === "object" && payload.id) {
      return this._api_connector.put(PERFORMANCE_SERVICES.performanceScoreSettings.uri(payload.id).url, payload);
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  deleteScore(id) {
    if (id) {
      return this._api_connector.delete(PERFORMANCE_SERVICES.performanceScoreSettings.uri(id).url);
    } else {
      throw new Error("Update API call requires ID!");
    }
  }

  saveTemplate(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(PERFORMANCE_SERVICES.performanceTemplate.url, payload);
    } else throw new Error("Save API call requires Payload!");
  }

  updateTemplate(payload) {
    if (typeof payload === "object" && payload.id) {
      return this._api_connector.put(PERFORMANCE_SERVICES.performanceTemplate.uri(payload.id).url, payload);
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  deleteTemplate(id) {
    if (id) {
      return this._api_connector.delete(PERFORMANCE_SERVICES.performanceTemplate.uri(id).url);
    } else {
      throw new Error("Update API call requires ID!");
    }
  }

  saveCategory(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(PERFORMANCE_SERVICES.performanceCategory.url, payload);
    } else throw new Error("Save API call requires Payload!");
  }

  updateCategory(payload) {
    if (typeof payload === "object" && payload.id) {
      return this._api_connector.put(PERFORMANCE_SERVICES.performanceCategory.uri(payload.id).url, payload);
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  deleteCategory(id) {
    if (id) {
      return this._api_connector.delete(PERFORMANCE_SERVICES.performanceCategory.uri(id).url);
    } else {
      throw new Error("Update API call requires ID!");
    }
  }

  // Performance Items
  saveItem(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(PERFORMANCE_SERVICES.performanceItems.url, payload);
    } else throw new Error("Save API call requires Payload!");
  }

  updateItem(payload) {
    if (typeof payload === "object" && payload.id) {
      return this._api_connector.put(PERFORMANCE_SERVICES.performanceItems.uri(payload.id).url, payload);
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  deleteItem(id) {
    if (id) {
      return this._api_connector.delete(PERFORMANCE_SERVICES.performanceItems.uri(id).url);
    } else {
      throw new Error("Update API call requires ID!");
    }
  }

  // Performance Items
  saveEvaluation(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(PERFORMANCE_SERVICES.performanceEvaluation.url, payload);
    } else throw new Error("Save API call requires Payload!");
  }

  updateEvaluation(payload) {
    if (typeof payload === "object" && payload.id) {
      return this._api_connector.put(PERFORMANCE_SERVICES.performanceEvaluation.uri(payload.id).url, payload);
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  deleteEvaluation(id) {
    if (id) {
      return this._api_connector.delete(PERFORMANCE_SERVICES.performanceEvaluation.uri(id).url);
    } else {
      throw new Error("Update API call requires ID!");
    }
  }

  publishEvaluation(id) {
    if (typeof id === "string") {
      return this._api_connector.put(PERFORMANCE_SERVICES.performanceEvaluation.uri(id).uri("publish").url);
    } else {
      throw new Error("Update API call requires ID!");
    }
  }

  updateEvaluationResult(payload) {
    return this._api_connector.put(PERFORMANCE_SERVICES.performanceEvaluation.uri("result/category/item").url, payload);
    /*  if (typeof payload === 'array') {

        } else {
            throw new Error('Update API call requires ID and Payload!')
        }*/
  }
}

export default PerformanceService;
