import HttpStatusEventBus from "../../httpStatusEventBus";
import { UNSUPPORTED_MEDIA_TYPE } from "../index";
import { eventBus } from "../../../../../plugins/event-bus";

function unsupported_media_type() {
  /*HttpStatusEventBus.$on(UNSUPPORTED_MEDIA_TYPE.toString(),(response)=>{
    let notification_credentials = {
      text: 'unsupported_file_type',
      color: 'error',
      type:'error'
    };
    EventBus.$emit('snack-bar-notification', notification_credentials)
  });*/
}
export default unsupported_media_type();
