import EmployeeService from "./rest/employeeService";
import LeaveRequestService from "./rest/leaveRequestService";
import PaperService from "./rest/paperService";
import MessageService from "./rest/message.service";
import UserService from "./rest/user.service";
import WorksheetService from "./rest/worksheet.service";
import TimesheetService from "./rest/timesheet.service";
import PerformanceService from "./rest/performance.service";
import WorkplanService from "./rest/workplanService";
import PayrollService from "./rest/payrollService";
import SubsistenceService from "./rest/subsistance.service";
import ClockRecordService from "./rest/clock-record.service";
import AuthenticationService from "./rest/authentication.service";
import StorageService from "./rest/storage.service";
import ExpenseService from "./rest/expense.service";
import ImprestService from "./rest/imprest.service";
import OvertimeService from "./rest/overtime.service";
import DocumentService from "./rest/document.service";
import SuggestionService from "./rest/suggestion.service";
import NoteService from "./rest/note.service";
import FeedbackResultService from "./rest/feedbackResult.service";
import TravelService from "./rest/travel.service";
import DebitTicketService from "./rest/debitTicket.service";
import TrainingRequestService from "./rest/trainingRequest.service";
import SurveyService from "./rest/survey.service";
import WorkplanRequestService from "./rest/workplanRequest.service";
import ShiftService from "./rest/shift.service";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 * @author   <halil.kilicarslan@globme.com.tr>
 * @description SERVICE CONTEXT IS SINGLETON ACCESSOR TO IDENFIT SERVICE INSTANCES
 */
class ServiceContext {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error("Cannot construct RestService Facade more than one");
    }
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ServiceContext(singletonEnforcer);
    }
    return this[singleton];
  }

  get employeeService() {
    return EmployeeService.instance;
  }

  get leaveRequestService() {
    return LeaveRequestService.instance;
  }

  get paperService() {
    return PaperService.instance;
  }

  get performanceService() {
    return PerformanceService.instance;
  }

  get messageService() {
    return MessageService.instance;
  }

  get timesheetService() {
    return TimesheetService.instance;
  }

  get userService() {
    return UserService.instance;
  }

  get worksheetService() {
    return WorksheetService.instance;
  }

  get timeSheetService() {
    return TimesheetService.instance;
  }

  get workplanService() {
    return WorkplanService.instance;
  }

  get payrollService() {
    return PayrollService.instance;
  }

  get subsistenceService() {
    return SubsistenceService.instance;
  }

  get clockRecordService() {
    return ClockRecordService.instance;
  }

  get authenticationService() {
    return AuthenticationService.instance;
  }

  get storageService() {
    return StorageService.instance;
  }

  get expenseService() {
    return ExpenseService.instance;
  }

  get imprestService() {
    return ImprestService.instance;
  }

  get overtimeService() {
    return OvertimeService.instance;
  }

  get documentService() {
    return DocumentService.instance;
  }

  get suggestionService() {
    return SuggestionService.instance;
  }

  get noteService() {
    return NoteService.instance;
  }

  get feedbackResultService() {
    return FeedbackResultService.instance;
  }

  get travelService() {
    return TravelService.instance;
  }

  get debitTicketService() {
    return DebitTicketService.instance;
  }

  get trainingRequestService() {
    return TrainingRequestService.instance;
  }

  get surveyService() {
    return SurveyService.instance;
  }

  get workplanRequestService() {
    return WorkplanRequestService.instance;
  }

  get shiftService() {
    return ShiftService.instance;
  }
}

export default ({ Vue }) => {
  Object.defineProperty(Vue.prototype, "$api", {
    get() {
      return ServiceContext.instance;
    }
  });
};
