import HttpStatusEventBus from "../../httpStatusEventBus";
import { HTTP_MESSAGE_NOT_READABLE } from "../index";
import { eventBus } from "../../../../../plugins/event-bus";

function http_message_not_readable() {
  HttpStatusEventBus.$on(HTTP_MESSAGE_NOT_READABLE.toString(), response => {
    let notification_credentials = {
      text: "http_message_not_readable",
      color: "error",
      type: "error"
    };
    eventBus.$emit("snack-bar-notification", notification_credentials);
  });
}

export default http_message_not_readable();
