import { extend, localize, setInteractionMode } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { isValidNumber } from "libphonenumber-js";
import tr from "vee-validate/dist/locale/tr";
import en from "vee-validate/dist/locale/en";
import { i18n } from "@/plugins/i18n";
import { storageManager } from "../plugins/storage-manager";
import { LANGUAGE_KEY } from "../base/constants";

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule]
  });
});

extend("integer", {
  ...rules.integer,
  message: (_, values) => {
    return i18n.t("validations.messages.integer", values);
  }
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: (_, values) => {
    return i18n.t("validations.messages.password_match", values);
  }
});

extend("phone", {
  validate(value) {
    return isValidNumber(value);
  },
  message: (_, values) => {
    return i18n.t("validations.messages.phone", values);
  }
});

localize({
  en,
  tr
});

setInteractionMode("passive");

localize(storageManager.get(LANGUAGE_KEY) || "tr");
