import RestService from "./restService";
import { DEBIT_TICKET } from "../../url/index";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class DebitTicketService extends RestService {
  constructor(enforcer) {
    super(DEBIT_TICKET);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct DebitTicket RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new DebitTicketService(singletonEnforcer);
    }
    return this[singleton];
  }

  transferRequest(debitTicket, approver) {
    if (debitTicket && approver) {
      return this._api_connector.put(
        DEBIT_TICKET.uri("transfer-request")
          .uri(debitTicket)
          .uri(approver).url
      );
    } else {
      throw new Error("Save API call requires Payload!");
    }
  }

  transferApproval(id, status) {
    if (id && status !== null && status !== undefined) {
      return this._api_connector.put(
        DEBIT_TICKET.uri("transfer-approval")
          .uri(id)
          .uri(status).url
      );
    } else {
      throw new Error("Save API call requires Payload!");
    }
  }

  debitTicketForm(debitTicket) {
    if (debitTicket) {
      return this._api_connector.post(
        DEBIT_TICKET.uri("reports")
          .uri("debit-ticket-form")
          .uri(debitTicket).url,
        null,
        {
          responseType: "blob"
        }
      );
    } else {
      throw new Error("Save API call requires Payload!");
    }
  }
}

export default DebitTicketService;
