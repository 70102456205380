import HttpStatusEventBus from "../../httpStatusEventBus";
import { INVALID_METHOD_ARGUMENT } from "../index";
import { eventBus } from "../../../../../plugins/event-bus";

function invalid_method_arguament() {
  HttpStatusEventBus.$on(INVALID_METHOD_ARGUMENT.toString(), response => {
    let notification_credentials = {
      text: "invalid_method_argument",
      color: "error",
      type: "error"
    };
    eventBus.$emit("snack-bar-notification", notification_credentials);
  });
}
export default invalid_method_arguament();
