import HttpStatusEventBus from "../../httpStatusEventBus";
import { NO_REFRESH_TOKEN } from "../index";

function no_refresh_token() {
  HttpStatusEventBus.$on(NO_REFRESH_TOKEN.toString(), response => {
    // TokenUtils.removeToken();
    // TokenUtils.removeRefreshToken();
    // router.replace({name:'login'})
  });
}
