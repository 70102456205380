import RestService from "./restService";
import { SUGGESTIONS } from "../../url/index";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class SuggestionService extends RestService {
  constructor(enforcer) {
    super(SUGGESTIONS);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Suggestion RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new SuggestionService(singletonEnforcer);
    }
    return this[singleton];
  }
}

export default SuggestionService;
