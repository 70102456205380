const TOKEN_KEY = process.env.VUE_APP_ACCESS_TOKEN_KEY;
const REFRESH_TOKEN_KEY = process.env.VUE_APP_REFRESH_TOKEN_KEY;
const LANG_KEY = process.env.VUE_APP_LANG_KEY;
import Vue from "vue";

/**
 * @description StoreHelper class is the facade helper for the localStorage API
 * @author halil.kilicarslan@globme.com.tr
 */
const TokenUtils = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  saveToken(tokens) {
    let token = tokens.accessToken.replace("Bearer ", "");
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refreshToken);
  },
  updateToken(token) {
    let accessToken = token.accessToken.replace("Bearer ", "");
    localStorage.setItem(TOKEN_KEY, accessToken);
  },
  updateAccessToken(accessToken) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  saveOrganizationId(accessToken) {
    let token = accessToken.replace("Bearer ", "");
    localStorage.setItem("organization", this.getOrganizationId(token));
  },

  saveUserProfile(user) {
    localStorage.setItem("user", JSON.stringify(user));
  },

  saveUserLanguage(language) {
    let lang;
    !language ? (lang = "en") : (lang = language);
    localStorage.setItem(LANG_KEY, lang);
    sessionStorage.setItem(LANG_KEY, lang);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },

  removeUser() {
    localStorage.removeItem("user");
  },

  removeAllStorage() {
    localStorage.clear();
    sessionStorage.clear();
  },

  getOrganizationId(token) {
    let decode = Vue.$jwt.decode(token.replace("Bearer ", ""));
    if (decode) {
      return decode.organization;
    } else {
      return null;
    }
  }
};

export { TokenUtils };
