/**
 * @entity Directives
 * @author halil.kilicarslan@globme.com.tr
 * @description
 * @date 10.12.2019
 * @time 09:38
 */
import Vue from "vue";
import moment from "@/plugins/moment";
import DecimalOrNumericInput from "./DecimalOrNumericInput";

const datePattern = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).datePattern : "YYYY-MM-DD";
Vue.directive("uppercase", {
  inserted: function(el, _, vnode) {
    el.addEventListener("input", async function(e) {
      e.target.value = e.target.value.toUpperCase();
      vnode.componentInstance.$emit("input", e.target.value.toUpperCase());
    });
  }
});
Vue.filter("formattedTime", function(value) {
  if (value) {
    let format = moment.localeData().longDateFormat("LTS");
    return moment.utc(value).format(format);
  }
});
Vue.filter("formattedDate", function(value) {
  if (value) {
    let format = moment.localeData().longDateFormat("LL");
    return moment.utc(value).format(format);
  } else {
    return "-";
  }
});
Vue.filter("formattedDateTime", function(value) {
  if (value) {
    return moment(String(value)).format(datePattern + " HH:mm:ss");
  }
});
Vue.filter("formattedDateTimeMinute", function(value) {
  if (value) {
    let format = moment.localeData().longDateFormat("LLL");
    return moment.utc(value).format(format);
  }
});
Vue.filter("month", function(value) {
  if (value) {
    return moment(String(value)).format("MMMM");
  }
});

Vue.use(DecimalOrNumericInput);

// Vue.directive("numeric", {
//   bind(el, binding) {
//     el.addEventListener("keydown", e => {
//       // delete, backpsace, tab, escape, enter,
//       let special = [46, 8, 9, 27, 13];
//       if (binding.modifiers["decimal"]) {
//         // decimal(numpad), period
//         special.push(110, 190);
//       }
//       // special from above
//       if (
//         special.indexOf(e.keyCode) !== -1 ||
//         // Ctrl+A
//         (e.keyCode === 65 && e.ctrlKey === true) ||
//         // Ctrl+C
//         (e.keyCode === 67 && e.ctrlKey === true) ||
//         // Ctrl+X
//         (e.keyCode === 88 && e.ctrlKey === true) ||
//         // home, end, left, right
//         (e.keyCode >= 35 && e.keyCode <= 39)
//       ) {
//         return; // allow
//       }
//       if (
//         binding.modifiers["alpha"] &&
//         // a-z/A-Z
//         e.keyCode >= 65 &&
//         e.keyCode <= 90
//       ) {
//         return; // allow
//       }
//       if (
//         binding.modifiers["number"] &&
//         // number keys without shift
//         ((!e.shiftKey && e.keyCode >= 48 && e.keyCode <= 57) ||
//           // numpad number keys
//           (e.keyCode >= 96 && e.keyCode <= 105))
//       ) {
//         return; // allow
//       }
//       // otherwise stop the keystroke
//       e.preventDefault(); // prevent
//     }); // end addEventListener
//   } // end bind
// });
