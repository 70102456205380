import RestService from "./restService";
import { APPLICANT_EVALUATOR } from "../../url/hiring";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class ApplicantEvaluatorService extends RestService {
  constructor(enforcer) {
    super(APPLICANT_EVALUATOR);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Agency RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ApplicantEvaluatorService(singletonEnforcer);
    }
    return this[singleton];
  }

  updateEvaluators(assessmentId, payload) {
    if (assessmentId && payload) {
      return this._api_connector.patch(
        APPLICANT_EVALUATOR.uri("update")
          .uri("assessment")
          .uri(assessmentId).url,
        payload
      );
    } else throw new Error("Save API call requires Payloads!");
  }
}

export default ApplicantEvaluatorService;
