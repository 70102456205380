export const getUserLocale = () => {
  const language = localStorage.getItem("language");
  const localesMap = {
    en: "en",
    tr: "tr",
    de: "de",
    esp: "es"
  };

  return localesMap[language] || process.env.VUE_APP_I18N_FALLBACK_LOCALE;
};
