import RestService from "./restService";
import { JOB_POSTING_CHANNELS } from "../../url/hiring";
const singleton = Symbol();
const singletonEnforcer = Symbol();
/**
 *
 */
class JobPostingChannelsService extends RestService {
  constructor(enforcer) {
    super(JOB_POSTING_CHANNELS);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Agency RestService more than one");
  }
  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new JobPostingChannelsService(singletonEnforcer);
    }
    return this[singleton];
  }
}

export default JobPostingChannelsService;
