import Vue from "vue";
import VueJWT from "vuejs-jwt";

Vue.use(VueJWT, { keyName: "accessToken" });
export const tokenManager = {
  managers() {
    let token = Vue.$jwt.decode();
    return {
      leaveManager: token.leaveManager,
      shiftManager: token.shiftManager,
      expenseManager: token.expenseManager,
      overtimeManager: token.overtimeManager
    };
  },
  get(manager) {
    let token = Vue.$jwt.decode();
    return token[`${manager}`];
  },
  organization(token) {
    let decode = Vue.$jwt.decode(token.replace("Bearer ", ""));
    if (decode) {
      return decode.organization;
    } else {
      return null;
    }
  },
  getWorkEmail() {
    let email = Vue.$jwt.decode();
    if (email) {
      return email.user;
    } else {
      return null;
    }
  }
};

export default ({ Vue }) => {
  Vue.prototype.$token_manager = tokenManager;
};
