import RestService from "./restService";
import { LEAVE_REQUEST } from "../../url";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class LeaveRequestService extends RestService {
  constructor(enforcer) {
    super(LEAVE_REQUEST);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Leave Request RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new LeaveRequestService(singletonEnforcer);
    }
    return this[singleton];
  }

  cancel(id, payload) {
    return this._api_connector.put(this._service_api.uri(id).uri("cancel").url, payload);
  }

  approve(id, payload) {
    if (id && payload && typeof payload === "object") {
      return this._api_connector.put(this._service_api.uri("processes").uri(id).url, payload);
    } else {
      throw new Error("Update API call requires ID or Payload!");
    }
  }

  deny(id, note = null) {
    return this._api_connector.put(this._service_api.uri("processes").uri(id).url, {
      approved: false,
      note: note
    });
  }

  reports(fileType, status, payload) {
    if (fileType && status && payload && typeof payload === "object" && status) {
      return this._api_connector.post(
        this._service_api
          .uri("reports")
          .uri(status)
          .uri(fileType).url,
        payload,
        {
          responseType: "blob"
        }
      );
    } else {
      throw new Error("Reports API call requires fileType, status, and Payload!");
    }
  }

  form(id) {
    return this._api_connector.post(
      this._service_api
        .uri("reports")
        .uri("form")
        .uri(id).url,
      null,
      {
        responseType: "blob"
      }
    );
  }

  addDocument(id, payload) {
    return this._api_connector.post(this._service_api.uri(id).uri("document").url, payload);
  }

  removeDocument(id, payload) {
    return this._api_connector.delete(this._service_api.uri(id).uri("document").url, {
      data: {
        url: payload
      }
    });
  }

  calculate(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(this._service_api.uri("calculate").url, payload);
    } else {
      throw new Error("Post API call requires Payload!");
    }
  }

  calculateHourly(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(this._service_api.uri("calculateHourly").url, payload);
    } else {
      throw new Error("Post API call requires Payload!");
    }
  }
}

export default LeaveRequestService;
