import RestService from "./restService";
import { POSITION_LEVEL } from "../../url/hiring";
const singleton = Symbol();
const singletonEnforcer = Symbol();
/**
 *
 */
class JobPositionLevelService extends RestService {
  constructor(enforcer) {
    super(POSITION_LEVEL);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Agency RestService more than one");
  }
  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new JobPositionLevelService(singletonEnforcer);
    }
    return this[singleton];
  }
}

export default JobPositionLevelService;
