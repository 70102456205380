<template>
  <router-view />
</template>

<script>
  export default {
    name: "App"
  };
</script>
<style lang="sass">
  body
      font-family: "Quicksand", sans-serif !important
      font-weight: 500
</style>
