import HttpStatusEventBus from "./status/httpStatusEventBus";
import { httpEventBus } from "../event/httpEventBus";

function http_response_listener() {
  httpEventBus.$on("http-response", response => {
    let response_status = response.status.toString();
    if (response.status && response.data.error) {
      if (response.data.error.code) {
        response_status = response.data.error.code.toString();
      } else {
        response_status = response.data.status.toString();
      }
    }
    HttpStatusEventBus.$emit(response_status, response);
  });
}

export default http_response_listener();
