let services = {
  channel_type: "hiring-manager",
  channels: "hiring-manager",
  skills: "hiring-manager",
  position_level: "hiring-manager",
  job_posting: "hiring-manager",
  job_posting_channels: "hiring-manager",
  applicants: "hiring-manager",
  assessment: "hiring-manager",
  assessment_detail: "hiring-manager",
  black_list: "hiring-manager",
  interview: "hiring-manager",
  stages: "hiring-manager",
  boarding: "hiring-manager",
  blacklist_manager: "hiring-manager",
  applicant_evaluator: "hiring-manager"
};

export default services;
