/**
 *
 * @type {
 *         {
 *          job_info_service: string,
 *          break_service: string,
 *          leave_type_service: string,
 *          shift_service: string,
 *          department_service: string,
 *          holiday_service: string,
 *          branch_service: string,
 *          geographic_service: string,
 *          terminal_service: string,
 *          employee_service: string
 *          }
 *        }
 */
let services = {
  job_info_service: "",
  role_service: "",
  break_service: "break-service",
  leave_type_service: "",
  leave_policy_service: "",
  leave_document_service: "",
  leave_request_service: "",
  leave_groups_service: "",
  shift_service: "worksheet-service",
  unit_service: "",
  holiday_service: "",
  branch_service: "",
  geographic_service: "geographic-service",
  terminal_service: "",
  employee_service: "",
  team_service: "",
  storage_service: "",
  registration_service: "",
  asset_service: "",
  debit_ticket_service: "",
  training_service: "",
  performance_service: "",
  boarding_template_service: "",
  boarding_item_service: "",
  boarding_category_service: "",
  category_item_service: "",
  document_service: "",
  paper_service: "",
  expense_service: "",
  message_service: "",
  cost_service: "",
  health_service: "",
  note_service: "",
  vehicle_service: "",
  agency_service: "",
  fixed_terminal_service: "",
  account_service: "",
  worksheet_service: "",
  access_terminal_service: "",
  time_sheet_service: "",
  organization_service: "",
  report_service: "",
  shifts_service: "",
  shift_groups_service: "",
  workplan_service: "",
  terminal_events_service: "",
  clock_records_service: "",
  service_lines_service: "",
  service_organization_shift_parameter: "",
  service_payroll: "",
  service_subsistence: "",
  service_imprest: "",
  service_location: "",
  communication_manager: "",
  chat_groups_service: "",
  activity_type: "",
  activity: "",
  clock_records: "",
  authentication_service: "",
  overtimes: "",
  suggestions: "",
  notes: "",
  feedback_result: "",
  travel: "",
  training_requests: "",
  survey: "",
  workplan_requests: ""
};

export default services;
