import RestService from "./restService";
import { EMPLOYEE_SERVICES, TIME_SHEETS } from "../../url";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class TimesheetService extends RestService {
  constructor(enforcer) {
    super(TIME_SHEETS.base);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Time Sheet RestService");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new TimesheetService(singletonEnforcer);
    }
    return this[singleton];
  }

  saveSubEvent(timeSheetId, eventId, payload) {
    if (typeof payload !== "object") throw new Error("Payload must be object");
    return this._api_connector.put(
      TIME_SHEETS.base
        .uri(timeSheetId)
        .uri("events")
        .uri(eventId).url,
      payload
    );
  }

  removeSubEvent(timeSheetId, eventId) {
    if (!timeSheetId && !eventId) throw new Error("Required arguments : time sheet id , event id");
    return this._api_connector.delete(
      TIME_SHEETS.base
        .uri(timeSheetId)
        .uri("events")
        .uri(eventId).url
    );
  }

  reportMonthlyTimeSheet(fileType, payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(
        TIME_SHEETS.base
          .uri("reports")
          .uri("monthly")
          .uri(fileType).url,
        payload,
        {
          responseType: "blob"
        }
      );
    } else {
      throw new Error("Update API call requires  Payload!");
    }
  }

  reportsAllTimesheets(payload, type, fileType = "xlsx") {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(
        TIME_SHEETS.base
          .uri("reports")
          .uri(type)
          .uri(fileType).url,
        payload,
        {
          responseType: "blob"
        }
      );
    } else {
      throw new Error("Update API call requires Payload!");
    }
  }

  reportDailyTimeSheet(fileType, payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(
        TIME_SHEETS.base
          .uri("reports")
          .uri("daily")
          .uri(fileType).url,
        payload,
        {
          responseType: "blob"
        }
      );
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  reportDailyLateComersTimeSheet(fileType, payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(
        TIME_SHEETS.base
          .uri("reports")
          .uri("latecomers")
          .uri(fileType).url,
        payload,
        {
          responseType: "blob"
        }
      );
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  reportDailyEarlyLeaversTimeSheet(fileType, payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(
        TIME_SHEETS.base
          .uri("reports")
          .uri("early-leavers")
          .uri(fileType).url,
        payload,
        {
          responseType: "blob"
        }
      );
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  reportDailyAbsencesTimeSheet(fileType, payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(
        TIME_SHEETS.base
          .uri("reports")
          .uri("absences")
          .uri(fileType).url,
        payload,
        {
          responseType: "blob"
        }
      );
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  reportDailyOnLeaveTimeSheet(fileType, payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(
        TIME_SHEETS.base
          .uri("reports")
          .uri("on-leave")
          .uri(fileType).url,
        payload,
        {
          responseType: "blob"
        }
      );
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  reports(fileType, status, payload, fields = []) {
    if (payload && typeof payload === "object" && status) {
      return this._api_connector.post(
        TIME_SHEETS.base
          .uri("reports")
          .uri(status)
          .uri(fileType).url,
        payload,
        {
          params: {
            fields: fields
          },
          responseType: "blob"
        }
      );
    } else {
      throw new Error("Reports API call requires PAYLOAD!!!!");
    }
  }
}

export default TimesheetService;
