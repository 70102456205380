import RestService from "./restService";
import { APPLICANTS } from "../../url/hiring";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class ApplicantsService extends RestService {
  constructor(enforcer) {
    super(APPLICANTS);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Agency RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ApplicantsService(singletonEnforcer);
    }
    return this[singleton];
  }

  changeStage(id, payload) {
    if (payload && id) {
      return this._api_connector.patch(
        APPLICANTS.uri("update")
          .uri("phase")
          .uri(id).url,
        payload
      );
    } else throw new Error("Save API call requires ID & Stage!");
  }

  updatePhoto(id, photoLink = null) {
    if (id) {
      return this._api_connector.patch(
        APPLICANTS.uri("update")
          .uri("photo")
          .uri("id")
          .uri(id).url,
        photoLink
      );
    } else throw new Error("Save API call requires ID!");
  }
}

export default ApplicantsService;
