import RestService from "./restService";
import { JOB_POSTING } from "../../url/hiring";
const singleton = Symbol();
const singletonEnforcer = Symbol();
/**
 *
 */
class JobPostingService extends RestService {
  constructor(enforcer) {
    super(JOB_POSTING);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Agency RestService more than one");
  }
  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new JobPostingService(singletonEnforcer);
    }
    return this[singleton];
  }
  addSkill(id, payload) {
    if (payload && typeof payload === "object" && id) {
      return this._api_connector.post(JOB_POSTING.uri("add-skill").uri(id).url, payload);
    } else throw new Error("Save API call requires ID & Payload!");
  }
  removeSkill(id, payload) {
    if (payload && typeof payload === "object" && id) {
      return this._api_connector.post(JOB_POSTING.uri("remove-skill").uri(id).url, payload);
    } else throw new Error("Save API call requires ID & Payload!");
  }
}

export default JobPostingService;
