import RestService from "./restService";
import { PAYROLL } from "../../url/index";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class PayrollService extends RestService {
  constructor(enforcer) {
    super(PAYROLL);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct PayrollService RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new PayrollService(singletonEnforcer);
    }
    return this[singleton];
  }

  import(formData, date, configuration) {
    return this._api_connector.put(
      PAYROLL.uri("import").url,
      formData,
      Object.assign(configuration, {
        params: {
          date: date
        },
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    );
  }
}

export default PayrollService;
