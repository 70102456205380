import HttpStatusEventBus from "../httpStatusEventBus";
import { OK } from "http-status-codes";
import { eventBus } from "../../../../plugins/event-bus";

function ok() {
  HttpStatusEventBus.$on(OK.toString(), response => {
    if (response.config.url.includes("/login")) return; // TODO : will be changed ACCEPTED
    let notification_credentials;
    if (response.config && response.config.method === "post") {
      notification_credentials = {
        text: "saved_successfully",
        color: "success",
        type: "success"
      };
    }

    if (response.config && response.config.method === "put") {
      notification_credentials = {
        text: "updated_successfully",
        color: "success",
        type: "success"
      };
    }
    if (response.config && response.config.method === "patch") {
      notification_credentials = {
        text: "updated_successfully",
        color: "success",
        type: "success"
      };
    }
    if (response.config && response.config.method === "delete") {
      notification_credentials = {
        text: "success",
        color: "success",
        type: "success"
      };
    }
    if (response.config && response.config.method === "get") {
      return;
    }
    eventBus.$emit("snack-bar-notification", notification_credentials);
  });
}

export default ok();
