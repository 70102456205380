import * as VueGoogleMaps from "vue2-google-maps";
import { gmapApi } from "vue2-google-maps";

export default ({ app, Vue }) => {
  Vue.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyBdNVMMC5XjAcH3OdDG6SpLmmE-5lq-pSE",
      libraries: "places" // This is required if you use the Autocomplete plugin
    }
  });
  Vue.prototype.$google = gmapApi;
};
