import RestService from "./restService";
import { SURVEY } from "../../url/index";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class SurveyService extends RestService {
  constructor(enforcer) {
    super(SURVEY.BASE);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Survey RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new SurveyService(singletonEnforcer);
    }
    return this[singleton];
  }

  finish(id, payload) {
    if (id && payload) {
      return this._api_connector.put(SURVEY.ANSWER.uri(id).url, payload);
    } else {
      throw new Error("Save API call required payload!");
    }
  }

  complete(id) {
    if (id) {
      return this._api_connector.put(SURVEY.ANSWER.uri(id).uri("complete").url);
    } else {
      throw new Error("Save API call required payload!");
    }
  }
}

export default SurveyService;
