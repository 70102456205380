import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import config from "../config/firebase-config";

export const FIREBASE_APP = firebase.initializeApp(config);
export const FIRESTORE = firebase.firestore();
export const FIREBASE_AUTH = firebase.auth();

export default function install(Vue) {
  Object.defineProperty(Vue.prototype, "$firebase", {
    get() {
      return FIREBASE_APP;
    }
  });
  Object.defineProperty(Vue.prototype, "$firebase_auth", {
    get() {
      return FIREBASE_AUTH;
    }
  });
  Object.defineProperty(Vue.prototype, "$firestore", {
    get() {
      return FIRESTORE;
    }
  });
}
