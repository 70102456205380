import { ValidationObserver, ValidationProvider } from "vee-validate";
import VueApexCharts from "vue-apexcharts";

export default ({ Vue }) => {
  Vue.component("ValidationProvider", ValidationProvider);
  Vue.component("ValidationObserver", ValidationObserver);
  Vue.component("Chart", VueApexCharts);
  Vue.component("NotificationBar", (resolve) =>
    import("./global/NotificationBar").then((NotificationBar) => resolve(NotificationBar.default))
  );
  Vue.component("IdButton", (resolve) => import("./global/IdButton").then((IdButton) => resolve(IdButton.default)));
  Vue.component("AppContentLayout", (resolve) =>
    import("@/layouts/app-content-layout").then((AppContentLayout) => resolve(AppContentLayout.default))
  );
  Vue.component("AppPageLayout", (resolve) =>
    import("@/layouts/app-page-layout").then((AppPageLayout) => resolve(AppPageLayout.default))
  );
  Vue.component("AppSidebar", (resolve) =>
    import("@/layouts/app-sidebar").then((AppSidebar) => resolve(AppSidebar.default))
  );
  Vue.component("AppClockRecord", (resolve) =>
    import("./global/ClockInClockOut").then((AppClockRecord) => resolve(AppClockRecord.default))
  );
  Vue.component("EmployeeProfile", (resolve) =>
    import("./global/EmployeeProfile").then((EmployeeProfile) => resolve(EmployeeProfile.default))
  );
  Vue.component("FileDownloadButton", (resolve) =>
    import("./global/DownloadButton").then((FileDownloadButton) => resolve(FileDownloadButton.default))
  );
  Vue.component("FileUploadPicker", (resolve) =>
    import("./global/file-upload-picker").then((FileUploadPicker) => resolve(FileUploadPicker.default))
  );
  Vue.component("DateTimePicker", (resolve) =>
    import("./global/DateTimePicker").then((DateTimePicker) => resolve(DateTimePicker.default))
  );
  Vue.component("DatePicker", (resolve) =>
    import("./global/DatePicker").then((DatePicker) => resolve(DatePicker.default))
  );
  Vue.component("TimePicker", (resolve) =>
    import("./global/TimePicker").then((TimePicker) => resolve(TimePicker.default))
  );
  Vue.component("AppIdentityIcon", (resolve) =>
    import("./global/IdentityTypeIcon").then((AppIdentityIcon) => resolve(AppIdentityIcon.default))
  );
  Vue.component("AppIdIcon", (resolve) =>
    import("./global/IdenfitIcon").then((AppIdIcon) => resolve(AppIdIcon.default))
  );
  Vue.component("AddressPicker", (resolve) =>
    import("./global/AddressPicker").then((AddressPicker) => resolve(AddressPicker.default))
  );
  Vue.component("MonthPicker", (resolve) =>
    import("./global/MonthPicker").then((MonthPicker) => resolve(MonthPicker.default))
  );
  Vue.component("DateRangePicker", (resolve) =>
    import("./global/DateRangePicker").then((DateRangePicker) => resolve(DateRangePicker.default))
  );
  Vue.component("WorkplanDetailCard", (resolve) =>
    import("./global/WorkplanDetailCard").then((WorkplanDetailCard) => resolve(WorkplanDetailCard.default))
  );
  Vue.component("ApprovalHistoryTable", (resolve) =>
    import("./ApprovalHistoryTable").then((ApprovalHistoryTable) => resolve(ApprovalHistoryTable.default))
  );
  Vue.component("EmployeePicker", (resolve) =>
    import("./selectpickers/EmployeePicker").then((EmployeePicker) => resolve(EmployeePicker.default))
  );
};
