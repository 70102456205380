import RestService from "./restService";
import { BLACK_LIST_MANAGER } from "../../url/hiring";
const singleton = Symbol();
const singletonEnforcer = Symbol();
/**
 *
 */
class BlackListManagerService extends RestService {
  constructor(enforcer) {
    super(BLACK_LIST_MANAGER);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Agency RestService more than one");
  }
  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new BlackListManagerService(singletonEnforcer);
    }
    return this[singleton];
  }

  addToBlackList(payload) {
    if (payload) {
      return this._api_connector.post(BLACK_LIST_MANAGER.uri("add-to-black").url, payload);
    } else throw new Error("Save API call requires IDs!");
  }
  removeToBlackList(ids) {
    if (ids.length) {
      return this._api_connector.post(BLACK_LIST_MANAGER.uri("remove-from-black").url, ids);
    } else throw new Error("Save API call requires IDs!");
  }
}

export default BlackListManagerService;
