import RestService from "./restService";
import { EXPENSE } from "../../url/index";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class ExpenseService extends RestService {
  constructor(enforcer) {
    super(EXPENSE.expenses);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Expense RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ExpenseService(singletonEnforcer);
    }
    return this[singleton];
  }

  saveExpenseType(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(EXPENSE.expenseTypes.url, payload);
    } else throw new Error("Save API call requires Payload!");
  }

  updateExpenseType(payload) {
    if (typeof payload === "object" && payload.id) {
      return this._api_connector.put(EXPENSE.expenseTypes.uri(payload.id).url, payload);
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  removeExpenseType(id) {
    if (id) {
      return this._api_connector.delete(EXPENSE.expenseTypes.uri(id).url);
    } else {
      throw new Error("Delete API call requires ID parameter !");
    }
  }

  approve(expenseId, payload) {
    if (expenseId && payload && typeof payload === "object") {
      return this._api_connector.put(EXPENSE.expenses.uri("processes").uri(expenseId).url, payload);
    } else throw new Error("Save API call requires Payload!");
  }
}

export default ExpenseService;
