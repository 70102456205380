import "./vee-validate";
import "./directives";
import moment from "./moment";
import vuetify from "./vuetify";
import StorageManager from "./storage-manager";
import TokenManager from "./token-manager";
import EventBus from "./event-bus";
import googleMap from "./google-map";

export default ({ app, Vue }) => {
  app["moment"] = moment;
  app["vuetify"] = vuetify;
  EventBus({ Vue });
  StorageManager({ Vue });
  TokenManager({ Vue });
  googleMap({ app, Vue });
};
