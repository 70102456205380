import Vue from "vue";
import { router } from "../../../router";
import { hiringConnector, idenfitConnector } from "../../../http/clients";
import { storageManager } from "../../../plugins/storage-manager";
import { tokenManager } from "../../../plugins/token-manager";
import {
  HIRING_REFRESH_TOKEN_KEY,
  HIRING_TOKEN_KEY,
  LANGUAGE_KEY,
  REFRESH_TOKEN_KEY,
  TOKEN_KEY,
  TOKEN_PREFIX,
  USER,
  ORGANIZATION
} from "../../../base/constants";
import { client } from "../../../plugins/apollo";
import { WEB_TERMINAL, WEB_TERMINAL_STATUS, PENDING_FEEDBACKS, ORGANIZATION_DETAIL, EMPLOYEE } from "../query";
import { i18n } from "../../../plugins/i18n";
import { RefreshTokenNotFoundError } from "@/http/api/services/rest/auth.service";

export default {
  namespaced: true,
  state: {
    loading: false,
    loggedIn: !!storageManager.get(USER),
    accessToken: storageManager.get(TOKEN_KEY) || null,
    refreshToken: storageManager.get(REFRESH_TOKEN_KEY) || null,
    hiringAccessToken: storageManager.get(HIRING_TOKEN_KEY) || null,
    hiringRefreshToken: storageManager.get(HIRING_REFRESH_TOKEN_KEY) || null,
    language: storageManager.get(LANGUAGE_KEY) || "tr",
    organization: storageManager.get(ORGANIZATION) || null,
    user: {
      isAdmin: storageManager.get("user")
        ? JSON.parse(storageManager.get("user")).admin && !JSON.parse(storageManager.get("user")).restrictedAdmin
        : false,
      id: storageManager.get("user") ? JSON.parse(storageManager.get("user")).id : null,
      firstName: storageManager.get("user") ? JSON.parse(storageManager.get("user")).firstName : null,
      lastName: storageManager.get("user") ? JSON.parse(storageManager.get("user")).lastName : null,
      firstTimePassword: storageManager.get("user") ? JSON.parse(storageManager.get("user")).firstTimePassword : null,
      language: storageManager.get("user") ? JSON.parse(storageManager.get("user")).language : null,
      imageUrl: storageManager.get("user") ? JSON.parse(storageManager.get("user")).imageUrl : null,
      organization: storageManager.get("user") ? JSON.parse(storageManager.get("user")).organization : null,
      logo: storageManager.get("user") ? JSON.parse(storageManager.get("user")).logo : null,
      restrictedAdmin: storageManager.get("user") ? JSON.parse(storageManager.get("user")).restrictedAdmin : false,
      adminBranches: storageManager.get("user") ? JSON.parse(storageManager.get("user")).adminBranches : []
    },
    requiredPendingFeedbackCount: Number(storageManager.get("requiredPendingFeedbackCount") || 0),
    notRequiredPendingFeedbackCount: 0,
    organizationDetail: {
      mobileSettings: {}
    },
    authenticatedEmployee: {
      gdprAccepted: true
    },
    passwordExpired: storageManager.get("user") ? JSON.parse(storageManager.get("user")).passwordExpired : false,
    passwordExpireNotification: storageManager.get("user")
      ? JSON.parse(storageManager.get("user")).passwordExpireNotification
      : null,
    passwordExpireNotificationStatus: storageManager.get("user")
      ? JSON.parse(storageManager.get("user")).passwordExpireNotification !== null &&
        JSON.parse(storageManager.get("user")).passwordExpireNotification !== undefined
      : false
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_ORGANIZATION(state, organization) {
      state.organization = organization;
    },
    SET_TOKEN(state, payload) {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    },
    SET_ACCESS_TOKEN(state, accessToken) {
      state.accessToken = accessToken;
    },
    SET_REFRESH_TOKEN(state, accessToken) {
      state.refreshToken = accessToken;
    },
    SET_HIRING_ACCESS_TOKEN(state, accessToken) {
      state.hiringAccessToken = accessToken;
    },
    SET_HIRING_REFRESH_TOKEN(state, accessToken) {
      state.hiringRefreshToken = accessToken;
    },
    SET_DATA(state, user) {
      state.loggedIn = true;
      state.user.isAdmin = user.admin && !user.restrictedAdmin;
      state.user.id = user.id;
      state.user.firstName = user.firstName;
      state.user.lastName = user.lastName;
      state.user.firstTimePassword = user.firstTimePassword;
      state.user.language = user.language;
      state.user.imageUrl = user.imageUrl;
      state.user.restrictedAdmin = user.restrictedAdmin;
      state.user.adminBranches = user.adminBranches;
      state.user.organization = user.organization;
      state.user.logo = user.logo;
      state.language = user.language;
      state.passwordExpired = user.passwordExpired;
      state.passwordExpireNotification = user.passwordExpireNotification;
      state.passwordExpireNotificationStatus =
        user.passwordExpireNotification !== null && user.passwordExpireNotification !== undefined;
    },
    SET_REQUIRED_PENDING_FEEDBACK_COUNT(state, count) {
      state.requiredPendingFeedbackCount = count;
      storageManager.set("requiredPendingFeedbackCount", count);
    },
    SET_NOT_REQUIRED_PENDING_FEEDBACK_COUNT(state, count) {
      state.notRequiredPendingFeedbackCount = count;
    },
    SET_ORGANIZATION_DETAIL(state, organizationDetail) {
      state.organizationDetail = organizationDetail;
    },
    CLEAR_DATA(state) {
      state.user.isAdmin = false;
      state.user.firstName = null;
      state.user.lastName = null;
      state.user.firstTimePassword = null;
      state.user.language = null;
      state.user.imageUrl = null;
      state.user.organization = null;
      state.user.logo = null;
      state.user.restrictedAdmin = false;
      state.user.adminBranches = [];
      state.language = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.loggedIn = false;
      state.organization = null;
      state.passwordExpired = false;
      state.passwordExpireNotification = null;
      state.passwordExpireNotificationStatus = false;
    },
    SET_REFRESH_ACCESS_TOKEN(state, token) {
      state.accessToken = token || null;
    },
    SET_HIRING_REFRESH_ACCESS_TOKEN(state, token) {
      state.hiringAccessToken = token || null;
    },
    SET_AUTHENTICATED_EMPLOYEE(state, employee) {
      state.authenticatedEmployee = employee;
    },
    clearPasswordExpired(state) {
      const user = storageManager.get("user");
      let userJson = {};
      if (user) {
        userJson = JSON.parse(user);
        userJson.passwordExpired = false;
        userJson.passwordExpireNotification = null;
        storageManager.set(USER, JSON.stringify(userJson));
      }
      state.passwordExpired = false;
      state.passwordExpireNotification = null;
      state.passwordExpireNotificationStatus = false;
    }
  },
  actions: {
    async LOGIN({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        await Vue.prototype.$api.authenticationService
          .login(payload)
          .then((data) => {
            if (data) {
              if (data.user && data.token) {
                const accessToken = data.token.accessToken.replace(TOKEN_PREFIX, "");
                // const hiringAccessToken = data.user.hiringManagerToken.replace(TOKEN_PREFIX, "");
                const refreshToken = data.token.refreshToken;
                storageManager.set(TOKEN_KEY, accessToken);
                storageManager.set(REFRESH_TOKEN_KEY, refreshToken);
                /* storageManager.set(HIRING_TOKEN_KEY, hiringAccessToken)
                            storageManager.set(HIRING_REFRESH_TOKEN_KEY, data.user.hiringManagerRefreshToken)*/
                storageManager.set(USER, JSON.stringify(data.user));
                storageManager.set(LANGUAGE_KEY, data.user.language);
                storageManager.set(ORGANIZATION, tokenManager.organization(accessToken));
                commit("SET_ORGANIZATION", tokenManager.organization(accessToken));
                commit("SET_ACCESS_TOKEN", accessToken);
                commit("SET_REFRESH_TOKEN", refreshToken);
                /*  commit('SET_HIRING_ACCESS_TOKEN', hiringAccessToken);
                            commit('SET_HIRING_REFRESH_TOKEN', data.user.hiringManagerRefreshToken);*/
                commit("SET_DATA", data.user);
                i18n.locale = data.user.language;
                resolve(data.user.firstTimePassword || data.user.passwordExpired);
                idenfitConnector.mount401Interceptor();
              }
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    LOGIN_WITH_SSO({ commit }, { identityProvider, accessToken }) {
      return new Promise(async (resolve, reject) => {
        if (accessToken) {
          Vue.prototype.$api.authenticationService
            .loginWithSSO({ identityProvider, accessToken })
            .then((data) => {
              if (data && data.user && data.token) {
                const accessToken = data.token.accessToken.replace(TOKEN_PREFIX, "");
                const refreshToken = data.token.refreshToken;
                storageManager.set(TOKEN_KEY, accessToken);
                storageManager.set(REFRESH_TOKEN_KEY, refreshToken);
                storageManager.set(USER, JSON.stringify(data.user));
                storageManager.set(LANGUAGE_KEY, data.user.language);
                storageManager.set(ORGANIZATION, tokenManager.organization(accessToken));
                commit("SET_ORGANIZATION", tokenManager.organization(accessToken));
                commit("SET_ACCESS_TOKEN", accessToken);
                commit("SET_REFRESH_TOKEN", refreshToken);
                commit("SET_DATA", data.user);
                i18n.locale = data.user.language;
                resolve(data.user.firstTimePassword || data.user.passwordExpired);
                idenfitConnector.mount401Interceptor();
              }
            })
            .catch(() => reject(i18n.t("auth.invalid_email_or_password")));
        } else {
          reject(i18n.t("auth.sso_error"));
        }
      });
    },
    MOBILELOGIN({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.authenticationService
          .mobileLogin(payload)
          .then((data) => {
            if (data && data.user && data.token) {
              const accessToken = data.token.accessToken.replace(TOKEN_PREFIX, "");
              const refreshToken = data.token.refreshToken;

              storageManager.set(TOKEN_KEY, accessToken);
              storageManager.set(REFRESH_TOKEN_KEY, refreshToken);

              commit("SET_ACCESS_TOKEN", accessToken);
              commit("SET_REFRESH_TOKEN", refreshToken);

              resolve(true);
            } else resolve(false);
          })
          .catch(reject);
      });
    },
    FETCH_PENDING_FEEDBACKS({ commit, state }, commit_required = true) {
      return new Promise((resolve, reject) => {
        client
          .query({ query: PENDING_FEEDBACKS, variables: { id: state.user.id } })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              const requiredCount = data.employee.pendingFeedbacks.filter(
                (pendingFeedback) => pendingFeedback.feedback.required
              ).length;
              const notRequiredCount = data.employee.pendingFeedbacks.filter(
                (pendingFeedback) => !pendingFeedback.feedback.required
              ).length;
              if (commit_required) {
                commit("SET_REQUIRED_PENDING_FEEDBACK_COUNT", requiredCount);
              }
              commit("SET_NOT_REQUIRED_PENDING_FEEDBACK_COUNT", notRequiredCount);
              resolve(data.employee.pendingFeedbacks);
            } else {
              reject(errors);
            }
          })
          .catch((error) => reject(error));
      });
    },
    FETCH_ORGANIZATION_DETAIL({ commit }) {
      return client
        .query({ query: ORGANIZATION_DETAIL })
        .then(({ data, errors }) => {
          if (!data.error && !errors) {
            commit("SET_ORGANIZATION_DETAIL", data.organizationDetail);
          }
        })
        .catch(console.error);
    },
    FETCH_AUTHENTICATED_EMPLOYEE({ commit, state }) {
      return client
        .query({ query: EMPLOYEE, variables: { id: state.user.id } })
        .then(({ data, errors }) => {
          if (!data.error && !errors) {
            commit("SET_AUTHENTICATED_EMPLOYEE", data.employee);
          }
        })
        .catch(console.error);
    },
    async FETCH_TERMINAL({ commit }, payload) {
      return new Promise(async (resolve) => {
        await client
          .query({
            query: WEB_TERMINAL,
            variables: {
              criteria: {
                name: "WEB"
              }
            }
          })
          .then(({ data, errors }) => {
            if (errors || data.error) {
              resolve(null);
            } else {
              resolve(data.filterTerminals[0]);
            }
          });
      });
    },
    async TERMINAL_STATUS({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        await client
          .query({
            query: WEB_TERMINAL_STATUS,
            variables: {
              criteria: {
                name: "WEB"
              }
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              resolve(data.filterTerminals[0].disabled);
            } else {
              reject(errors);
            }
          });
      });
    },
    LOGOUT({ commit, state }) {
      return new Promise(async (resolve, reject) => {
        Vue.prototype.$api.authenticationService
          .logout(state.accessToken)
          .then(() => resolve(true))
          .catch(reject);
        const layouts = storageManager.get("layouts");
        storageManager.reset();
        if (layouts) {
          storageManager.set("layouts", layouts);
        }
        idenfitConnector.setRemoveHeaders();
        idenfitConnector.unmount401Interceptor();
        commit("CLEAR_DATA");
      });
    },
    REFRESH_TOKEN({ commit }) {
      return Vue.prototype.$api.authenticationService
        .refreshToken()
        .then((data) => {
          if (!data.error) {
            commit("SET_REFRESH_ACCESS_TOKEN", data.accessToken);
            storageManager.set(TOKEN_KEY, data.accessToken);
            idenfitConnector.setRemoveHeaders();
            return data.accessToken;
          } else {
            const layouts = storageManager.get("layouts");
            storageManager.reset();
            if (layouts) {
              storageManager.set("layouts", layouts);
            }
            idenfitConnector.setRemoveHeaders();
            idenfitConnector.unmount401Interceptor();
            commit("CLEAR_DATA");
            router.replace({ name: "login" });
            throw new RefreshTokenNotFoundError(750, "Refresh Token Not Found");
          }
        })
        .catch((err) => {
          const layouts = storageManager.get("layouts");
          storageManager.reset();
          if (layouts) {
            storageManager.set("layouts", layouts);
          }
          idenfitConnector.setRemoveHeaders();
          idenfitConnector.unmount401Interceptor();
          commit("CLEAR_DATA");
          router.replace({ name: "login" });
          throw new RefreshTokenNotFoundError(750, "Refresh Token Not Found");
        });
    },
    async HIRING_REFRESH_TOKEN({ commit }) {
      await Vue.prototype.$api_hiring.authenticationService
        .refreshToken()
        .then((data) => {
          if (!data.error) {
            commit("SET_HIRING_REFRESH_ACCESS_TOKEN", data.accessToken);
            storageManager.set(HIRING_TOKEN_KEY, data.accessToken);
            hiringConnector.setRemoveHeaders();
            return data.accessToken;
          } else {
            router.replace({ name: "login" });
          }
        })
        .catch((e) => {
          router.replace({ name: "login" });
        });
    }
  },
  getters: {
    firstName: (state) => state.user.firstName,
    lastName: (state) => state.user.lastName,
    fullName: (state) => state.user.firstName + " " + state.user.lastName,
    imageUrl: (state) => state.user && state.user.imageUrl,
    organization: (state) => state.user && state.user.organization,
    loading: (state) => state.loading,
    organizationLogo: (state) => state.user && state.user.logo,
    routeDisabled: (state) => (mobileSetting) =>
      mobileSetting
        ? mobileSetting in state.organizationDetail.mobileSettings
          ? state.organizationDetail.mobileSettings[mobileSetting]
          : true
        : false,
    ssoCredentials: (state) => state.organizationDetail.ssoCredentials,
    shiftSelection: (state) => state.organizationDetail.shiftSelection,
    organizationDetail: (state) => state.organizationDetail,
    authenticatedEmployee: (state) => state.authenticatedEmployee,
    isSuperAdmin: (state) => (state.authenticatedEmployee ? state.authenticatedEmployee.profile.superAdmin : false)
  }
};
