const cache = {};
function importAll(file) {
  file.keys().forEach(key => (cache[key] = file(key)));
}

export default {
  setup() {
    this.registerListeners();
    this.registerStatusListeners();
  },
  registerListeners() {
    importAll(require.context("./event/listeners/", false, /\.js$/));
    importAll(require.context("./event/listeners/", false, /\.js$/));
  },
  registerStatusListeners() {
    importAll(require.context("./response/", false, /\.js$/));
    importAll(require.context("./response/status/listeners", false, /\.js$/));
    importAll(require.context("./response/status/idenfit/errors", false, /\.js$/));
  }
};
