import Vue from "vue";
import moment from "moment";
import "moment/locale/tr";
import { getUserLocale } from "@/utils/locale";

moment.updateLocale(getUserLocale(), {
  week: {
    dow: 1
  }
});
Vue.prototype.$moment = moment;

export default moment;
