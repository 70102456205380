import RestService from "./restService";
import { ACCOUNT } from "../../url/";
import { idenfitConnector } from "../../../clients";
const singleton = Symbol();
const singletonEnforcer = Symbol();

class UserService extends RestService {
  constructor(enforcer) {
    super(ACCOUNT.account);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Unit RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new UserService(singletonEnforcer);
    }
    return this[singleton];
  }

  /**
   *
   * @param payload
   * @param config
   * @returns {AxiosPromise<any>}
   */

  changePassword(payload, config = {}) {
    return this._api_connector.put(ACCOUNT.account.uri("change-password").url, payload, config);
  }

  /**
   *
   * @returns {AxiosPromise<any>}
   */
  sendPasswordResetToken(params) {
    return idenfitConnector.customRequest({
      method: "post",
      url: ACCOUNT.account.uri("send-password-reset-token").url,
      params: params,
      headers: {}
    });
  }

  newPassword(params) {
    return idenfitConnector.customRequest({
      method: "post",
      url: ACCOUNT.account.uri("new-password").url,
      params: params,
      headers: {}
    });
  }
}

export default UserService;
