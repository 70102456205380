import RestService from "./restService";
import { ASSESSMENT_DETAIL } from "../../url/hiring";
const singleton = Symbol();
const singletonEnforcer = Symbol();
/**
 *
 */
class AssessmentDetailService extends RestService {
  constructor(enforcer) {
    super(ASSESSMENT_DETAIL);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Agency RestService more than one");
  }
  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new AssessmentDetailService(singletonEnforcer);
    }
    return this[singleton];
  }

  evaluatorAssessment(payload) {
    if (payload) {
      return this._api_connector.patch(
        ASSESSMENT_DETAIL.uri("add-detail-list")
          .uri("evaluator")
          .uri(payload.evaluatorId)
          .uri("assessment")
          .uri(payload.assessmentId).url,
        payload.detailDtoList
      );
    } else {
      throw new Error("Api call required params");
    }
  }
}

export default AssessmentDetailService;
