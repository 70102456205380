import axios from "axios";
import Qs from "qs";
import helpers from "@/helpers";
import store from "@/store";
import { httpEventBus } from "../../event/httpEventBus";
import { UNAUTHORIZED } from "http-status-codes";

const paramsSerializer = params => Qs.stringify(params, { indices: false, arrayFormat: "repeat" });

const TIMEOUT = 900000;
const AUTHORIZATION_HEADER_KEY = "Authorization";

export default class HiringConnector {
  constructor(BASE_URL) {
    this.client = axios.create({
      baseURL: BASE_URL,
      timeout: TIMEOUT,
      paramsSerializer: paramsSerializer
    });
    this._401interceptor = null;
    this.isAlreadyFetchingAccessToken = false;
    this.subscribers = [];

    this.unmount401Interceptor();
    this.mount401Interceptor();
    this.mountRequestInterceptor();
  }

  create(BASE_URL) {
    this.client = axios.create({
      baseURL: BASE_URL,
      timeout: TIMEOUT,
      paramsSerializer: paramsSerializer
    });
  }

  getClient() {
    return this;
  }

  setHeader(field, value) {
    this.client.defaults.headers.common[field] = value;
  }

  setAuthorizationHeader(header) {
    let token = header || `Bearer ${store.state.auth.hiringAccessToken}`;
    this.setHeader(AUTHORIZATION_HEADER_KEY, token);
  }

  setRemoveHeaders() {
    this.client.defaults.common = {};
    this.client.defaults.headers.common = {};
  }

  get(resource, config = {}) {
    return this.client.get(resource, config);
  }

  post(resource, data, config = {}) {
    return this.client.post(resource, data, config);
  }

  put(resource, data, config = {}) {
    return this.client.put(resource, data, config);
  }

  patch(resource, data, config = {}) {
    return this.client.patch(resource, data, config);
  }

  delete(resource, config = {}) {
    return this.client.delete(resource, config);
  }

  async customRequest(config) {
    return this.client(config);
  }

  async refreshToken() {
    return this.client.post("/hiring-manager/v1.0/api/client-account/refresh-token", null, {
      headers: {
        "Refresh-Token": store.state.auth.hiringRefreshToken
      }
    });
  }

  mountRequestInterceptor() {
    this.client.interceptors.request.use(
      function(config) {
        if (config.url !== "hiring-manager/v1.0/api/client-account/refresh-token") {
          config.headers.Authorization = `Bearer ${store.state.auth.hiringAccessToken}`;
        } else {
          delete config.headers.Authorization;
        }
        return config;
      },
      function(error) {
        return Promise.reject(error);
      }
    );
  }

  mount401Interceptor() {
    let pointer = this;
    this._401interceptor = this.client.interceptors.response.use(
      function(response) {
        httpEventBus.httpResponse(response);
        return response;
      },
      function(error) {
        if (!error.response) {
          helpers.showNotification("network_error", "error");
          return false;
        }
        httpEventBus.httpResponse(error.response);
        const errorResponse = error.response;
        if (pointer.isTokenExpired(errorResponse)) return pointer.resetTokenAndReattemptRequest(error);
        return Promise.reject(error);
      }
    );
  }

  unmount401Interceptor() {
    this.client.interceptors.response.eject(this._401interceptor);
  }

  isTokenExpired(errorResponse) {
    return (errorResponse && errorResponse.request && errorResponse.request.status) === UNAUTHORIZED;
  }

  resetTokenAndReattemptRequest(error) {
    let pointer = this;
    try {
      const { response: errorResponse } = error;
      const refreshToken = store.state.auth.hiringRefreshToken;
      if (!refreshToken) return Promise.reject(error);
      const retryOriginalRequest = new Promise(resolve => {
        pointer.addSubscriber(access_token => {
          errorResponse.config.headers.Authorization = "Bearer " + access_token;
          resolve(pointer.customRequest(errorResponse.config));
        });
      });
      if (!this.isAlreadyFetchingAccessToken) {
        this.isAlreadyFetchingAccessToken = true;
        store
          .dispatch("auth/HIRING_REFRESH_TOKEN")
          .then(newToken => {
            pointer.onAccessTokenFetched(newToken);
          })
          .catch(e => console.log("refresh error: ", e.toString()))
          .finally(() => (this.isAlreadyFetchingAccessToken = false));
      }
      return retryOriginalRequest;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  onAccessTokenFetched(access_token) {
    // retrying the requests one by one and empty the queue
    this.subscribers.forEach(callback => callback(access_token));
    this.subscribers = [];
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }
}
