import RestService from "./restService";
import {
  EMPLOYEE_SERVICES,
  LEAVE_TYPE,
  SHIFTS,
  TIME_SHEETS,
  VEHICLE_SERVICES,
  WORKPLAN_SERVICES
} from "../../url/index";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class WorkplanService extends RestService {
  constructor(enforcer) {
    super(WORKPLAN_SERVICES);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Workplan RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new WorkplanService(singletonEnforcer);
    }
    return this[singleton];
  }

  multiple(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(WORKPLAN_SERVICES.uri("multiple").url, payload);
    } else {
      throw new Error("Multiple API call requires  Payload!");
    }
  }

  removeEvent(workplanId, timesheetId, eventId) {
    if (workplanId && timesheetId && eventId) {
      return this._api_connector.delete(
        WORKPLAN_SERVICES.uri(workplanId)
          .uri("timesheets")
          .uri(timesheetId)
          .uri("events")
          .uri(eventId).url
      );
    } else {
      throw new Error("Remove Event API call requires  WorkplanId,TimesheetId, EventId!");
    }
  }

  removeTimeSheet(workplanId, timesheetId) {
    if (workplanId && timesheetId) {
      return this._api_connector.delete(
        WORKPLAN_SERVICES.uri(workplanId)
          .uri("timesheets")
          .uri(timesheetId).url
      );
    } else {
      throw new Error("Remove Event API call requires  WorkplanId,TimesheetId!");
    }
  }

  multipleDeleteTimesheets(timesheetsIds) {
    if (timesheetsIds) {
      return this._api_connector.delete(WORKPLAN_SERVICES.uri("timesheets").url, {
        data: { ids: timesheetsIds }
      });
    } else {
      throw new Error("Remove Event API call requires  timesheetsIds!");
    }
  }

  updateEvent(workplanId, timesheetId, eventId, payload) {
    if (workplanId && timesheetId && eventId && payload) {
      return this._api_connector.put(
        WORKPLAN_SERVICES.uri(workplanId)
          .uri("timesheets")
          .uri(timesheetId)
          .uri("events")
          .uri(eventId).url,
        payload
      );
    } else {
      throw new Error("Remove Event API call requires  WorkplanId,TimesheetId, EventId, Payload!");
    }
  }

  reports(fileType, status, payload) {
    if (payload && typeof payload === "object" && status) {
      return this._api_connector.post(
        WORKPLAN_SERVICES.uri("reports")
          .uri(status)
          .uri(fileType).url,
        payload,
        {
          responseType: "blob"
        }
      );
    } else {
      throw new Error("Reports API call requires PAYLOAD!!!!");
    }
  }

  deleteAllWorkplans(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.delete(WORKPLAN_SERVICES.uri("multiple").url, {
        data: payload
      });
    } else {
      throw new Error("Reports API call requires PAYLOAD!!!!");
    }
  }

  exportTemplate(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(WORKPLAN_SERVICES.uri("export").url, payload, {
        responseType: "blob"
      });
    } else {
      throw new Error("Remove Event API call requires  Payload!");
    }
  }

  importTemplate(payload) {
    if (!payload) throw new Error("Save API call requires Payload!");
    return this._api_connector.post(WORKPLAN_SERVICES.uri("import").url, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
  addNote(payload) {
    if (typeof payload !== "object") throw new Error("Payload must be object");
    return this._api_connector.put(WORKPLAN_SERVICES.uri("note").url, payload);
  }
}

export default WorkplanService;
