import HttpStatusEventBus from "../httpStatusEventBus";
import { UNAUTHORIZED } from "http-status-codes";
import { eventBus } from "../../../../plugins/event-bus";

function unAuthorized() {
  HttpStatusEventBus.$on(UNAUTHORIZED.toString(), response => {
    if (!response.config.url.includes("/login")) return false;
    let notification_credentials = {
      text: "login_failed",
      color: "error",
      type: "error"
    };
    eventBus.$emit("snack-bar-notification", notification_credentials);
  });
}
export default unAuthorized();
