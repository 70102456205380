import RestService from "./restService";
import { CHANNELS } from "../../url/hiring";
const singleton = Symbol();
const singletonEnforcer = Symbol();
/**
 *
 */
class ChannelsService extends RestService {
  constructor(enforcer) {
    super(CHANNELS);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Agency RestService more than one");
  }
  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ChannelsService(singletonEnforcer);
    }
    return this[singleton];
  }
}

export default ChannelsService;
