import { STORAGE } from "../../url/index";
import Service from "./service";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class StorageService extends Service {
  constructor(enforcer) {
    super();
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Storage Service");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new StorageService(singletonEnforcer);
    }
    return this[singleton];
  }

  upload(formData, configuration = {}) {
    return this._api_connector.post(
      STORAGE.url,
      formData,
      Object.assign(configuration, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    );
  }

  delete(storageType, url) {
    return this._api_connector.delete(STORAGE.url, {
      params: { storageType: storageType, url: url }
    });
  }

  multipleDelete(storageType, arrUrls) {
    return this._api_connector.delete(STORAGE.uri("multiple").url, {
      params: { storageType: storageType, urls: arrUrls }
    });
  }

  download(url) {
    return this._api_connector.get(STORAGE.uri("download").url, {
      params: { url: url },
      responseType: "blob"
    });
  }
}

export default StorageService;
