import moment from "@/plugins/moment";
import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import base64 from "uuid-base64";
import store from "@/store";
import { eventBus } from "@/plugins/event-bus";
import { i18n } from "@/plugins/i18n";
import * as enums from "./enums";

const datePattern = localStorage.getItem("user") ? localStorage.getItem("user").datePattern : "YYYY-MM-DD";

export const helpers = {
  cloneDeep,
  get,
  toHash(willConvert) {
    if (!(typeof willConvert === "string" || typeof willConvert === "object"))
      throw new Error("toHash Function : Expected String or Object, got " + typeof willConvert);
    if (typeof willConvert === "object") {
      willConvert = JSON.stringify(willConvert, function (key, value) {
        return value === null || value === undefined ? "" : value;
      });
    }
    let hash = 0,
      i,
      chr;
    if (willConvert.length === 0) return hash;
    for (i = 0; i < willConvert.length; i++) {
      chr = willConvert.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  },
  convertPagination(table_pagination_object, sortAliases) {
    if (table_pagination_object === null || table_pagination_object === undefined) {
      throw "convertTablePaginationtoRequestPagination method parameter can not be NULL";
    }

    let sortBy = null;
    if (sortAliases) {
      sortBy = sortAliases[table_pagination_object.sortBy]
        ? sortAliases[table_pagination_object.sortBy]
        : table_pagination_object.sortBy;
    }

    return table_pagination_object.descending === null
      ? {
          page: table_pagination_object.page - 1,
          rowsPerPage: table_pagination_object.rowsPerPage,
          descending: true,
          sortBy: "updatedDateTime"
        }
      : {
          page: table_pagination_object.page - 1,
          rowsPerPage: table_pagination_object.rowsPerPage,
          descending: table_pagination_object.descending,
          sortBy: sortBy || table_pagination_object.sortBy
        };
  },
  toPaginationCriteria: ({ page = 1, itemsPerPage = 10, sortBy = [], sortDesc = [] }) => {
    if (!Array.isArray(sortBy) || !Array.isArray(sortDesc)) {
      throw new Error("sortBy or sortDesc option must be an array!");
    }
    return {
      page: page - 1,
      rowsPerPage: itemsPerPage,
      sortBy: sortBy.length > 0 ? sortBy[0] : "updatedDateTime",
      descending: sortDesc.length > 0 ? sortDesc[0] : true
    };
  },
  formatTimeZoneDate: (date, format = "DD MMMM YYYY") => {
    return date ? moment(date, "YYYY-MM-DD").format(format) : null;
  },
  formatTimeZoneTime: (timestamp, format = "HH:mm") => {
    let locale = localStorage.getItem("language") || "tr";
    let formatPattern = `${store.state.datePattern}THH:mm:ss`;
    return moment(timestamp, formatPattern).locale(locale).format(format);
  },
  convertToUTCDate: (time, format = "HH:mm") => {
    // Insert ve Update yaparken gönderdiğimiz saati UTC Convert yapıyoruz.
    return moment(time, "HH:mm").format(format);
  },
  formattedDate(date) {
    let format = moment.localeData().longDateFormat("LL");
    return moment.utc(date).format(format);
  },
  formattedDateTime(date) {
    if (date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return "-";
    }
  },
  showNotification(message = "İşlem sırasında hata oluştu", color = "error", no_translate = false) {
    let payload = {
      text: message,
      color: color,
      no_translate: no_translate
    };
    eventBus.$emit("snack-bar-notification", payload);
  },
  getTypeName(item) {
    if (item.leaveRequest) {
      return item.leaveRequest.leaveType.name;
    }
    if (item.countAsOvertime) {
      return i18n.t("shift.public_holiday");
    }
    if (
      item.assignedShifts.length === 0 ||
      (item.assignedShifts.length > 0 && item.assignedShifts.some((shift) => shift.shiftType === "OVERTIME"))
    ) {
      return i18n.t("shift.off_day");
    }
    if (moment().isBefore(item.date) && item.timesheet.clockInTimestamp === null) {
      return "-";
    }
    if (item.assignedShifts.length > 0 && item.timesheet.clockInTimestamp === null) {
      return i18n.t("shift.absent");
    }
    if (item.assignedShifts.length > 0 && item.timesheet.clockInTimestamp) {
      return i18n.t("shift.work_day");
    }
  },
  getStatusTypeColor(item) {
    if (item.leaveRequest) {
      return item.leaveRequest.leaveType.color;
    }
    if (item.timesheet.clockInTimestamp && item.timesheet.clockOutTimestamp) {
      return "green";
    }
    if (item.timesheet.clockInTimestamp && !item.timesheet.clockOutTimestamp) {
      return "red";
    }
    if (moment().isBefore(item.date) && item.timesheet.clockInTimestamp === null) {
      return "grey";
    }

    if (
      item.assignedShifts.length === 0 ||
      (item.assignedShifts.length > 0 && item.assignedShifts.some((shift) => shift.shiftType === "OVERTIME"))
    ) {
      return "grey";
    } else {
      return "red";
    }
  },
  getTypeColor(item) {
    if (item.timesheet.clockInTimestamp && item.timesheet.clockOutTimestamp) {
      return "green";
    }
    if (item.timesheet.clockInTimestamp && !item.timesheet.clockOutTimestamp) {
      return "red";
    }

    if (moment().isBefore(item.date) && item.timesheet.clockInTimestamp === null) {
      return "grey";
    }

    if (
      item.assignedShifts.length === 0 ||
      (item.assignedShifts.length > 0 && item.assignedShifts.some((shift) => shift.shiftType === "OVERTIME"))
    ) {
      return "grey";
    } else {
      return "red";
    }
  },
  getTypeIcon({ countAsOvertime, date, assignedShifts, leaveRequest, timesheet: { clockInTimestamp } }) {
    if (leaveRequest) {
      return "mdi-airplane";
    }
    if (countAsOvertime) {
      return "mdi-home";
    }
    if (moment().isBefore(date) && clockInTimestamp === null) {
      return "";
    }
    if (
      assignedShifts.length === 0 ||
      (assignedShifts.length > 0 && assignedShifts.some((shift) => shift.shiftType === "OVERTIME"))
    ) {
      return "mdi-bag-personal-off";
    }
    if (assignedShifts.length > 0 && clockInTimestamp === null) {
      return "mdi-block-helper";
    }
    if (assignedShifts.length > 0 && clockInTimestamp) {
      return "mdi-briefcase";
    }
  },
  isLateStatus({ latelyClockInTimeInMinutes, clockInTimestamp }) {
    if (!latelyClockInTimeInMinutes && clockInTimestamp) {
      return "teal--text";
    } else if (latelyClockInTimeInMinutes && clockInTimestamp) {
      return latelyClockInTimeInMinutes === 0 ? "teal--text" : "primary--text";
    } else {
      return null;
    }
  },
  isEarlyStatus({ earlyClockOutTimeInMinutes, clockOutTimestamp }) {
    if (!earlyClockOutTimeInMinutes && clockOutTimestamp) {
      return "teal--text";
    } else if (earlyClockOutTimeInMinutes && clockOutTimestamp) {
      return earlyClockOutTimeInMinutes === 0 ? "teal--text" : "primary--text";
    } else {
      return null;
    }
  },
  dayTypeClass(timeSheetType) {
    let dayTypeClass = {};
    switch (timeSheetType) {
      case "OFF_DAY":
        dayTypeClass = { background: "#f6f6f6" };
        break;
      case "WORKDAY":
        break;
      case "LEAVE":
        break;
      case "ABSENCE":
        break;
    }
    return dayTypeClass;
  },
  getCalculationIcon({ clockInTimestamp, clockOutTimestamp }) {
    let calculationStatusIcon = null;
    if (clockInTimestamp) calculationStatusIcon = !clockOutTimestamp ? "mdi-help-circle-outline" : "mdi-check";
    return calculationStatusIcon;
  },
  getCalculationColor({ clockInTimestamp, clockOutTimestamp }) {
    let calculationStatusColor = "green";
    if (clockInTimestamp && !clockOutTimestamp) calculationStatusColor = "red";
    return calculationStatusColor;
  },
  convertMinuteToHour(minutes) {
    if (!minutes) return "-- : --";
    let hour =
      Number.parseInt(minutes / 60) >= 10 ? Number.parseInt(minutes / 60) : "0" + Number.parseInt(minutes / 60);
    let mins =
      Number.parseInt(minutes % 60) >= 10 ? Number.parseInt(minutes % 60) : "0" + Number.parseInt(minutes % 60);
    return `${hour}:${mins}`;
  },
  formattedClock(timestamp) {
    if (timestamp) {
      return moment(timestamp, "YYYY-MM-DDTHH:mm").format("HH:mm");
    } else {
      return "-- : --";
    }
  },
  convertMinutesToTime(minutes) {
    if (minutes) {
      return moment.utc(moment.duration(minutes, "minutes").asMilliseconds()).format("HH:mm");
    } else {
      return "-- : --";
    }
  },
  isGreaterThanZero(val) {
    return val > 0;
  },
  isOvertime(totalOvertimeInMinutes) {
    return this.isGreaterThanZero(totalOvertimeInMinutes);
  },
  isMissingTime(totalMissingTimeInMinutes) {
    return this.isGreaterThanZero(totalMissingTimeInMinutes);
  },
  formatDate(date, format = "DD MMMM YYYY") {
    if (!date) return null;
    return moment(date, "YYYY-MM-DD").format(format);
  },
  getShortName(first, last) {
    return first.substring(0, 1).toUpperCase() + "" + last.substring(0, 1).toUpperCase();
  },
  lastSeen(timestamp) {
    let nowTime = moment().valueOf();
    let zamanFarki = (nowTime - timestamp) / 1000;
    let saniye = Math.round(zamanFarki);
    let dakika = Math.round(zamanFarki / 60);
    let saat = Math.round(zamanFarki / 3600);
    let gun = Math.round(zamanFarki / 86400);
    let hafta = Math.round(zamanFarki / 604800);
    let ay = Math.round(zamanFarki / 2419200);
    let yil = Math.round(zamanFarki / 29030400);
    if (saniye < 60) {
      if (saniye === 0) {
        return i18n.t("last_seen.now");
      } else {
        return saniye + " " + i18n.t("last_seen.second_ago");
      }
    } else if (dakika < 60) {
      return dakika + " " + i18n.t("last_seen.minute_ago");
    } else if (saat < 24) {
      return saat + " " + i18n.t("last_seen.hour_ago");
    } else if (gun < 7) {
      return gun + " " + i18n.t("last_seen.day_ago");
    } else if (hafta < 4) {
      return hafta + " " + i18n.t("last_seen.week_ago");
    } else if (ay < 12) {
      return ay + " " + i18n.t("last_seen.month_ago");
    } else {
      return yil + " " + i18n.t("last_seen.year_ago");
    }
  },
  humanFileSize(bytes, si) {
    let thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) return bytes + " B";

    let units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + " " + units[u];
  },
  groupBy(items, key) {
    return items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item]
      }),
      {}
    );
  },
  groupBy2(list, props) {
    return list.reduce((a, b) => {
      (a[b[props]] = a[b[props]] || []).push(b);
      return a;
    }, {});
  },
  /*
   * @params
   * uuid
   * */
  encodeID(uuid) {
    return base64.encode(uuid);
  },
  /*
   * @params
   * base64
   * */
  decodeId(id) {
    return base64.decode(id);
  },
  validationId(uuid) {
    let s = "" + uuid;
    s = s.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$");
    return s !== null;
  },
  getCurrencyIcon(code) {
    const currency = new Map([
      ["TRY", "mdi-currency-try"],
      ["USD", "mdi-currency-usd"],
      ["EUR", "mdi-currency-eur"]
    ]);
    return currency.get(code);
  },
  getCurrencyCode(code) {
    const currency = new Map([
      ["TRY", "₺"],
      ["USD", "$"],
      ["EUR", "€"]
    ]);
    return currency.get(code);
  },
  getConvertCurrency(currency) {
    if (typeof currency !== "object") {
      return "-";
    } else if (currency === null) {
      return "-";
    } else if (currency.amount === null) {
      return null;
    } else {
      return currency.amount.toFixed(2) + " " + this.getCurrencyCode(currency.code);
    }
  },
  getRequestStatusColor(status) {
    const statuses = new Map([
      ["PENDING", "orange"],
      ["APPROVED", "green"],
      ["DENIED", "primary"],
      ["CANCELLED", "grey"],
      ["NEED_EXTRA_DETAIL", "blue-gray"],
      ["UNKNOWN", "black"],
      ["REJECTED", "primary"],
      ["CLOSED", "teal"]
    ]);
    return statuses.get(status) || statuses.get("UNKNOWN");
  },
  fromEnumToArray(enumObject) {
    return Object.keys(enumObject).map((key) => ({ text: key, value: enumObject[key] }));
  },
  sortBy: (arr, by) => {
    return arr.sort(function (a, b) {
      let nameA = a[by].toString().toUpperCase(); // ignore upper and lowercase
      let nameB = b[by].toString().toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  },
  isWeekend(date) {
    const dayNumber = moment(date).day();
    if (dayNumber === 0 || dayNumber === 6) {
      return true;
    } else return false;
  },
  isSaturDay(date) {
    const dayNumber = moment(date).day();
    if (dayNumber === 6) {
      return true;
    } else return false;
  },
  isUndef: (v) => v === undefined || v === null,
  calculateActualWorkingTimeInMinutes(actualWorkingTimeInMinutes) {
    if (actualWorkingTimeInMinutes < 0) return "-";
    return this.convertMinutesToTime(actualWorkingTimeInMinutes);
  },
  getInitials(text) {
    return text.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "");
  },
  getShiftTypeIcon: (shiftType) => {
    switch (shiftType) {
      case enums.SHIFT_TYPES.FIX:
        return "mdi-clock";
      case enums.SHIFT_TYPES.OVERTIME:
        return "mdi-exit-run";
      case enums.SHIFT_TYPES.FLEXIBLE:
        return "mdi-timelapse";
      case enums.SHIFT_TYPES.DYNAMIC:
        return "mdi-atom";
    }
  },
  hasLowerCase(input) {
    return input ? /[a-z]/.test(input) : false;
  },
  hasUpperCase(input) {
    return input ? /[A-Z]/.test(input) : false;
  },
  hasDigit(input) {
    return /[0-9]/.test(input);
  },
  hasSpecialCharacter(input) {
    // .-/@#$%^&_+=()
    return input ? /[.\-/@#$%^&_+=()]/.test(input) : false;
  },
  minLength(input, length = 8) {
    let str = "";
    if (input !== null && input !== undefined) {
      str += input;
    }
    return str.length >= length;
  },
  isPasswordValid(password) {
    const rules = [this.hasLowerCase, this.hasUpperCase, this.hasDigit, this.hasSpecialCharacter, this.minLength];
    return rules.every((rule) => rule(password));
  },
  getValidationColor(valid) {
    return valid ? "success" : "error";
  },
  getValidationIcon(valid) {
    return valid ? "mdi-check-circle" : "mdi-close-circle";
  }
};

export default ({ Vue }) => {
  Vue.prototype.$helpers = helpers;
  Vue.prototype.$enums = enums;
};
