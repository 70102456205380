/**
 *
 * @type {
 *          {
 *              job_infos: string,
 *              break_times: string,
 *              leave_types: string,
 *              shifts: string,
 *              departments: string,
 *              holidays: string,
 *              branches: string,
 *              cities: string,
 *              countries: string,
 *              terminals: string,
 *              brands: string,
 *              models: string,
 *              employees: string,
 *              fixed_terminals: string,
 *              identities: string
 *          }
 *       }
 */
let endpoints = {
  job_infos: "job-infos",
  break_times: "breaks",
  leave_types: "leave-types",
  leave_policies: "leave-policies",
  leave_documents: "leave-documents",
  leave_requests: "leave-requests",
  shifts: "shifts",
  units: "units",
  holidays: "holidays",
  branches: "branches",
  cities: "cities",
  countries: "countries",
  terminals: "terminals",
  brands: "brands",
  models: "models",
  employees: "employees",
  teams: "teams",
  storage: "storage",
  registration: "registration",
  roles: "roles",
  training_category: "training/category",
  training_evaluation_type: "training/evaluation-type",
  training_lecturer: "training/lecturer",
  training: "training",
  training_plan: "training/plan",
  performance_category: "performance/category",
  performance_groups: "performance/groups",
  performance_items: "performance/item",
  performance_template: "performance/template",
  performance_score_settings: "performance/score-settings",
  performance_evaluation: "performance/evaluation",
  service_lines: "service-lines",
  boarding_template: "boarding/template",
  boarding_item: "boarding/item",
  boarding_category: "boarding/category",
  assets: "assets",
  asset_categories: "asset-categories",
  asset_brands: "asset-brands",
  asset_models: "asset-models",
  debit_tickets: "debit-tickets",
  documents: "documents",
  document_types: "document-types",
  papers: "papers",
  paper_types: "paper-types",
  paper_request: "paper-request",
  paper_request_process: "paper-request-process",
  expenses: "expenses",
  expense_types: "expense-types",
  diseases: "diseases",
  disease_categories: "disease-categories",
  medicines: "medicines",
  surgery_operations: "surgery-operations",
  health_categories: "health-categories",
  health_info: "health-info",
  costs: "costs",
  cost_types: "cost-types",
  cost_categories: "cost-categories",
  notes: "notes",
  messages: "messages",
  vehicles: "vehicles",
  vehicle_brands: "vehicle-brands",
  vehicle_models: "vehicle-models",
  agencies: "agencies",
  fixed_terminals: "access-terminals",
  identities: "identities",
  account: "account",
  change_password: "change-password",
  new_change_password: "account/change-password",
  send_password_reset_token: "send-password-reset-token",
  worksheets: "worksheets",
  access_terminals: "access-terminals",
  clock_records: "clock-records",
  time_sheets: "timesheets",
  organization: "organizations",
  leave_groups_service: "leave-groups",
  report_service: "reports",
  shifts_service: "shifts",
  shift_groups_service: "shift-groups",
  workplan_service: "workplans",
  terminal_events_service: "terminal-events",
  clock_records_service: "clock-records",
  service_organization_shift_parameter: "organization/shift-parameters",
  service_payroll: "payrolls",
  service_subsistence: "subsistences",
  service_imprest: "imprests",
  service_location: "locations",
  communication_manager: "comm-managers",
  chat_groups: "chat/groups",
  activity_type: "activity-types",
  activity: "activities",
  overtimes: "overtimes",
  authentication: {
    login: "login",
    refresh_token: "account/refresh-token",
    change_password: "change-password",
    send_password_reset_token: "send-password-reset-token",
    reset_password: "password-reset",
    logout: "account/logout"
  },
  suggestions: "suggestions",
  notes: "notes",
  feedback_result: "feedback-result",
  travel: "travel",
  training_requests: "training-requests",
  survey: {
    base: "survey",
    answer: "survey-question-answers"
  },
  workplan_requests: "workplan-requests"
};
export default endpoints;
