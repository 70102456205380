import RestService from "./restService";
import { AUTHENTICATION } from "../../url/";
import { FIREBASE_AUTH } from "@/plugins/firebase";
import { AuthenticationError, LogOutError } from "./auth.service";
import { ACCEPTED, OK, UNAUTHORIZED } from "http-status-codes";
import { NO_REFRESH_TOKEN } from "../../../response/status/idenfit";
import { TOKEN_PREFIX } from "../../../../base/constants";
import { storageManager } from "../../../../plugins/storage-manager";
import store from "@/store";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class AuthenticationService extends RestService {
  constructor(enforcer) {
    super(AUTHENTICATION);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Authentication RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new AuthenticationService(singletonEnforcer);
    }
    return this[singleton];
  }

  /**
   *
   * @param payload
   * @param config
   * @returns {AxiosPromise<any>}
   */
  async login(payload) {
    return this._api_connector
      .post(AUTHENTICATION.login.url, null, {
        params: {
          username: payload.username,
          password: payload.password,
          captcha: payload.captcha
        }
      })
      .then((response) => {
        if (response) {
          if (response) {
            return {
              user: response.data,
              token: {
                accessToken: response.headers["authorization"],
                refreshToken: response.headers["refresh-token"]
              }
            };
          } else {
            return null;
          }
        }
      });
  }
  loginWithSSO({ identityProvider, accessToken }) {
    return this._api_connector.post(`oauth/token/${identityProvider}`, { token: accessToken }).then((response) => {
      if (!response) return null;
      return {
        user: response.data,
        token: {
          accessToken: response.headers["authorization"],
          refreshToken: response.headers["refresh-token"]
        }
      };
    });
  }
  async passChange(payload) {
    return this._api_connector.put(AUTHENTICATION.newChangePassword.url, null, { params: { password: payload } });
  }
  async mobileLogin(payload) {
    this._api_connector.unmount401Interceptor();

    return this._api_connector
      .post(AUTHENTICATION.login.url, null, {
        params: {
          username: payload.username,
          password: payload.password
        },
        headers: {
          from: "mobile"
        }
      })
      .then((response) => {
        if (response) {
          if (response) {
            return {
              user: response.data,
              token: {
                accessToken: response.headers["authorization"],
                refreshToken: response.headers["refresh-token"]
              }
            };
          } else {
            return null;
          }
        }
      })
      .finally(() => this._api_connector.mount401Interceptor());
  }

  async logout(accessToken) {
    return this._api_connector.post(AUTHENTICATION.logout.url, null, {
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    });
  }

  changePassword(payload, config = {}) {
    return this._api_connector.post(AUTHENTICATION.changePassword.url, payload, config);
  }

  async refreshToken() {
    return this._api_connector
      .post(AUTHENTICATION.refreshToken.url, null, {
        headers: {
          "Refresh-Token": store.state.auth.refreshToken
        }
      })
      .then(({ headers, status, data }) => {
        if (status === OK) {
          if (data.error && data.error.code === NO_REFRESH_TOKEN) {
            storageManager.reset();
            this._api_connector.setRemoveHeaders();
            this._api_connector.unmount401Interceptor();
            return {
              error: true,
              message: "Refresh token not found in idenfit"
            };
          }
        } else if (status === UNAUTHORIZED) {
          return {
            error: true,
            message: "No refresh token found in idenfit"
          };
        } else if (status === ACCEPTED) {
          return {
            accessToken: headers.authorization.substring(TOKEN_PREFIX.length)
          };
        }
      });
  }
  async hiringRefreshToken() {
    return this._api_connector
      .post(AUTHENTICATION.refreshToken.url, null, {
        headers: {
          "Refresh-Token": store.state.auth.refreshToken
        }
      })
      .then(({ headers, status, data }) => {
        if (status === OK) {
          if (data.error && data.error.code === NO_REFRESH_TOKEN) {
            storageManager.reset();
            this._api_connector.setRemoveHeaders();
            this._api_connector.unmount401Interceptor();
            return {
              error: true,
              message: "Refresh token not found in idenfit"
            };
          }
        } else if (status === UNAUTHORIZED) {
          return {
            error: true,
            message: "No refresh token found in idenfit"
          };
        } else if (status === ACCEPTED) {
          return {
            accessToken: headers.authorization.substring(TOKEN_PREFIX.length)
          };
        }
      });
  }

  /**
   *
   * @returns {AxiosPromise<any>}
   */
  sendPasswordResetToken(payload) {
    return this._api_connector.post(AUTHENTICATION.sendPasswordResetToken.url, payload);
  }

  newPassword(payload) {
    return this._api_connector.post(AUTHENTICATION.resetPassword.url, payload);
  }

  /**
   * @returns {Promise<void>}
   */
  async loginOnFirebase(token) {
    // await FIREBASE_APP.auth().setPersistence(FIREBASE_APP.auth.Auth.Persistence.SESSION);
    return new Promise(async (resolve, reject) => {
      await FIREBASE_AUTH.signInWithCustomToken(token)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(new AuthenticationError(-4001, error.message));
        });
    });
  }

  /**
   * @returns {Promise<void>}
   */
  async logOutOnFirebase() {
    return new Promise(async (resolve, reject) => {
      await FIREBASE_AUTH.signOut()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(new LogOutError(-2002, error.message));
        });
    });
  }
}

export default AuthenticationService;
