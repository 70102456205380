import { hiringConnector } from "../../../clients";

/**
 *
 */
class Service {
  constructor() {
    this._api_connector = hiringConnector.getClient();
  }
}

export default Service;
