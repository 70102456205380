import VueApollo from "vue-apollo";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { BatchHttpLink } from "apollo-link-batch-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink, from, Observable } from "apollo-link";
import { router } from "../router";
import { onError } from "apollo-link-error";
import { RefreshTokenNotFoundError } from "../http/api/services/rest/auth.service";
import { FORBIDDEN, INTERNAL_SERVER_ERROR, OK, UNAUTHORIZED } from "http-status-codes";
import { FORBIDDEN_TAG, HIRING_TOKEN_KEY, TOKEN_KEY, UNAUTHORIZED_TAG } from "../base/constants";
import { storageManager } from "./storage-manager";
import store from "@store";

const promiseToObservable = (promise) => {
  return new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) {
          return;
        }
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => {
        subscriber.error(err);
        if (err instanceof RefreshTokenNotFoundError) {
          router.replace({
            name: "login"
          });
        }
      }
    );
  });
};
const errorlink = onError(({ graphQLErrors, networkError, response, operation, forward }) => {
  if (graphQLErrors && graphQLErrors.filter((e) => e).length > 0)
    graphQLErrors.map(({ message = "", status = OK }) => {
      if (UNAUTHORIZED_TAG === message || status === UNAUTHORIZED) {
        console.warn(`You've attempted to access UNAUTHORIZED section`);
        if (router && router.currentRoute && router.currentRoute.path !== "/login") {
          router.replace({
            name: "login"
          });
        }
      }
      if (FORBIDDEN_TAG === message || status === FORBIDDEN) {
        console.warn(`You've attempted a FORBIDDEN action`);
      }
      return null;
    });

  if (networkError && networkError.statusCode === UNAUTHORIZED) {
    const oldHeaders = operation.getContext().headers;
    const promise = store.dispatch("auth/REFRESH_TOKEN");
    return promiseToObservable(promise).flatMap(() => {
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: `Bearer ${storageManager.get(TOKEN_KEY)}` || null
        }
      });
      return forward(operation);
    });
  }

  if (networkError && networkError.statusCode === FORBIDDEN) {
    console.warn("FORBIDDEN");
  }
  if (networkError && networkError.statusCode >= INTERNAL_SERVER_ERROR) {
    console.warn("SERVER ERROR");
  }
});
const errorlinkHiring = onError(({ graphQLErrors, networkError, response, operation, forward }) => {
  if (graphQLErrors && graphQLErrors.filter((e) => e).length > 0)
    graphQLErrors.map(({ message = "", status = OK }) => {
      if (UNAUTHORIZED_TAG === message || status === UNAUTHORIZED) {
        console.warn(`You've attempted to access UNAUTHORIZED section`);
        if (router && router.currentRoute && router.currentRoute.path !== "/login") {
          router.replace({
            name: "login"
          });
        }
      }
      if (FORBIDDEN_TAG === message || status === FORBIDDEN) {
        console.warn(`You've attempted a FORBIDDEN action`);
      }
      return null;
    });

  if (networkError && networkError.statusCode === UNAUTHORIZED) {
    const oldHeaders = operation.getContext().headers;
    const promise = store.dispatch("auth/HIRING_REFRESH_TOKEN");
    return promiseToObservable(promise).flatMap((newToken) => {
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: `Bearer ${storageManager.get(HIRING_TOKEN_KEY)}` || null
        }
      });
      return forward(operation);
    });
  }
  if (networkError && networkError.statusCode === FORBIDDEN) {
    // Do something
    console.warn("FORBIDDEN");
  }
  if (networkError && networkError.statusCode >= INTERNAL_SERVER_ERROR) {
    // eslint-disable-next-line
    console.warn("SERVER ERROR");
    router.replace(`/error-page/${networkError.statusCode}`);
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${storageManager.get(TOKEN_KEY)}` || null
    }
  }));

  return forward(operation);
});
const baseURL = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_GRAPHQL_ENDPOINT}`;
const hiringManagerAuthMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${storageManager.get(HIRING_TOKEN_KEY)}` || null
    }
  }));

  return forward(operation);
});

/**
 *
 * @type {BatchHttpLink}
 */
const httpLink = new HttpLink({
  uri: baseURL
});
const hiringManagerHttpLink = new BatchHttpLink({
  uri: `${process.env.VUE_APP_HIRING_BASE_URL}hiring-manager/query`
});
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore"
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all"
  },
  mutate: {
    errorPolicy: "all"
  }
};
/**
 *
 * @type {ApolloClient<NormalizedCacheObject>}
 */
export const client = new ApolloClient({
  link: from([authMiddleware, errorlink, httpLink]),
  shouldBatch: false,
  cache: new InMemoryCache({
    addTypename: false
  }),
  connectToDevTools: true,
  defaultOptions
});

export const hiringService = new ApolloClient({
  link: from([hiringManagerAuthMiddleware, errorlinkHiring, hiringManagerHttpLink]),
  shouldBatch: false,
  cache: new InMemoryCache({
    addTypename: false
  }),
  connectToDevTools: true,
  defaultOptions
});

/**
 *
 * @param app
 * @param Vue
 */
const apolloProvider = new VueApollo({
  defaultClient: client,
  clients: {
    hiring: hiringService
  },
  defaultOptions: {
    $query: {
      fetchPolicy: "no-cache"
    }
  },
  errorHandler(error) {
    // eslint-disable-next-line no-console
    console.log(
      "%cError",
      "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
      error.message
    );
  }
});

export default ({ app, Vue }) => {
  app["apolloProvider"] = apolloProvider;
  Vue.use(VueApollo);
};
