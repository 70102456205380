export const TOKEN_PREFIX = "Bearer ";
export const AUTHORIZATION_HEADER_KEY = "Authorization";
export const TIMEOUT = 900000;
export const TOKEN_KEY = "accessToken";
export const HIRING_TOKEN_KEY = "hiringAccessToken";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const HIRING_REFRESH_TOKEN_KEY = "hiringRefreshToken";
export const LANGUAGE_KEY = "language";
export const UNAUTHORIZED_TAG = "UNAUTHORIZED";
export const FORBIDDEN_TAG = "FORBIDDEN";
export const USER = "user";
export const ORGANIZATION = "organization";
