import RestService from "./restService";
import { EMPLOYEE_SERVICES, WORKSHEET } from "../../url";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class WorksheetService extends RestService {
  constructor(enforcer) {
    super(WORKSHEET.base);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Worksheet Service");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new WorksheetService(singletonEnforcer);
    }
    return this[singleton];
  }
  createClockRecord(payload) {
    if (!payload) throw new Error("API call requires Payload!");
    if (typeof payload === "object") return this._api_connector.post(WORKSHEET.clockRecords.url, payload);
  }
}

export default WorksheetService;
