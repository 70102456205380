import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "@/i18n";
import { storageManager } from "./storage-manager";
import { LANGUAGE_KEY } from "../base/constants";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: storageManager.get(LANGUAGE_KEY) || "tr",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "tr",
  messages: messages
});

export default ({ app }) => {
  app["i18n"] = i18n;
};
