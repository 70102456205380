import RestService from "./restService";
import { TRAVEL } from "../../url/index";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class TravelService extends RestService {
  constructor(enforcer) {
    super(TRAVEL);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct TRAVEL RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new TravelService(singletonEnforcer);
    }
    return this[singleton];
  }

  approve(travelId, payload) {
    if (travelId && payload && typeof payload === "object") {
      return this._api_connector.put(TRAVEL.uri("processes").uri(travelId).url, payload);
    } else throw new Error("Save API call requires Payload!");
  }

  createDetail(travelId, payload) {
    if (travelId && payload && typeof payload === "object") {
      return this._api_connector.post(TRAVEL.uri(travelId).uri("travelDetail").url, payload);
    } else throw new Error("Save API call requires Payload!");
  }

  updateDetail(travelId, payload) {
    if (travelId && payload && typeof payload === "object") {
      return this._api_connector.put(TRAVEL.uri(travelId).uri("travelDetail").uri(payload.id).url, payload);
    } else throw new Error("Save API call requires Payload!");
  }

  deleteDetail(travelId, detailId) {
    if (travelId && detailId) {
      return this._api_connector.delete(TRAVEL.uri(travelId).uri("travelDetail").uri(detailId).url);
    } else throw new Error("Save API call requires Payload!");
  }

  reports(endpoint, fileType, payload) {
    if (endpoint && fileType && payload && typeof payload === "object") {
      return this._api_connector.post(TRAVEL.uri("reports").uri(endpoint).uri(fileType).url, payload, {
        responseType: "blob"
      });
    } else {
      throw new Error("Reports API call requires endpoint, fileType, and Payload!");
    }
  }
}

export default TravelService;
