import RestService from "./restService";
import { WORKPLAN_REQUESTS } from "../../url/index";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class WorkplanRequestService extends RestService {
  constructor(enforcer) {
    super(WORKPLAN_REQUESTS);
    if (enforcer !== singletonEnforcer)
      throw new Error("Cannot construct WorkplanRequestService RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new WorkplanRequestService(singletonEnforcer);
    }
    return this[singleton];
  }

  approve(id, payload) {
    if (id && payload && typeof payload === "object") {
      return this._api_connector.put(WORKPLAN_REQUESTS.uri("processes").uri(id).url, payload);
    } else throw new Error("Save API call requires Payload!");
  }

  cancel(id, reason) {
    if (id) {
      return this._api_connector.put(WORKPLAN_REQUESTS.uri(id).uri("cancel").url, { reason });
    } else throw new Error("Save API call requires Payload!");
  }
}

export default WorkplanRequestService;
