import RestService from "./restService";
import { ASSESSMENT } from "../../url/hiring";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class AssessmentService extends RestService {
  constructor(enforcer) {
    super(ASSESSMENT);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Agency RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new AssessmentService(singletonEnforcer);
    }
    return this[singleton];
  }

  create(payload) {
    if (payload) {
      return this._api_connector.post(ASSESSMENT.uri("create").url, payload);
    } else throw new Error("Save API call requires IDs!");
  }
}

export default AssessmentService;
