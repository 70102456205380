import RestService from "./restService";
import { INTERVIEW } from "../../url/hiring";
const singleton = Symbol();
const singletonEnforcer = Symbol();
/**
 *
 */
class InterviewService extends RestService {
  constructor(enforcer) {
    super(INTERVIEW);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Agency RestService more than one");
  }
  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new InterviewService(singletonEnforcer);
    }
    return this[singleton];
  }

  changeStatus(payload) {
    if (payload) {
      return this._api_connector.patch(INTERVIEW.uri("update-status").url, payload);
    } else throw new Error("Save API call requires Payload!");
  }

  sendMail(payload) {
    if (payload) {
      return this._api_connector.post(INTERVIEW.uri("mail").url, payload);
    } else throw new Error("Save API call requires Payload!");
  }
}

export default InterviewService;
