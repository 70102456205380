import HttpStatusEventBus from "../../httpStatusEventBus";
import { ENTITY_NOT_FOUND } from "../index";
import { eventBus } from "../../../../../plugins/event-bus";

function entity_not_found() {
  HttpStatusEventBus.$on(ENTITY_NOT_FOUND.toString(), response => {
    let notification_credentials = {
      text: "entity_not_found",
      color: "error",
      type: "error"
    };
    eventBus.$emit("snack-bar-notification", notification_credentials);
  });
}
export default entity_not_found();
