import RestService from "./restService";
import { ACCESS_TERMINALS, EMPLOYEE_SERVICES } from "../../url/index";
import EventBus from "@/bus";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class EmployeeService extends RestService {
  constructor(enforcer) {
    super(EMPLOYEE_SERVICES.employee);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Employee RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new EmployeeService(singletonEnforcer);
    }
    return this[singleton];
  }

  /**
   *
   * @returns {*}
   */

  units() {
    return this._api_connector.get(EMPLOYEE_SERVICES.units.url);
  }

  /**
   *
   * @returns {*}
   */

  branches() {
    return this._api_connector.get(EMPLOYEE_SERVICES.branches.url);
  }

  /**
   *
   * @returns {*}
   */
  jobInfos() {
    return this._api_connector.get(EMPLOYEE_SERVICES.jobInfos.url);
  }

  /**
   *
   * @param id
   * @param payload
   * @param configuration
   * @returns {AxiosPromise<any>}
   */

  image(id, payload, configuration = {}) {
    return this._api_connector.put(
      EMPLOYEE_SERVICES.employee.uri(id).uri("profile").uri("image").url,
      payload,
      configuration
    );
  }

  /**
   *
   * @param id
   * @returns {*}
   */
  deleteImage(id) {
    return this._api_connector.delete(EMPLOYEE_SERVICES.employee.uri(id).uri("profile").uri("image").url);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */

  updateEmployeeCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  createEmployeeCredentials(id, payload) {
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri(id).url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateContactCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("contact").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  createContactCredentials(id, payload) {
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri(id).uri("contact").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */

  updatePersonalCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("personal").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  createPersonalCredentials(id, payload) {
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri(id).uri("personal").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateEmploymentCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("employment").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  createEmploymentCredentials(id, payload) {
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri(id).uri("employment").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */

  updateFinancialCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("financial").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  createFinancialCredentials(id, payload) {
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri(id).uri("financial").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   */
  updateFamilialCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("family").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  createFamilialCredentials(id, payload) {
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri(id).uri("family").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateBodySizeCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("body-size").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  createBodySizeCredentials(id, payload) {
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri(id).uri("body-size").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateProfileCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("profile").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  createProfileCredentials(id, payload) {
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri(id).uri("profile").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateEducationalCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("education").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any>}
   */

  createEducationalCredentials(id, payload) {
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri(id).uri("education").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   */
  updateSocialMediaCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("social-media").url, payload);
  }

  createSocialMediaCredentials(id, payload) {
    if (!payload) throw new Error("Payload can not be null!");
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri(id).uri("social-media").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   */
  updateManagementCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("management").url, payload);
  }

  createManagementCredentials(id, payload) {
    if (!payload) throw new Error("Payload can not be null!");
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri(id).uri("management").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   */
  updatePermissionCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("permission").url, payload);
  }

  createPermissionCredentials(id, payload) {
    if (!payload) throw new Error("Payload can not be null!");
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri(id).uri("permission").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   */
  updateNotificationCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("notification").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   */
  updateExpenseSettingsCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("expense-settings").url, payload);
  }

  createNotificationCredentials(id, payload) {
    if (!payload) throw new Error("Payload can not be null!");
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri(id).uri("notification").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {firebase.storage.UploadTask | AxiosPromise<any> | * | void | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateLeaveSettingsCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("leave-settings").url, payload);
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {firebase.storage.UploadTask | AxiosPromise<any> | * | void | IDBRequest<IDBValidKey> | Promise<void>}
   */

  updateTimeSettingsCredentials(id, payload) {
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("time-settings").url, payload);
  }

  //api/v1/employees/notification/send-registration

  /**
   *
   * @param employeeIds
   * @returns {AxiosPromise<any> | * | void | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  sendRegistrationMail(employeeIds = []) {
    if (!Array.isArray(employeeIds)) throw new Error("Expected array , got " + typeof employeeIds);
    return this._api_connector.post(
      EMPLOYEE_SERVICES.employee.uri("notification").uri("send-registration-credentials").url,
      null,
      {
        params: {
          ids: employeeIds
        }
      }
    );
  }

  /**
   *
   * @param branchId
   * @param employeeIds
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateBranch(payload = {}) {
    if (!Array.isArray(payload.employees)) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    if (!payload.branch) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }
    //if (!Array.isArray(employees)) throw new Error("Expected array,got " + typeof employees);
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri("update").uri("branch").url, payload);
  }

  /**
   *
   * @param unitId
   * @param employeeIds
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateUnit(payload = {}) {
    if (!Array.isArray(payload.employees)) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    if (!payload.unit) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri("update").uri("unit").url, payload);
  }

  /**
   *
   * @param teamId
   * @param employeeIds
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateTeam(payload = {}) {
    if (!Array.isArray(payload.employees)) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    if (!payload.team) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri("update").uri("team").url, payload);
  }

  updateShiftParameter(selection, payload = {}) {
    if (!Array.isArray(payload.employees)) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }
    if (!selection) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    return this._api_connector.put(
      EMPLOYEE_SERVICES.employee.uri("update").uri("shift-parameter").uri(selection).url,
      payload
    );
  }

  /**
   *
   * @param jobInfo
   * @param employees
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateJobInfo(payload = {}) {
    if (!Array.isArray(payload.employees)) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    if (!payload.jobInfo) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri("update").uri("job-info").url, payload);
  }

  /**
   *
   * @param jobInfo
   * @param employees
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateManager(payload = {}) {
    if (!Array.isArray(payload.employees)) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    if (!payload.manager) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri("update").uri("manager").url, payload);
  }

  /**
   *
   * @param jobInfo
   * @param employees
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateLeaveManager(payload = {}) {
    if (!Array.isArray(payload.employees)) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    if (!Array.isArray(payload.leaveManagers)) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri("update").uri("leave-manager").url, payload);
  }

  /**
   *
   * @param jobInfo
   * @param employees
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateShiftManager(payload = {}) {
    if (!Array.isArray(payload.employees)) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    if (!Array.isArray(payload.shiftManagers)) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri("update").uri("shift-manager").url, payload);
  }

  /**
   *
   * @param jobInfo
   * @param employees
   * @returns {AxiosPromise<any> | firebase.storage.UploadTask | IDBRequest<IDBValidKey> | Promise<void>}
   */
  updateOvertimeManager(payload = {}) {
    if (!Array.isArray(payload.employees)) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }

    if (!Array.isArray(payload.overtimeManagers)) {
      EventBus.$emit("snack-bar-notification", {
        text: "data_integrity_violation",
        error: "error",
        color: "error"
      });
      return false;
    }
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri("update").uri("overtime-manager").url, payload);
  }

  /**
   *
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  createIdentity(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(EMPLOYEE_SERVICES.identities.url, payload);
    } else throw new Error("Save API call requires Payload!");
  }

  /**
   *
   * @param terminalId
   * @param employeeId
   * @returns {*}
   */

  createIdentityTemplateOnCard(terminalId, employeeId) {
    if (terminalId && employeeId) {
      return this._api_connector.post(ACCESS_TERMINALS.base.uri(terminalId).uri("scan-for-toc").url, null, {
        params: {
          employee: employeeId
        }
      });
    } else throw new Error("Save API call requires Payload!");
  }

  /**
   *
   * @param id
   * @returns {AxiosPromise<any>}
   */
  deleteIdentity(id) {
    if (id) {
      return this._api_connector.delete(EMPLOYEE_SERVICES.identities.uri(id).url);
    } else {
      throw new Error("Delete API call requires ID parameter !");
    }
  }

  /**
   *
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  shareIdentity(id, payload) {
    if (id && payload && typeof payload === "object") {
      return this._api_connector.put(EMPLOYEE_SERVICES.identities.uri(id).url, payload);
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  /**
   *
   * @param id
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  updateMobileDeviceCredentials(id, payload) {
    if (id && payload && typeof payload === "object") {
      return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("mobile-device").url, payload);
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  assignWorksheet(id, payload) {
    if (id && payload && typeof payload === "object") {
      return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(id).uri("worksheet-assignment").url, payload);
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  report(fileType, payload) {
    // reports/list
    if (payload && typeof payload === "object") {
      return this._api_connector.post(
        EMPLOYEE_SERVICES.employee.uri("reports").uri("list").uri(fileType).url,
        payload,
        {
          responseType: "blob"
        }
      );
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  reports(fileType, status, payload) {
    if (payload && typeof payload === "object" && status) {
      return this._api_connector.post(
        EMPLOYEE_SERVICES.employee.uri("reports").uri(status).uri(fileType).url,
        payload,
        {
          responseType: "blob"
        }
      );
    } else {
      throw new Error("Reports API call requires PAYLOAD!!!!");
    }
  }

  exportEmployeeTemplate() {
    return this._api_connector.get(EMPLOYEE_SERVICES.employee.uri("import").url, {
      responseType: "blob"
    });
  }

  importEmployees(formData, sendNotification) {
    if (!formData) throw new Error("Save API call requires formData!");
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri("import").url, formData, {
      params: {
        notifyLater: !sendNotification
      },
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  downloadTemplate() {
    return this._api_connector.get(EMPLOYEE_SERVICES.employee.uri("import").url, {
      responseType: "blob"
    });
  }

  exportEmployeeLeaveBalanceTemplate() {
    return this._api_connector.get(EMPLOYEE_SERVICES.employee.uri("leave-settings/balance/import").url, {
      responseType: "blob"
    });
  }

  importEmployeeLeaveBalance(payload) {
    if (!payload) throw new Error("Save API call requires Payload!");
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri("leave-settings/balance/import").url, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  uploadEmployees(formData, sendNotification) {
    if (!formData) throw new Error("Save API call requires formData!");
    return this._api_connector.post(this._service_api.uri("import").url, formData, {
      params: {
        notifyLater: !sendNotification
      },
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  updateLeaveBalance(employeeId, payload) {
    if (payload && employeeId) {
      return this._api_connector.post(
        EMPLOYEE_SERVICES.employee.uri(employeeId).uri("leave-settings").uri("balance").url,
        payload
      );
    } else throw new Error("Save API call requires Payload!");
  }

  updateTrainingInfoCredentials(employeeId, payload) {
    if (payload && employeeId) {
      return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(employeeId).uri("training-info").url, payload);
    } else throw new Error("Update API call requires ID and Payload!");
  }

  saveEmployee(payload, sendNotification) {
    if (!payload && typeof payload !== "object") throw new Error("Save API call requires Payload!");
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.url, payload, {
      params: {
        notifyLater: !sendNotification
      }
    });
  }

  sendWelcomeNotificationSend(id) {
    if (!id) throw new Error("Save API call requires Id!");
    return this._api_connector.put(
      EMPLOYEE_SERVICES.employee.uri(id).uri("credential").uri("send-welcome-notification").url
    );
  }

  updateBoardingStatus(employeeId, id, status) {
    if (!employeeId || !id) throw new Error("Save API call requires ID or status!");
    return this._api_connector.put(
      EMPLOYEE_SERVICES.employee.uri(employeeId).uri("boardingItemResults").uri(id).url,
      null,
      {
        params: {
          success: status
        }
      }
    );
  }

  clearBoarding(employeeId, status) {
    if (!employeeId) throw new Error("Save API call requires Payload!");
    return this._api_connector.delete(EMPLOYEE_SERVICES.employee.uri(employeeId).uri("boardingItemResults").url, {
      params: {
        onboarding: status
      }
    });
  }

  updateBoarding(employeeId, id) {
    if (!employeeId || !id) throw new Error("Save API call requires ID or status!");
    return this._api_connector.put(EMPLOYEE_SERVICES.employee.uri(employeeId).uri("boardingItemResults").url, null, {
      params: {
        boarding: id
      }
    });
  }

  vedubox(employeeId) {
    if (employeeId) {
      return this._api_connector.get(EMPLOYEE_SERVICES.employee.uri("vedubox").uri(employeeId).url);
    }
    throw new Error("API call requires employeeId parameter!");
  }

  acceptGdpr() {
    return this._api_connector.post(EMPLOYEE_SERVICES.employee.uri("gdpr/accept").url);
  }
}

export default EmployeeService;
