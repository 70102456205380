import RestService from "./restService";
import { AUTHENTICATION } from "../../url/";
import { ACCEPTED, OK, UNAUTHORIZED } from "http-status-codes";
import { NO_REFRESH_TOKEN } from "../../../response/status/idenfit";
import { HIRING_REFRESH_TOKEN_KEY, HIRING_TOKEN_KEY, TOKEN_PREFIX } from "../../../../base/constants";
import { storageManager } from "../../../../plugins/storage-manager";
import store from "@/store";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class AuthenticationService extends RestService {
  constructor(enforcer) {
    super(AUTHENTICATION);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Authentication RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new AuthenticationService(singletonEnforcer);
    }
    return this[singleton];
  }

  async refreshToken() {
    return this._api_connector
      .post("hiring-manager/v1.0/api/client-account/refresh-token", null, {
        headers: {
          "Refresh-Token": store.state.auth.hiringRefreshToken,
          Organization: store.state.auth.organization
        }
      })
      .then(({ headers, status, data }) => {
        if (status === OK) {
          if (data.error && data.error.code === NO_REFRESH_TOKEN) {
            storageManager.remove(HIRING_TOKEN_KEY);
            storageManager.remove(HIRING_REFRESH_TOKEN_KEY);
            this._api_connector.setRemoveHeaders();
            this._api_connector.unmount401Interceptor();
            return {
              error: true,
              message: "Refresh token not found in idenfit"
            };
          }
        } else if (status === UNAUTHORIZED) {
          return {
            error: true,
            message: "No refresh token found in idenfit"
          };
        } else if (status === ACCEPTED) {
          return {
            accessToken: headers.authorization.substring(TOKEN_PREFIX.length)
          };
        }
      });
  }
}

export default AuthenticationService;
