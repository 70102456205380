import tr from "./tr";
import en from "./en";
import de from "./de";
import esp from "./esp";

export default {
  en,
  tr,
  de,
  esp
};
