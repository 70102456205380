import RestService from "./restService";
import { SHIFTS } from "../../url";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class ShiftService extends RestService {
  constructor(enforcer) {
    super(SHIFTS);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Shift RestService");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ShiftService(singletonEnforcer);
    }
    return this[singleton];
  }
}

export default ShiftService;
