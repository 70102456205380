import RestService from "./restService";
import { OVERTIME } from "../../url/index";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class OvertimeService extends RestService {
  constructor(enforcer) {
    super(OVERTIME);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct SUBSISTENCE RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new OvertimeService(singletonEnforcer);
    }
    return this[singleton];
  }

  approve(overtimeId, payload) {
    if (overtimeId && payload && typeof payload === "object") {
      return this._api_connector.put(OVERTIME.uri("processes").uri(overtimeId).url, payload);
    } else throw new Error("Save API call requires Payload!");
  }
}

export default OvertimeService;
