import ChannelTypeService from "./hiring/channelType.service";
import ChannelsService from "./hiring/channels.service";
import SkillService from "./hiring/skill.service";
import JobPositionLevelService from "./hiring/job_position_level.service";
import JobPostingService from "./hiring/job_posting.service";
import JobPostingChannelsService from "./hiring/job_posting_channels.service";
import ApplicantsService from "./hiring/applicants.service";
import AssessmentService from "./hiring/assessment.service";
import AssessmentDetailService from "./hiring/assessment_detail.service";
import BlackListService from "./hiring/black_list.service";
import InterviewService from "./hiring/interview.service";
import StagesService from "./hiring/stages.service";
import BoardingService from "./hiring/boarding.service";
import BlackListManagerService from "./hiring/blacklist_manager.service";
import ApplicantEvaluatorService from "./hiring/applicant_evaluator.service";
import AuthenticationService from "./hiring/authentication.service";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class HiringService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error("Cannot construct RestService Facade more than one");
    }
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new HiringService(singletonEnforcer);
    }
    return this[singleton];
  }

  get authenticationService() {
    return AuthenticationService.instance;
  }
  get channelTypeService() {
    return ChannelTypeService.instance;
  }

  get channelsService() {
    return ChannelsService.instance;
  }

  get skillService() {
    return SkillService.instance;
  }

  get jobPositionLevelService() {
    return JobPositionLevelService.instance;
  }

  get jobPostingService() {
    return JobPostingService.instance;
  }

  get jobPostingChannelsService() {
    return JobPostingChannelsService.instance;
  }

  get applicantsService() {
    return ApplicantsService.instance;
  }

  get assessmentService() {
    return AssessmentService.instance;
  }

  get assessmentDetailService() {
    return AssessmentDetailService.instance;
  }

  get blackListService() {
    return BlackListService.instance;
  }

  get interviewService() {
    return InterviewService.instance;
  }

  get stagesService() {
    return StagesService.instance;
  }

  get boardingService() {
    return BoardingService.instance;
  }

  get blacklistManagerService() {
    return BlackListManagerService.instance;
  }

  get applicantEvaluatorService() {
    return ApplicantEvaluatorService.instance;
  }
}

export default ({ Vue }) => {
  Vue.prototype.$api_hiring = HiringService.instance;
};
