import axios from "axios";
import Qs from "qs";
import helpers from "@/helpers";
import store from "@/store";
import { httpEventBus } from "../../event/httpEventBus";
import { UNAUTHORIZED } from "http-status-codes";

const paramsSerializer = (params) => Qs.stringify(params, { indices: false, arrayFormat: "repeat" });

const TIMEOUT = 900000;
const AUTHORIZATION_HEADER_KEY = "Authorization";

export default class IdenfitConnector {
  constructor(BASE_URL) {
    this.client = axios.create({
      baseURL: BASE_URL,
      timeout: TIMEOUT,
      paramsSerializer: paramsSerializer
    });
    this._401interceptor = null;
    this.isAlreadyFetchingAccessToken = false;
    this.subscribers = [];

    this.unmount401Interceptor();
    this.mount401Interceptor();
    this.mountRequestInterceptor();
  }

  create(BASE_URL) {
    this.client = axios.create({
      baseURL: BASE_URL,
      timeout: TIMEOUT,
      paramsSerializer: paramsSerializer
    });
  }

  getClient() {
    return this;
  }

  setHeader(field, value) {
    this.client.defaults.headers.common[field] = value;
  }

  setAuthorizationHeader(header) {
    let token = header || `Bearer ${store.state.authToken}`;
    this.setHeader(AUTHORIZATION_HEADER_KEY, token);
  }

  setRemoveHeaders() {
    this.client.defaults.common = {};
    this.client.defaults.headers.common = {};
  }

  get(resource, config = {}) {
    return this.client.get(resource, config);
  }

  post(resource, data, config = {}) {
    return this.client.post(resource, data, config);
  }

  put(resource, data, config = {}) {
    return this.client.put(resource, data, config);
  }

  patch(resource, data, config = {}) {
    return this.client.patch(resource, data, config);
  }

  delete(resource, config = {}) {
    return this.client.delete(resource, config);
  }

  async customRequest(config) {
    return this.client(config);
  }

  async login(data) {
    return this.client.post("login", null, {
      params: {
        username: data.username,
        password: data.password,
        captcha: data.captcha
      }
    });
  }

  async logout() {
    return this.client.post("api/v1/account/logout");
  }

  async refreshToken() {
    return this.client.post("/api/v1/account/refresh-token", null, {
      headers: {
        "refresh-token": store.state.auth.refreshToken
      }
    });
  }

  mountRequestInterceptor() {
    this.client.interceptors.request.use(
      function (config) {
        const urls = [
          "/login",
          "/api/v1/account/logout",
          "/api/v1/account/send-password-reset-token",
          "/api/v1/account/new-password",
          "api/v1/account/refresh-token",
          "oauth/sso-credentials",
          "oauth/token/microsoft",
          "oauth/token/amazon",
          "oauth/token/google"
        ];
        if (!urls.includes(config.url)) {
          config.headers.Authorization = `Bearer ${store.state.auth.accessToken}`;
        } else {
          delete config.headers.Authorization;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  mount401Interceptor() {
    let pointer = this;
    this._401interceptor = this.client.interceptors.response.use(
      function (response) {
        httpEventBus.httpResponse(response);
        return response;
      },
      function (error) {
        if (!error.response) {
          helpers.showNotification("network_error", "error");
          return false;
        }
        httpEventBus.httpResponse(error.response);
        const errorResponse = error.response;
        const urls = ["/login", "/api/v1/account/logout", "/api/v1/account/refresh-token"];
        if (error.response.config && !urls.includes(error.response.config.url)) {
          if (pointer.isTokenExpired(errorResponse)) return pointer.resetTokenAndReattemptRequest(error);
        }
        return Promise.reject(error);
      }
    );
  }

  unmount401Interceptor() {
    this.client.interceptors.response.eject(this._401interceptor);
  }

  isTokenExpired(errorResponse) {
    return (errorResponse && errorResponse.request && errorResponse.request.status) === UNAUTHORIZED;
  }

  resetTokenAndReattemptRequest(error) {
    let pointer = this;
    try {
      const { response: errorResponse } = error;
      const refreshToken = store.state.auth.refreshToken;
      if (!refreshToken) return Promise.reject(error);
      const retryOriginalRequest = new Promise((resolve) => {
        pointer.addSubscriber((access_token) => {
          errorResponse.config.headers.Authorization = "Bearer " + access_token;
          resolve(pointer.customRequest(errorResponse.config));
        });
      });
      if (!this.isAlreadyFetchingAccessToken) {
        this.isAlreadyFetchingAccessToken = true;
        store
          .dispatch("auth/REFRESH_TOKEN")
          .then((newToken) => {
            pointer.onAccessTokenFetched(newToken);
          })
          .catch(console.error)
          .finally(() => (this.isAlreadyFetchingAccessToken = false));
      }
      return retryOriginalRequest;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  onAccessTokenFetched(access_token) {
    // retrying the requests one by one and empty the queue
    this.subscribers.forEach((callback) => callback(access_token));
    this.subscribers = [];
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }
}
