let endpoints = {
  channel_type: "channels-type",
  channels: "channels",
  skills: "skills",
  position_level: "job-position-level",
  job_posting: "job-posting",
  job_posting_channels: "job-posting-channels",
  applicants: "job-applicants",
  assessment: "job-assessments",
  assessment_detail: "job-assessments-detail",
  black_list: "black-list",
  interview: "job-interviews",
  stages: "applicants",
  boarding: "job-boarding",
  blacklist_manager: "black-list-manager",
  applicant_evaluator: "applicant-evaluator"
};

export default endpoints;
