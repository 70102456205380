import RestService from "./restService";
import { TRAINING_REQUESTS } from "../../url";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class TrainingRequestService extends RestService {
  constructor(enforcer) {
    super(TRAINING_REQUESTS);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct TrainingRequest RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new TrainingRequestService(singletonEnforcer);
    }
    return this[singleton];
  }

  approve(id, payload) {
    if (id && payload && typeof payload === "object") {
      return this._api_connector.put(this._service_api.uri("processes").uri(id).url, payload);
    } else {
      throw new Error("Update API call requires ID or Payload!");
    }
  }

  multipleApproval(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.put(this._service_api.uri("processes/multiple").url, payload);
    } else throw new Error("Save API call requires Payload!");
  }

  deny(id, note = null) {
    return this._api_connector.put(this._service_api.uri("processes").uri(id).url, {
      approved: false,
      note: note
    });
  }

  cancel(id, payload) {
    return this._api_connector.put(this._service_api.uri(id).uri("cancel").url, payload);
  }

  trainingPlanned(id) {
    if (id) {
      return this._api_connector.put(this._service_api.uri(id).uri("training-planned").url);
    } else {
      throw new Error("Update API call requires ID");
    }
  }
}

export default TrainingRequestService;
