import RestService from "./restService";
import { SKILL } from "../../url/hiring";
const singleton = Symbol();
const singletonEnforcer = Symbol();
/**
 *
 */
class SkillService extends RestService {
  constructor(enforcer) {
    super(SKILL);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Agency RestService more than one");
  }
  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new SkillService(singletonEnforcer);
    }
    return this[singleton];
  }
}

export default SkillService;
