import services from "./resources/services";
import endpoints from "./resources/endpoints";
import URL from "./generators/rest_url";

/**
 *
 * @type {RestUrl}
 */
export const TEAM = new URL(services.team_service, endpoints.teams);
/**
 *
 * @type {RestUrl}
 */
export const JOB_INFO = new URL(services.job_info_service, endpoints.job_infos);
/**
 *
 * @type {RestUrl}
 */
export const BREAK = new URL(services.break_service, endpoints.break_times);
/**
 *
 * @type {RestUrl}
 */
export const UNIT = new URL(services.unit_service, endpoints.units);
/**
 *
 * @type {RestUrl}
 */
export const SHIFT = new URL(services.shift_service, endpoints.shifts);
/**
 *
 * @type {RestUrl}
 */
export const BRANCH = new URL(services.branch_service, endpoints.branches);
/**
 *
 * @type {RestUrl}
 */
export const GEOGRAPHIC = new URL(services.geographic_service, endpoints.countries);
/**
 *
 * @type {{terminal: Url, brand: Url, model: Url, branch: Url}}
 */
export const TERMINAL_SERVICES = {
  terminal: new URL(services.terminal_service, endpoints.terminals),
  brand: new URL(services.terminal_service, endpoints.brands),
  model: new URL(services.terminal_service, endpoints.models),
  branch: new URL(services.terminal_service, endpoints.branches)
};
/**
 *
 * @type {{employee: Url, units: Url, branches: Url, jobInfos: Url}}
 */
export const EMPLOYEE_SERVICES = {
  employee: new URL(services.employee_service, endpoints.employees),
  units: new URL(services.employee_service, endpoints.units),
  branches: new URL(services.employee_service, endpoints.branches),
  jobInfos: new URL(services.employee_service, endpoints.job_infos),
  identities: new URL(services.employee_service, endpoints.identities)
};
/**
 *
 * @type {RestUrl}
 */
export const HOLIDAY = new URL(services.holiday_service, endpoints.holidays);
/**
 *
 * @type {RestUrl}
 */
export const LEAVE_TYPE = new URL(services.leave_type_service, endpoints.leave_types);

/**
 *
 * @type {RestUrl}
 */
export const LEAVE_POLICY = new URL(services.leave_type_service, endpoints.leave_policies);

export const LEAVE_REQUEST = new URL(services.leave_request_service, endpoints.leave_requests);

export const LEAVE_GROUPS = new URL(services.leave_groups_service, endpoints.leave_groups_service);

export const ROLE = new URL(services.role_service, endpoints.roles);

/**
 *
 * @type {RestUrl}
 */
export const STORAGE = new URL(services.storage_service, endpoints.storage);

export const LEAVE_DOCUMENT = new URL(services.leave_document_service, endpoints.leave_documents);

export const REGISTRATION = new URL(services.registration_service, endpoints.registration);
/**
 *
 * @type {RestUrl}
 */
export const TRAINING_SERVICES = {
  trainingCategory: new URL(services.training_service, endpoints.training_category),
  trainingEvaluationType: new URL(services.training_service, endpoints.training_evaluation_type),
  trainingLecturer: new URL(services.training_service, endpoints.training_lecturer),
  training: new URL(services.training_service, endpoints.training),
  trainingPlan: new URL(services.training_service, endpoints.training_plan)
};
/**
 *
 * @type {RestUrl}
 */
export const PERFORMANCE_SERVICES = {
  performanceTemplate: new URL(services.performance_service, endpoints.performance_template),
  performanceGroups: new URL(services.performance_service, endpoints.performance_groups),
  performanceCategory: new URL(services.performance_service, endpoints.performance_category),
  performanceItems: new URL(services.performance_service, endpoints.performance_items),
  performanceScoreSettings: new URL(services.performance_service, endpoints.performance_score_settings),
  performanceEvaluation: new URL(services.performance_service, endpoints.performance_evaluation)
};
/**
 *
 * @type {RestUrl}
 */
export const BOARDING_SERVICES = {
  boardItem: new URL(services.boarding_item_service, endpoints.boarding_item),
  boardCategory: new URL(services.boarding_category_service, endpoints.boarding_category),
  boardTemplate: new URL(services.boarding_template_service, endpoints.boarding_template)
};
/**
 *
 * @type {RestUrl}
 */
export const ASSET_SERVICES = {
  asset: new URL(services.asset_service, endpoints.assets),
  assetCategories: new URL(services.asset_service, endpoints.asset_categories),
  assetBrands: new URL(services.asset_service, endpoints.asset_brands),
  assetModels: new URL(services.asset_service, endpoints.asset_models)
};
/**
 *
 * @type {RestUrl}
 */
export const DEBIT_TICKET = new URL(services.debit_ticket_service, endpoints.debit_tickets);
/**
 *
 * @type {RestUrl}
 */
export const DOCUMENT = {
  documents: new URL(services.document_service, endpoints.documents),
  documentTypes: new URL(services.document_service, endpoints.document_types)
};
/**
 *
 * @type {RestUrl}
 */
export const PAPER = {
  paper: new URL(services.paper_service, endpoints.papers),
  paperTypes: new URL(services.paper_service, endpoints.paper_types),
  paperRequest: new URL(services.paper_service, endpoints.paper_request),
  paperRequestProcess: new URL(services.paper_service, endpoints.paper_request_process)
};
/**
 *
 * @type {RestUrl}
 */
export const EXPENSE = {
  expenses: new URL(services.expense_service, endpoints.expenses),
  expenseTypes: new URL(services.expense_service, endpoints.expense_types)
};
/**
 *
 * @type {RestUrl}
 */
export const COST_SERVICES = {
  costs: new URL(services.cost_service, endpoints.costs),
  costCategories: new URL(services.cost_service, endpoints.cost_categories),
  costTypes: new URL(services.cost_service, endpoints.cost_types)
};
/**
 *
 * @type {RestUrl}
 */
export const HEALTH_SERVICES = {
  diseases: new URL(services.health_service, endpoints.diseases),
  diseaseCategories: new URL(services.health_service, endpoints.disease_categories),
  medicines: new URL(services.health_service, endpoints.medicines),
  surgeryOperations: new URL(services.health_service, endpoints.surgery_operations)
};
/**
 *
 * @type {RestUrl}
 */
export const MESSAGE = new URL(services.message_service, endpoints.messages);
/**
 *
 * @type {RestUrl}
 */
export const NOTE = new URL(services.note_service, endpoints.notes);
/**
 *
 * @type {RestUrl}
 */
export const VEHICLE_SERVICES = {
  vehicle: new URL(services.vehicle_service, endpoints.vehicles),
  vehicleBrands: new URL(services.vehicle_service, endpoints.vehicle_brands),
  vehicleModels: new URL(services.asset_service, endpoints.vehicle_models)
};
/**
 *
 * @type {RestUrl}
 */
export const AGENCY = new URL(services.agency_service, endpoints.agencies);
/**
 *
 * @type {RestUrl}
 */
export const FIXED_TERMINAL_SERVICES = {
  fixedTerminal: new URL(services.fixed_terminal_service, endpoints.fixed_terminals)
};

/**
 *  * @type {{changePassword: RestUrl}}
 */
export const ACCOUNT = {
  account: new URL(services.account_service, endpoints.account),
  changePassword: new URL(services.account_service, endpoints.change_password),
  sendPasswordResetToken: new URL(services.account_service, endpoints.send_password_reset_token)
};

export const WORKSHEET = {
  base: new URL(services.worksheet_service, endpoints.worksheets),
  clockRecords: new URL(services.worksheet_service, endpoints.clock_records)
};

export const ACCESS_TERMINALS = {
  base: new URL(services.access_terminal_service, endpoints.access_terminals)
};

export const TIME_SHEETS = {
  base: new URL(services.time_sheet_service, endpoints.time_sheets)
};

export const ORGANIZATION = new URL(services.organization_service, endpoints.organization);
export const REPORT = new URL(services.report_service, endpoints.report_service);
export const SHIFTS = new URL(services.shifts_service, endpoints.shifts_service);
export const SHIFT_GROUPS = new URL(services.shift_groups_service, endpoints.shift_groups_service);
export const WORKPLAN_SERVICES = new URL(services.workplan_service, endpoints.workplan_service);
export const TERMINAL_EVENTS_SERVICES = new URL(services.terminal_events_service, endpoints.terminal_events_service);
export const CLOCK_RECORDS = new URL(services.clock_records_service, endpoints.clock_records_service);
export const SERVICE_LINES = new URL(services.service_lines_service, endpoints.service_lines);
export const SHIFT_PARAMETER = new URL(
  services.service_organization_shift_parameter,
  endpoints.service_organization_shift_parameter
);
export const PAYROLL = new URL(services.service_payroll, endpoints.service_payroll);
export const SUBSISTENCE = new URL(services.service_subsistence, endpoints.service_subsistence);
export const IMPREST = new URL(services.service_imprest, endpoints.service_imprest);
export const LOCATION = new URL(services.service_location, endpoints.service_location);
export const COMMUNICATION_MANAGER = new URL(services.communication_manager, endpoints.communication_manager);
export const CHAT_GROUP = new URL(services.chat_groups_service, endpoints.chat_groups);
export const ACTIVITY_TYPE = new URL(services.activity_type, endpoints.activity_type);
export const ACTIVITY = new URL(services.activity, endpoints.activity);
export const OVERTIME = new URL(services.overtimes, endpoints.overtimes);

export const AUTHENTICATION = {
  login: new URL(services.authentication_service, endpoints.authentication.login, null),
  changePassword: new URL(services.authentication_service, endpoints.authentication.change_password),
  newChangePassword: new URL(services.authentication_service, endpoints.new_change_password),
  sendPasswordResetToken: new URL(services.authentication_service, endpoints.authentication.send_password_reset_token),
  resetPassword: new URL(services.authentication_service, endpoints.authentication.reset_password),
  logout: new URL(services.authentication_service, endpoints.authentication.logout),
  refreshToken: new URL(services.authentication_service, endpoints.authentication.refresh_token)
};

export const SUGGESTIONS = new URL(services.suggestions, endpoints.suggestions);
export const NOTES = new URL(services.notes, endpoints.notes);
export const FEEDBACK_RESULT = new URL(services.feedback_result, endpoints.feedback_result);
export const TRAVEL = new URL(services.travel, endpoints.travel);
export const TRAINING_REQUESTS = new URL(services.training_requests, endpoints.training_requests);

export const SURVEY = {
  BASE: new URL(services.survey, endpoints.survey.base),
  ANSWER: new URL(services.survey, endpoints.survey.answer)
};

export const WORKPLAN_REQUESTS = new URL(services.workplan_requests, endpoints.workplan_requests);
