import RestService from "./restService";
import { DOCUMENT } from "../../url/index";

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 *
 */
class DocumentService extends RestService {
  constructor(enforcer) {
    super(DOCUMENT.documents);
    if (enforcer !== singletonEnforcer) throw new Error("Cannot construct Document RestService more than one");
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new DocumentService(singletonEnforcer);
    }
    return this[singleton];
  }

  saveDocumentType(payload) {
    if (payload && typeof payload === "object") {
      return this._api_connector.post(DOCUMENT.documentTypes.url, payload);
    } else {
      throw new Error("Save API call requires Payload!");
    }
  }

  updateDocumentType(payload) {
    if (payload && typeof payload === "object" && payload.id) {
      return this._api_connector.put(DOCUMENT.documentTypes.uri(payload.id).url, payload);
    } else {
      throw new Error("Update API call requires ID and Payload!");
    }
  }

  removeDocumentType(id) {
    if (id) {
      return this._api_connector.delete(DOCUMENT.documentTypes.uri(id).url);
    } else {
      throw new Error("Delete API call requires ID parameter !");
    }
  }
}

export default DocumentService;
