export default {
  auth: {
    email: "E-Mail",
    password: "Passwort",
    logout: "Logout",
    sign_in: "Login",
    sign_in_with_sso: "Login with SSO",
    login_screen: "login screen",
    back_to_sign_in: "zurück zum Log-In",
    reset_password: "Passwort zurücksetzen",
    reset_password_code: "Passwort-Code zurücksetzen",
    forget_password: "Passwort vergessen",
    new_password: "Neues Passwort",
    re_new_password: "Neues Passwort",
    login_failed: "Identität nicht gefunden",
    change_password: "Passwort ändern",
    continue: "Weiter",
    showcase: {
      welcome: "Willkommen^3000",
      cloud: "Die neue Generation der Identifizierungs- und Managements-Cloudplattform^4000",
      identify:
        "identifizieren, tracken und managen Sie ihr Workforce^1000 auf benutzerfreundliche und sichere Art und Weise^4000",
      manage: "Sie können jederzeit und von überall aus managen^4000",
      freedom: "Sie haben die Freiheit^3000 jegliche Systeme jederzeit einzusehen^3000"
    },
    email_not_found: "This email address was not found",
    invalid_reset_token: "Invalid reset code",
    reset_password_expired: "You must wait at least 3 minutes to reset a new password",
    reset_code_expired: "Password reset code has expired",
    send_sms_password_code: "Password reset code was sent as SMS message",
    password_change_successfully: "Ihr Passwort wurde erfolgreich aktualisiert",
    invalid_email_or_password: "Email or password is invalid",
    invalid_password: "Password is invalid",
    current_password: "Dein Aktuelles Passwort",
    sso_error: "An error occured"
  },
  dashboard: {
    access: {
      table: {
        last_event: "vergangene Events",
        personal: "Arbeitnehmer",
        terminal_type: "Terminal Typ",
        general_info: "Allgemeine Informationen",
        terminal: "Terminal",
        event_type: "Event Typ",
        identity_type: "Identitätstyp",
        time: "Zeit",
        event: "Event",
        location: "Standort",
        place: "Ort",
        place_beacon: " Beacon platzieren",
        minor: "gering",
        major: "hoch",
        parent: "Überordnung",
        duration: "Dauer",
        first_seen: "zuerst gesehen",
        last_seen: "zuletzt gesehen",
        max_person_count: "maximale Personananzahl",
        begin_date_time: "Startzeit und -datum",
        end_date_time: "Endzeit und -datum",
        employee_dialog_title: "Enter – Exit Information Personal",
        empty_zone_message: "Leere Zone",
        loading_message: "wird geladen"
      },
      monthly_dashboard: "monatliches Dashboard",
      monthly_events: "monatliche Events",
      monthly_timesheet_report: "Mein monatlicher Fahrplanbericht",
      absence: "Meine Abwesenheiten",
      incalculable: "Mein Unberechenbares",
      lates: "Meine späte Ankunft",
      overtime: "Meine Überstunden",
      early_leaver: "Meine frühen Abreisen",
      missing_time: "Meine fehlenden Stunden",
      expected: "Mein Erwartetes",
      total_tardiness_average: "Total Verspätungsdurchschnitt",
      total_leaver_average: "Total Durchschnitt verfrühtes Schichtende",
      total_overtime_average: "Total Überstundendurchschnitt",
      tardiness_minute: "Verspätung in Minuten",
      early_minute: "verfrühter Schichtbeginn in Minuten",
      overtime_minute: "Überstunden in Minuten",
      short_minute: "min",
      today: "Heute"
    },
    currency: {
      buying: "zu kaufen",
      selling: "zu verkaufen",
      currencies: "Währung"
    },
    about: {
      about: "Über",
      mission: "Mission",
      vision: "Vision"
    },
    menu_of_day: "Tagesmenü",
    messages: {
      no_data_reminders: "Keine Daten - Erinnerungen",
      no_data_messages: "Keine Daten - Benachrichtigungen",
      no_data_leaves: "Keine Daten - Urlaubsanträge",
      no_data_overtimes: "Keine Daten - Überstundenanträge",
      no_data_expenses: "Keine Daten - Kostenabfragne",
      no_data_imprests: "Keine Daten - Zahlstellenanfragen",
      no_data_incalculables: "Keine Daten – unberechenbare Inhalte",
      no_data_absences: "Keine Daten - Abwesenheiten"
    },
    request_type: {
      leave: "Urlaubsanträge",
      overtime: "Überstundenanträge",
      expense: "Kostenabfragen",
      imprest: "Zahlstellenanfragen"
    },
    weather_forecast: "Wettervorhersage",
    reminder_tab_title: "Erinnerungen",
    message_tab_title: "Nachrichten",
    data_type_enum: {
      LEAVE_REQUEST: "Time Off Requests",
      ACTIVITY: "Activities",
      REMINDER: "Reminders"
    }
  },
  time: {
    crud: {
      create_overtime: "Überstundenantrag erstellen"
    },
    event: {
      clock_in: "Clock In",
      clock_out: "Clock Out",
      duty_in: "Dienst In",
      duty_out: "Dienst Out",
      break_in: "Pause In",
      break_out: "Pause Out",
      lunch_in: "Mittagspause In",
      lunch_out: "Mittagspause Out"
    },
    menu: {
      shifts: "Schichten",
      timesheets: "Timesheets",
      overtime_requests: "Überstundenanträge",
      overtime_approval: "Antrag auf Bestätigung der Überstunden",
      employee_calendar: "Employee Calendar"
    },
    shift_types: {
      FIX: "gesetzt",
      FLEXIBLE: "flexibel",
      OFF_DAY: "freier Tag",
      OFFDAY: "freier Tag",
      HOLIDAY: "Feiertag",
      WORKDAY: "Arbeitstag",
      BREAK: "Ruhetag",
      OVERTIME: "Overtime",
      OVERTIME_SHIFT: "Overtime"
    },
    overtime_approval: {
      employee: "Mitarbeiter",
      workplan: "Arbeitsplan",
      request_status: "Antragsstatus",
      reason: "Vernunft",
      request_time: "Antragsdatum"
    },
    overtime_request: {
      approve_title: "Bestätigung des Überstundenantrags",
      add_overtime: "Überstundenantrag hinzufügen",
      date: "Schichtdatum",
      workplan: "Schicht",
      overtime_in_minutes: "Dauer der Überstunden",
      short_minute: "min",
      request_status: "Status",
      request_date: "Antragsdatum",
      period: "Zeitraum",
      periods: {
        CLOCK_IN: "Clock In",
        CLOCK_OUT: "Clock Out",
        CLOCK_IN_OUT: "Clock In Out"
      },
      message: "{0} Möchten Sie die Überstundenanzahl von <b> {2} Minuten für die Schicht vom {1}  </b> bestätigen?"
    },
    timesheet: {
      employee: "Mitarbeiter",
      employees: "Mitarbeiter",
      date: "Datum",
      type: "Typ",
      work_plan: "Arbeitsplan",
      shift: "Schicht",
      clock_in: "Clock In",
      clock_in_title: "Clock In Zeit",
      late: "zu spät",
      reason: "Vernunft",
      late_title: "zu spät IN",
      it: "IT",
      it_title: "Identiätstyp",
      terminal: "Terminal",
      clock_out: "Clock Out",
      clock_out_title: "Clock Out Zeit",
      early: "zu früh",
      early_title: "zu früh OUT",
      ewt: "EAZ",
      ewt_title: "erwartete Arbeitszeit",
      awt: "TAZ",
      twt_title: "tatsächliche Arbeitszeit",
      twt: "TAZ",
      awt_title: "tatsächliche Arbeitszeit",
      ot: "ÜS",
      ot_title: "Überstunden",
      mt: "FZ",
      mt_title: "Fehlzeiten",
      name: "Mitarbeiter",
      time: "Zeit",
      identity_type: "Identitätstyp",
      event_type: "Event Typ",
      event_time: "Event Zeit",
      all_events: "Alle Events",
      status: "Status",
      shift_name: "Schicht Name",
      branch: "Bereich",
      unit: "Unit",
      team: "Team",
      jobInfo: "Job Info",
      staffNumber: "Personalnummer",
      information: {
        work_day: "Arbeitstag",
        work_day_desc: "Der Mitarbeiter wird an diesem Tag zum Dienst erscheinen.",
        work_off: "Ruhetag",
        work_off_desc: "Dieser Tag ist ein freier Tag es Mitarbeiters.",
        public_holiday: "gesetzlicher Feiertag",
        public_holiday_desc: "Dies ist ein gesetzlicher Feiertag.",
        did_not_come_to_work: "Nicht zur Arbeit erschienen",
        did_not_come_to_work_desc:
          "Trotz zugeteilter Schicht ist der Mitarbeiter an diesem Tag nicht zur Arbeit erschienen.",
        on_leave: "im Urlaub",
        on_leave_desc: "Der Mitarbeiter ist an diesem Tag im Urlaub.",
        calculated: "errechnet",
        calculated_desc: "Die grünmarkierten Bereiche sind erfolgreich berechnet worden.",
        not_calculated: "nicht errechnet",
        not_calculated_desc: "Die rotmarkierten Bereiche sind noch nicht berechnet worden."
      },
      total: "Total",
      ot_mt: "Difference"
    },
    calendar: {
      holiday: "Holiday",
      off_day: "Off Day",
      breakday: "Break Day",
      employee_overtime: "If the employee comes to work on this date, it is calculated as overtime",
      employee_off_day: "It is the employee's weekly holiday",
      employee_break_day: "It is the employee's rest day.",
      shift_loading: "Idenfit creating shifts",
      create_shift: "Create Shift",
      shift_detail: "Shift Detail",
      leave_type: "Time Off Type",
      begin_time: "Begin Time",
      end_time: "End Time",
      break_time: "Break Time",
      shift_time: "Shift Time",
      clock_in_max_overtime: "Clock In Max Overtime",
      clock_out_max_overtime: "Clock Out Max Overtime",
      lately_tolerance: "Late Tolerance",
      early_tolerance: "Early Time Off Tolerance",
      overtime: "Overtime",
      early_clock_out_tolerance: "Late Tolerance",
      lately_clock_in_tolerance: "Early Time Off Tolerance",
      max_clock_in_overtime_in_minutes: "Max Clock In Overtime In Minutes",
      max_clock_out_overtime_in_minutes: "Max Clock Out Overtime In Minutes",
      min_clock_in_overtime_in_minutes: "Min Clock In Overtime In Minutes",
      min_clock_out_overtime_in_minutes: "Min Clock Out Overtime In Minutes",
      minimum_overtime: "Minimum overtime",
      maximum_overtime: "Maximum overtime",
      midnight_tolerance: "Midnight Tolerance",
      shift_type: "Shift Type",
      count_as_overtime: "Count as overtime",
      total_work_time: "Total Working Time",
      selectWeekends: "Weekend Days",
      selectBreaks: "Break Days",
      overtime_request: {
        approve_title: "Approving Overtime",
        add_overtime: "Request Overtime ",
        detail_overtime: "Overtime Detail",
        date: "Shift Date",
        workplan: "Shift",
        overtime_in_minutes: "Shift Time",
        request_status: "Status",
        request_date: "Request Date",
        approval_date: "Approving Date",
        period: "Period",
        history: "Approving History",
        periods: {
          CLOCK_IN: "Clock In",
          CLOCK_OUT: "Clock Out",
          CLOCK_IN_OUT: "Clock In & Out"
        },
        approved:
          "{0} <b> {1} tarihinideki vardiyası için {2} dk </b> fazla mesai talebini onaylamak istiyor musunuz ?",
        rejected:
          "{0} <b> {1} tarihinideki vardiyası için {2} dk </b> fazla mesai talebini reddetmek istiyor musunuz ?",
        approval_to_manager: "Fazla Mesai Yöneticisine onay gitsin",
        not_approval_to_manager: "Fazla Mesai Yöneticisine onay gitmesin",
        pending_approval_manager: "Onayı Beklenen Yönetici",
        processes: {
          approved: "Approved the request",
          pending: "Pending approval",
          stopped: "Approving process stopped since the request cancelled",
          waiting: "Waiting for previous manager to approve",
          denied: "Request cancelled"
        }
      },
      multiple_shift_deleting: "Multiple Shift Deleting",
      cancel_request: "Cancel Request",
      cancel_request_title: "Cancel Shift Request"
    }
  },
  leave: {
    crud: {
      create: "Urlaubsantrag hinzufügen"
    },
    menu: {
      leave_accruals: "Urlaubsrücklagen",
      leave_requests: "Urlaubsanträge",
      leave_approvals: "ausstehende Urlaubsansträge"
    },
    hourly: "stündlich",
    daily: "täglich",
    type: "Art des Urlaubs",
    begin_time: "Startzeit",
    end_time: "Endzeit",
    begin_date: "Startdatum",
    end_date: "Enddatum",
    approved_time: "Bestätigungszeit",
    request_time: "Antragszeit",
    approve_or_reject: "Genehmigen oder Ablehnen",
    status: "Status",
    no_approver: "nicht bestätigt",
    manager: "Urlaubsmanager:In",
    managers: "Urlaubsmanager:In",
    balance: "Bilanz",
    not_leave_accruals: "Dieser Mitarbeiter kann noch keinen Urlaub beantragen",
    permission: {
      employee_leave_approver_message: "Dieser Mitarbeiter kann Anträge anderer Mitarbeiter bestätigen",
      employee_not_leave_approver_message: "Dieser Mitarbeiter kann Anträge anderer Mitarbeiter nicht bestätigen",
      employee_shift_approver_message: "Dieser Mitarbeiter kann anderen Mitarbeitern Arbeitsschichten zuteilen",
      employee_not_shift_approver_message:
        "Dieser Mitarbeiter kann anderen Mitarbeitern keine Arbeitsschichten zuteilen",
      employee_overtime_approver_message: "Dieser Mitarbeiter kann Überstundenanträge anderer Mitarbeiter bestätigen",
      employee_not_overtime_approver_message:
        "Dieser Mitarbeiter kann Überstundenanträge anderer Mitarbeiter nicht bestätigen"
    },
    notification: {
      employee_receiver_leave_notification_message:
        "Diese Mitarbeiter können Bestätigungen von anderen Mitarbeitern erhalten",
      employee_not_receiver_leave_notification_message:
        "Diese Mitarbeiter können keine Bestätigungen von anderen Mitarbeitern erhalten",
      employee_receiver_shift_notification_message:
        "Diese Mitarbeiter können Schichtzuteilungsbenachrichtungen von anderen Mitarbeitern erhalten.",
      employee_not_receiver_shift_notification_message:
        "Diese Mitarbeiter können keine Schichtzuteilungsbenachrichtigungen von anderen Mitarbeitenr erhalten",
      employee_receiver_overtime_notification_message:
        "Diese Mitarbeiter können Benachrichtigungen zu Überstundenanträgen von anderen Mitarbeitern erhalten",
      employee_not_receiver_overtime_notification_message:
        "Diese Mitarbeiter können keine Benachrichtigungen zu Überstundenanträgen von anderen Mitarbeitern erhalten",
      employee_leave_notification_enabled: "This employee can receive time off notifications",
      employee_leave_notification_disabled: "This employee cannot receive time off notifications"
    },
    respect: "Die Bestätigungen der oben aufgelisteten Manager:Innen werden prioritiert behandelt",
    order_is_important: "Die Reihenfolge der Manager:Innen ist von Belang",
    already_selected_manager: "Sie haben diesen Manager bereits gewählt",
    there_is_no_leave_manager: "Keine zuständigen Urlaubsmanager verfügbar",
    add_leave_manager: "Urlaubsmanager hinzufügen",
    used: "verbraucht",
    remaining: "noch verfügbar",
    on_leave: "im Urlaub",
    period: "Periode",
    duration: "Dauer",
    periods: {
      first_half: "1.Hälfte",
      second_half: "2.Hälfte",
      first_quarter: "1.Quartal",
      second_quarter: "2.Quartal",
      third_quarter: "3.Quartal",
      fourth_quarter: "4.Quartal"
    },
    request: {
      this_day_does_not_count_as_leave: "Dieser Tag wird nicht als Urlaub gezählt",
      leave_balance: "Urlaubsbilanz ",
      max_exceed_leave_balance: "maximale Urlaubsbilanz überschritten",
      leave_request_count: "Anzahl Urlaubsanträge",
      used: "verbraucht",
      remaining: "verfügbar",
      approved: "bestätigen",
      denied: "ablehnen",
      leave_decreased_count: "Time Off will decrease"
    },
    accruals: {
      name: "Name",
      count: "Gesamt abschlagszahlung",
      used: "verbraucht",
      balance: "real Bilanz",
      previous_balance: "bisherige Bilanz"
    },
    approvals: {
      leave_approval: "Urlaubsbestätigung",
      message:
        "Der Urlaubssaldo von <b>{0}</b> vom Typ <b>{1}</b> beträgt <b>{2}</b> Tage. Möchten Sie insgesamt <b>{5}</b> Urlaubsanträge genehmigen, die zwischen <b>{3}</b> - <b>{4}</b> gestellt wurden?"
    },
    cancels: {
      leave_cancel: "Urlaub widerrufen",
      message: "Ihr Grund für den Widerruf der Genehmigung?",
      error_message: "Ihr Kündigungsgrund? Platz wird benötigt"
    },
    error_messages: {
      notice_period_error: "Dieser Urlaub muss mindestens {0} Tage im Voraus beantragt werden",
      max_consecutive_error: "Sie können bis zu {0} Urlaubstage auf einmal beantragen",
      min_consecutive_error: "Sie können {0} Urlaubstage auf einmal beantragen",
      leave_request_exist_error: "Es liegt ein Urlaubsantrag für diesen Zeitraum vor",
      total_leave_balance_error1: "Ihre Restbilanz ist nicht ausreichend für diesen Urlaubszeitraum",
      total_leave_balance_error2:
        "Ihre Restbilanz ist nicht ausreichend für diesen Urlaubszeitraum. Sie können ein Maximum von {0} restlichen Urlaubstagen aus Ihrer Bilanz entnehmen."
    },
    e_signature: "E-signature",
    e_signature_leave_form: "E-signed Time Off Form",
    leave_form: "Time Off Form",
    leave_cancel: "Erlaubnis widerrufen",
    signed: "Signed",
    not_signed: "Not Signed",
    requests: {
      days_off_automatically: "Automatisch hinzugefügte Tage",
      requested_leave_type_documents: "Files need to be documents for time off"
    },
    today_counts_as_a_day_off: "Heute gilt als freier Tag",
    required_document_message:
      " | There is {count} document you need to upload | There are {count} documents you need to upload",
    isNexyDayMessage: "Loslassen am nächsten Tag starten"
  },
  leave_request_status: {
    pending: "ausstehend",
    PENDING: "ausstehend",
    approved: "bestätigt",
    APPROVED: "bestätigt",
    denied: "abgelehnt",
    DENIED: "abgelehnt",
    cancelled: "abgebrochen",
    CANCELLED: "abgebrochen",
    need_extra_detail: "Es werden weitere Informationen benötigt",
    NEED_EXTRA_DETAIL: "Es werden weitere Informationen benötigt",
    STOPPED: "Stopped",
    stopped: "Stopped",
    not_come: "Not Come",
    NOT_COME: "Not Come",
    null: "Unknown",
    NULL: "Unknown",
    undefined: "Unknown",
    UNDEFINED: "Unknown",
    closed: "Closed",
    CLOSED: "Closed",
    close_pending: "Pending Close",
    CLOSE_PENDING: "Pending Close",
    NOT_COME: "Not Come",
    not_come: "Not Come",
    super_admin: "Genehmigung durch den Administrator",
    SUPER_ADMIN: "Genehmigung durch den Administrator"
  },
  time_duration: {
    hour: "Stunde",
    minute: "Minute",
    second: "Zweite"
  },
  leave_type: {
    paid: "bezahlt",
    unpaid: "unbezahlt",
    on_duty: "im Dienst",
    restricted_holiday: "eingeschränkter Feiertag"
  },
  leave_status: {
    APPROVED: "bestätigt",
    PENDING: "ausstehend",
    DENIED: "abgelehnt",
    CANCELLED: "abgebrochen",
    NEED_EXTRA_DETAIL: "Es werden zusätzliche Details benötigt",
    not_come: "Keine Zeiten verfügbar",
    NOT_COME: "No time has come"
  },
  leave_duration: {
    FULL_DAY: "Voller Arbeitstag",
    HALF_DAY: "halber Arbeitstag",
    QUARTER_DAY: "Ein-Viertel Arbeitstag",
    HOURLY_DAY: "Arbeitstag nach Stunden",
    HOURLY: "stündlich"
  },
  hr: {
    menu: {
      profile: "Profil",
      performances: "Performance",
      hiring: "Einstellung",
      trainings: "Trainings",
      expenses: "Ausgaben",
      imprests: "Zahlstellen",
      papers: "Papiere",
      paper: "Papier",
      documents: "Dokumente",
      health: "Gesundheit",
      debit_tickets: "Debit Tickets",
      payroll: "Gehaltsabrechnungen",
      events: "Events",
      approvals: "Approval Requests",
      expense_approval: "Zahlungsanträge",
      imprest_approval: "Zahlstellenanträge",
      messages: "Nachrichten",
      inbox: "Posteingang",
      outbox: "Postausgang",
      suggestions: "Vorschläge",
      notes: "Notizen",
      travels: "Reisen",
      travel_requests: "Reiseantrag",
      vehicles: "Fahrzeuge",
      menus: "Essensmenü",
      contact: "Kontakt",
      expense: "Ausgaben",
      expense_request_history: "Zahlungsanträge Verlauf",
      imprest: "Zahlstellen",
      imprest_request_history: "Zahlstellenanträge Verlauf",
      travel: "Reisen",
      travel_request_history: "Reiseanträge Verlauf",
      debit_ticket_transfer_requests: "Debit Ticket Transfer Requests",
      training: "Training",
      training_requests: "Training Requests",
      training_request_history: "Training Request History",
      survey: "Survey",
      pending_surveys: "Pending Surveys",
      completed_surveys: "Completed Surveys",
      expired_surveys: "Expired Surveys",
      paper_request_history: "Papieranforderungsverlauf",
      paper_requests: "Papieranforderung"
    },
    account: {
      overview: "Übersicht",
      email: "E-Mail",
      information: "Informationen",
      profile: "Profil",
      language_role: "Language & Role",
      contact: "Kontakt",
      personal: "persönlich",
      family: "Familie",
      education: "Bildungsweg",
      employment: "Beschäftigung",
      financial: "finanziell",
      social_media: "Social Media",
      body_size: "Body Size",
      mobile_device: "Mobile Devices",
      special_fields: "besondere Tätigkeiten",
      messages: "Nachrichten",
      notes: "Notizen",
      management: "Management"
    },
    employee: {
      overview: {
        staff_number: "Personalnummer",
        branch: "Bereich",
        unit: "Unit",
        team: "Team",
        email: "E-Mail",
        job_info: "Jobinformation",
        leave: {
          manager: "Manager - Urlaub",
          managers: "Manager - Urlaub",
          approver: "Genehmigung - Urlaub"
        },
        hired_date: "Datum Beschäftigungsbeginn",
        status: "Status",
        manager: "Manager",
        shift_manager: "Shift Manager",
        shift_managers: "Shift Managers",
        role: "Rolle"
      },
      information: {
        identity_number: "Identitätsnummer",
        first_name: "Vorname",
        last_name: "Nachname",
        work_email: "Geschäftsemail",
        phone: "Telefon",
        email_and_sms_send_question: "E-Mail und SMS-Benachrichtigungen erhalten?"
      },
      profile: {
        identity_number: "Identitätsnummer",
        first_name: "Vorname",
        last_name: "Nachname",
        work_email: "Geschäftsemail",
        phone: "Telefon",
        email_and_sms_send_question: "E-Mail und SMS-Benachrichtigungen erhalten?",
        service_line: "Service Line"
      },
      language_role: {
        language: "Sprache",
        role: "Rolle"
      },
      contact: {
        email: "E-Mail",
        address: "Adresse",
        secondary_phone: "Zweittelefon",
        fixed_phone: "festes Telefon",
        work_phone: "Arbeitstelefon",
        spouse_phone: "Telefon des Partners",
        extension_number: "Durchwahlnummer",
        emergency_contact_name: "Notfallkontakt Name",
        emergency_contact_phone: "Notfallkontakt Telefon"
      },
      personal: {
        pasaport_number: "Reisepassnummer",
        birth_date: "Geburtsdatum",
        place_of_birth: "Geburtsort",
        nationality: "Nationalität",
        blood_type: "Blutgruppe",
        gender: "Geschlecht",
        disability_level: "Behinderungsgrad",
        ex_convict: "ehemaliger Häftling",
        driver: "Fahrer",
        military_service: "Militärdienst",
        suspended_until: "suspendiert bis",
        hes_code: "HES Code",
        hes_expired_date: "HES Code Expired Date"
      },
      family: {
        marital_status: "Beziehungsstatus",
        spouse_name: "Name des Ehepartners",
        child_count: "Anzahl Kinder",
        tenant: "Mieter",
        martyrs_relatives: "Märtyrer-Verwandte",
        disability_child_count: "Anzahl Kinder mit Behinderung",
        date_of_marriage: "Date of Marriage",
        father_name: "Father Name",
        mother_name: "Mother Name",
        mothers_maiden_name: "Mother's Maiden Name",
        sibling_count: "Sibling Count",
        child_info: "Child Info",
        add_child_info: "Add Child Info"
      },
      education: {
        graduation: "Abschluss",
        school: "Schule",
        faculty: "Fakultät",
        department: "Abteilung",
        graduation_grade: "Abschlussnote",
        academic_title: "akademischer Titel",
        languages: "Sprachen",
        license_file: "License File"
      },
      employment: {
        staff_number: "Personalnummer",
        branch: "Bereich",
        unit: "Unit",
        team: "Team",
        job_info: "Jobinformation",
        manager: "Manager",
        agency: "Agentur",
        ssn: "SSN",
        passive: "passiv",
        hired_date: "Startdatum der Beschäftigung",
        started_date: "Startdatum der Beschäftigung",
        employment_type: "Art der bBschäftigung",
        collar_type: "Collar Type",
        quite_job: "Kündigung",
        quit_job_reason: "Kündigungsgrund",
        quit_job_reason_description: "Beschreibung",
        description: "Beschreibung",
        quit_job_date: "Datum der Kündigung",
        have_an_interview_made: "Interview ja",
        have_an_interview_made_not: "Interview nein",
        research_and_development_person: "R&D Employee",
        social_security_type: "Social Security Type",
        trial_time: "Trial period",
        trial_time_hint: "* Trial period notifications are added on the basis of the recruitment date.",
        trial_time_title: "Trial period information",
        trial_time_item: "Information will be given on <strong>{0}</strong>",
        license_file: "License File",
        integration_value: "Integration Number",
        company: "Company",
        allowance_date: "Allowance Started Date"
      },
      financial: {
        iban: "IBAN",
        ticket_number: "Ticketnummer",
        bank_account_number: "Bankleitzahl",
        bank_branch: "Bank Bereich",
        salary: "Gehalt",
        ticket_price: "Ticketpreis",
        previous_salary: "Previous Salary",
        previous_ticket_price: "Previous Ticket Price",
        yearly_premium: "Yearly Premium",
        monthly_stoppage: "Monthly Stoppage",
        health_insurance_person_count: "Health Insurance Person Count",
        agi: "Minimum Living Allowance",
        gross_salary: "Gross Salary",
        pay_to_net: "Net Salary",
        pay_to_gross: "Gross Salary",
        salary_type: "Salary Payment Type"
      },
      social_media: {
        facebook: "Facebook",
        twitter: "Twitter",
        instagram: "Instagram",
        linkedin: "Linkedin",
        skype: "Skype"
      },
      body_size: {
        weight: "Gewicht",
        height: "Größe",
        shirt_size: "Hemdgröße",
        pants_size: "Hosengröße",
        shoe_size: "Schuhgröße",
        waistcoat: "Waistcoat",
        coat: "Coat"
      },
      management: {
        manager: "Manager",
        leave_managers: "Manager - Urlaube",
        shift_managers: "Manager - Dienstpläne",
        overtime_managers: "Manager - Überstunden",
        expense_managers: "Manager – Ausgaben"
      },
      mobile_device: {
        serial_number: "Serial Number",
        multiple_device_allowed: "Multiple Device Allowed"
      }
    },
    performance: {
      results: {
        evaluatee: "Gutachter:In",
        published_evaluators: "veröffentlichte Gutachter:Innen",
        unpublished_evaluators: "nicht veröffentlichte Gutachter:Innen",
        kpi_score: "Kpi Score",
        competence_score: "Kompetenz-Score",
        totalCount: "Total Teilnehmeranzahl",
        publishedCount: "active Teilnehmer",
        evaluation_name: "Name des Gutachtens",
        total_score: "Total Score",
        status: "Status",
        period: "Zeit",
        scoring: "Scoring",
        beginDate: "Startdatum",
        endDate: "Enddatum"
      },
      table: {
        name: "Name",
        type: "Performance Typ",
        score_range: "Score Reichweite",
        evaluatee: "Gutachter:In",
        expiration_period: "Auslaufdatum",
        validation_period: "Gültigkeitsdatum",
        status: "Bewertungsstatus",
        categories: "Kategorien"
      },
      depths: {
        depth_1: "1. Level",
        depth_2: "2. Level",
        depth_3: "3. Level",
        depth_4: "4. Level",
        depth_5: "5. Level"
      },
      add: {
        kpi: "KPI hinzufügen",
        competence: "Kompetenz hinzufügen",
        pool: "Aus dem Pool wählen",
        custom: "Neu hinzufügen",
        kpi_group: "KPI-Gruppe hinzufügen",
        competence_group: "Kompetenz-Gruppe hinzufügen",
        evaluation: "Add Evaluation Plan"
      },
      detail: {
        kpi_group: "KPI-Gruppe Details",
        competence_group: "Kompetenz-Gruppe Details"
      },
      crud: {
        detail: {
          template: "Template Details",
          evaluation: "Bewertungsplan Details"
        }
      },
      type: {
        KPI: "KPI",
        COMPETENCE: "Kompetenz"
      },
      validation: {
        add_item: "Der Kategorie {0} müssen Fragen hinzugefügt werden",
        template_exist_category: "Kategorie {0} wurde hinzugefügt",
        template_must_a_category: "Es muss mindestens eine Kategorie im Performance Template vorhanden sein",
        question_total_weight_required: "Die Gesamtgewichtung der Fragen muss bei {0}% liegen",
        question_total_weight: "Gesamtgewichtung der Fragen: {0}%",
        category_not_exist: "Diese Kategorie existiert nicht",
        category_total_weight_equals_in_questions:
          "Da die Gewichtung der Kategorie {0} {1}% der Gesamtgewichtung der Fragen beträgt, muss diese Kategorie {1}% entsprechen",
        category_in_question_required: "Kategorie {0} muss mindestens eine Frage beinhalten",
        question_weight_required: "Geben Sie die Gewichtung {0} an"
      },
      information:
        '<p class="body-1 mt-5">' +
        "Durch anklicken der KPI- & KOMPETENZkategorien können Sie KIP- und Kompetenzlisten." +
        "</p>" +
        '<p class="body-1">' +
        "Sie können eine auf die Fragen basierte Bewertung vornehmen, indem Sie die Gewichtung der KPI- und Kompetenzfragen in deren entsprechenden Kategorien angeben " +
        "</p>" +
        '<p class="body-1">' +
        "Die Gesamtsumme der Fragen sollte der Gesamtgewichtung der KPI und Kompetenz aus Ihrer Liste entsprechen." +
        "</p>",
      total_evaluatees_count: "Gesamtanzahl der Gutachter:Innen {0}",
      waiting_evaluates_finish: "{0} Person(en) warten auf ein Gutachten",
      complete_evaluation: "Dieses Gutachten wurde abgeschlossen",
      expiration_date: "Auslaufdatum",
      template_name: "Template Name",
      kpi: "KPI",
      kpi_category: "KPI Kategorie",
      kpis: "KPIs",
      competence: "Kompetenzen",
      competence_category: "Kompetenzkategorie",
      competences: "Kompetenzen",
      result_view: "Ergebnisse anzeigen",
      waiting: "in Bearbeitung",
      hourly: "stündlich",
      weekly: "wöchentlich",
      monthly: "monatlich",
      yearly: "jährlich",
      score: "Score",
      star: "Star",
      weight: "Gewicht",
      point: "Punkt",
      person: "Person",
      persons: "Personen",
      period_type: "Periode",
      score_type: "Score Typ",
      score_min_count: "minimaler Score",
      score_max_count: "maximaler Score",
      evaluation: "Aufgabe",
      evaluates: "Gutacher:Innen",
      evaluators: "Gutachter:In",
      self: "Ich",
      superior: "vorgesetzt",
      subordinates: "untergestellt",
      same: "gleich",
      flexible: "flexibel",
      period: "Periode",
      expiration_dates: "Auslaufdatum",
      expiration_begin_date: "Startdatum des Auslaufzeitraumes",
      expiration_end_date: "Enddatum des Auslaufzeitraumes",
      level: "Level",
      depth: "Tiefe",
      validation_date: "Validierungsdatum",
      validation_begin_date: "Beginndatum der Validierung",
      validation_end_date: "Enddatum der Validierung",
      published_date_time: "Datum der Veröffentlichtung",
      degree_360: "360 °",
      m2m: "M2M",
      published: "Start des Gutachtens",
      published_start: "Gutachten starten",
      evaluation_done: "Gutachten abschließen",
      are_you_sure: "Sind Sie sicher?",
      are_you_text: "Möchten Sie das Gutachten starten??",
      performance_item: {
        title: "Performance"
      },
      my_evaluatees: "meine Gutachter",
      my_evaluated: "meine Gutachten"
    },
    training: {
      plan: "Trainingsplan",
      name: "Trainingsname",
      type: "Trainingstyp",
      organizer: "Zuständige:r",
      lecturer: "Dozent:In",
      terminal: "Terminal",
      sessions: "Sitzungen",
      session: "Sitzung",
      training_type: {
        ONLINE: "Online",
        CLASSROOM: "Seminarraum",
        ON_SITE: "vor Ort"
      },
      training_detail: "Trainingsdetails",
      location: "Standort",
      description: "Beschreibung",
      training: "Training",
      request_training: "Request a Training",
      training_requests: "Training Requests",
      request_date: "Request Date",
      abolisher: "Abolisher",
      approval_history: "Approval History",
      cancelled_date: "Cancelled Date",
      hr_manager: "HR Manager",
      approval_date: "Approval Date",
      cancel_request: "Cancel Request",
      cancellation_note: "Cancellation note?",
      request_cancellation_process: "Training Request Cancellation Process",
      request_approval_process: "Training Request Approval Process",
      denied: "Deny",
      approved: "Approve",
      training_planned: "Is Training Planned"
    },
    expense: {
      name: "Kosten: Name",
      type: "Kosten: Typ",
      amount: "Kostenhöhe",
      date: "Datum der Anforderung",
      status: "Status",
      attachment: "Anhang",
      create_expense: "Ausgabenantrag stellen",
      travel: "Reise",
      approved_amount: "Höhe der Ausgaben wurde bestätigt",
      expense_type: {
        expense_type: "Ausgabentyp"
      },
      payment_type: {
        CASH: "Kasse",
        CREDIT_CARD: "Kreditkarte",
        PREPAID_CARD: "Company Credit Karte",
        OTHER: "Sonstiges",
        null: "Nicht spezifiziert"
      }
    },
    imprest: {
      amount: "Menge",
      period: "Zeitraum der Zahlstelle",
      reason: "Grund",
      status: "Status",
      create_imprest: "Zahlstellenantrag stellen",
      travel: "Reise",
      approved_amount: "Höhe der Zahlstelle wurde bestätigt",
      date: "Datum"
    },
    paper: {
      name: "Papiername",
      type: "Papiertyp",
      issue_date: "Ausgabedatum",
      expire_date: "Auslaufdatum",
      signature_status: "Signatur-Status",
      signature_types: {
        NOT_REQUIRED: "nicht benötigt",
        STANDARD_SIGNATURE: "Standardsignatur",
        BIOMETRIC_SIGNATURE: "Biometrische Signatur",
        SIGNED: "unterschrieben",
        null: "-",
        E_SIGNATURE: "E-Signatur"
      },
      add_paper: "Dokument hinzufügen",
      papers: {
        issue_date: "Ausgabedatum",
        expire_date: "Auslaufdatum"
      }
    },
    document: {
      name: "Dokumentenname",
      type: "Dokumententyp",
      sender: "Sender",
      date: "Datum",
      add_document: "Dokument hinzuüfgen",
      mobile_accessible_disable: "mobil erreichbar – deaktivieren",
      mobile_accessible_enable: "mobil erreichbar – aktivieren"
    },
    health: {
      name: "Name",
      category: "Kategorie",
      from: "Startdatum",
      to: "Enddatum",
      disease_detail: "Gesundheitsdetails",
      importance: "Priorität",
      importance_enum: {
        LOW: "gering",
        MEDIUM: "normal",
        HIGH: "hoch"
      },
      medicines: "Medikamente und Antibiotika",
      attachment: "Anhang",
      description: "Beschreibung"
    },
    debit_tickets: {
      asset: "Asset",
      borrow_date: "Ausleihdatum",
      due_date: "Abgabedatum",
      give_back_date: "Frist",
      quantity: "Anzahl",
      transfer: "Transfer",
      transfer_approval: "Übertragung Genehmigung",
      approver: "Mitarbeiter, der versetzt werden soll",
      debit_transfer: "Debit Ticket Transfer",
      download_form: "Formular herunterladen",
      DEBIT_TRANSFER_DUPLICATE_EXCEPTION: "There is a previously created transfer request for this debit ticket",
      DATA_INTEGRITY_VIOLATION_EXCEPTION: "This debit has already been registeref on you"
    },
    payroll: {
      month: "Monat",
      created_date: "Erstelldatum",
      file: "Datei"
    },
    events: {
      name: "Name",
      type: "Typ",
      owner: "Besitzer",
      online: "Online",
      begin_date: "Startdatum",
      end_date: "Enddatum",
      activity: "Aktivität"
    },
    expense_approval: {
      employee: "Mitarbeiter",
      description: "Beschreibung",
      amount: "Menge",
      status: "Status",
      request_date: "Antragsdatum",
      document: "Dokument",
      expense_approval: "Kostenbestätigung",
      message:
        "{0} hat eine Kostenanfrage in Höhe von <b>{3}</b> <b>{2}</b> on <b>{1}</b> beantragt. Möchten Sie dies bestätigen?"
    },
    imprest_approval: {
      employee: "Mitarbeiter",
      amount: "Menge",
      status: "Status",
      request_date: "Antragsdatum",
      imprest_approval: "Zahlstellenantrag",
      message:
        "{0} hat einen Zahlstellenantrag in Höhe von <b>{2}</b> <b>{1}</b> beantragt. Möchten Sie dies bestätigen?",
      approved_amount: "Approved Amount",
      requested_amount: "Requested Amount"
    },
    hiring: {
      job_posting: "Stellenanzeige",
      applicant: "Bewerber:In",
      applicants: "Bewerber:Innen",
      interview: "Interview",
      assessment_date: "Bewertungsdatum",
      working_date: "Arbeitsdatum",
      last_job: "letzte Beschäftigung",
      last_position_level: "letzte Position",
      evaluator: "Gutachter:In",
      evaluators: "Gutachter:Innen",
      weight: "Gewicht",
      link: "Link",
      skill: "Fähigkeiten",
      rate: "Rate",
      rating: "Bewertung",
      table: {
        applicant: "Bewerber:In",
        job_posting: "Stellenanzeige",
        last_job: "letzte Beschäftigung",
        assessment: "Aufgabe",
        rating: "Bewertung",
        assessment_date: "Bewertungsdatum",
        status: "Status",
        evaluators: "Gutachter:Innen",
        evaluator: "Gutachter:In"
      },
      skill_type: {
        soft: "soft",
        technical: "technisch",
        question: "Frage",
        link: "Link"
      }
    },
    message: {
      message_type: "Nachrichtentyp",
      sender: "Sender",
      receivers: "Empfänger",
      importance: "Priorität",
      title: "Titel",
      body: "Body",
      whom: "An wen?",
      date: "Datum",
      message_type_enum: {
        EMAIL: "E-mail",
        SMS: "Sms",
        IN_APP: "Benachrichtigung"
      },
      message_importance_enum: {
        LOW: "niedrig",
        MEDIUM: "normal",
        HIGH: "hoch"
      },
      send_message: "Nachricht senden",
      success_message: "{1} gesendet an {0} Personen | {1} gesendet an {0} Personen"
    },
    suggestion: {
      category: "Empfehlungskategorie",
      title: "Empfehlungen",
      add_suggestion: "Empfehlung hinzufügen",
      body: "Nachricht",
      owner: "Owner"
    },
    note: {
      notes: "Notizen",
      title: "Titel",
      body: "Body",
      attachment: "Anhang",
      description: "Beschreibung",
      add_note: "Notiz hinzufügen",
      owner: "Mitarbeiter",
      importance: "Priorität"
    },
    travel: {
      request_travel: "Reiseantrag erstellen",
      leave_request: "Urlaubsantrag stellen",
      travels: "Reisen",
      travel: "Reise",
      travel_detail: "Reisedetail",
      start_work_date: "Startdatum Arbeit",
      is_domestic: "inländische Reise?",
      country: "Land",
      city: "Stadt",
      region: "Region",
      is_rent_car: "Mietwagen?",
      is_shuttle: "Shuttle?",
      description: "Beschreibung",
      reason: "Grund",
      status: "Status",
      expense_message: "<strong>{0}</strong> requested to spend <strong>{2}</strong> on <strong>{1}</strong>",
      imprest_message:
        "<strong>{0}</strong> hat eine Vorauszahlung von <strong>{2}</strong> für <strong>{1}</strong> beantragt",
      subsistence_message:
        "Der Mitarbeiter <strong>{0}</strong> hat eine Pauschalsumme von <strong>{2}</strong> für <strong>{1}</strong> erhalten",
      travel_dates: "Reisedaten",
      pending_travel_approvals: "Reisebestätigung ausstehend",
      travel_process: "Reiseprozess",
      travel_details: "Reisedetails",
      no_detail_item: "Keine Details",
      add_new_detail: "Details hinzufügen",
      detail_type: "Detailtyp",
      detail_subtype: "Detailunterkategorie",
      travel_detail_type_enum: {
        TRANSFER: "Transfer",
        LAYOVER: "Layover",
        BUSINESS: "Business",
        TRAVEL_INSURANCE: "Travel Insurance"
      },
      travel_detail_subtype_enum: {
        BUS: "Bus",
        PLANE: "Flugzeug",
        TRAIN: "Zug",
        SHIP: "Schiff",
        CAR: "Auto",
        HOTEL: "Hotel",
        HOSTEL: "Hostel",
        VILLA: "Villa",
        FACTORY: "Fabrik",
        OFFICE: "Office",
        TRAVEL_HEALTH_POLICY: "Travel Health Policy"
      },
      begin_date: "Startdatum",
      end_date: "Enddatum",
      expenses: "Kosten",
      imprests: "Zahlenstellen",
      debits: "Abbuchungen",
      borrow_date: "Leihdatum",
      due_date: "Frist",
      give_back_date: "Rückgabedatum",
      quantity: "Menge",
      name: "Name",
      category: "Kategorie",
      model: "Modell",
      subsistences: "Pauschalsumme",
      project: "Projekt",
      attrition: "Fluktuationsrate",
      new_leave_request: "Request Time Off",
      adding_expense: "Adding Expense",
      adding_imprest: "Adding Imprest",
      adding_subsistence: "Adding Subsistence",
      adding_debit: "Adding Debit",
      assignment_form: "Assignment Form"
    },
    travel_request: {
      employee: "Mitarbeiter",
      begin_date: "Startdatum",
      end_date: "Enddatum",
      start_work_date: "Startdatum Arbeit",
      status: "Status",
      request_date: "Antragsdatum",
      travel_approval: "Reisebestätigung",
      message: "<b>{0}</b> hat einen Reiseantrag für <b>{1}</b> - <b>{2}</b> gestellt. Möchten Sie dies bestätigen?"
    },
    vehicle: {
      plate_number: "KFZ-Kennzeichen",
      brand: "Marke",
      model: "Modell",
      year: "Baujahr",
      branch: "Bereich",
      employee: "Mitarbeiter"
    },
    contact: {
      employee: "Mitarbeiter",
      email: "E-Mail",
      phone: "Telefon"
    },
    projects: {
      project: "Projekt",
      employee_count_message: "Keine Mitarbeiter | 1 Mitarbeiter | {count} Mitarbeiter"
    },
    debit_ticket_transfer_request: {
      requester: "Antragsteller",
      asset: "Anlagegut",
      approval_message:
        "<strong>{0}</strong> has requested to transfer the asset which has the following information to your embezzlement, do you want to confirm?"
    },
    survey: {
      name: "Survey Name",
      published_date: "Publish Date",
      end_date: "End Date",
      start_survey: "Start Survey",
      survey_privacy: "Survey Privacy",
      status: "Status",
      pending_title: "Surveys You Should Complete",
      completed_title: "Surveys I Participated",
      expired_title: "Expired Surveys",
      complete_survey: "Complete Survey",
      survey_information: "Survey Information",
      exit: "Exit",
      save_and_finish: "Save And Finish",
      status_enum: {
        completed: "Completed",
        continuous: "Continuous",
        expired: "Expired"
      },
      section_count: "Section Count",
      question_count: "Question Count",
      estimated_duration: "Estimated Duration",
      description: "Description",
      anonymous_on: "Closed Voting",
      anonymous_off: "Open Voting",
      enter_answer: "You can enter an answer",
      your_answer: "Your answer",
      please_select: "please select"
    },
    employee_requesting: "Requester",
    employee_creating: "Reporter"
  },
  feedback: {
    reasons: "Gründe",
    reason: "Grund",
    feeling: "Gesichtsausdruck",
    feeling_enum: {
      BAD: "schlecht",
      SAD: "traurig",
      NORMAL: "Normal",
      HAPPY: "fröhlich",
      ECSTATIC: "sehr glpcklich"
    },
    title: "Feedback {0} / {1}",
    comment: "Beschreibung"
  },
  menu: {
    dashboard: "Startseite",
    time: "Zeit",
    hr: "Human Resources",
    leave: "Urlaub/Abwesenheiten",
    clock_in_out: "Clock In / Out"
  },
  message: {
    saved_successfully: "Gespeichert",
    registration_failed: "Speichern fehlgeschlagen",
    updated_successfully: "erfolgreich aktualisiert",
    deleted_successfully: "erfolgreich gelöscht",
    download_email_report: "Click the download button to download the file",
    clock_ip_error: "IP-Adresse gehört nicht zu den zulässigen IP-Adressen",
    evaluation_date_range_error_message: "Sie befinden sich nicht im aktiven bewertungszeitraum",
    leave_calculate_error: "Rechenfehler",
    required_document_message: "There is document you need to upload",
    notice_period_error: "Dieser Urlaub muss mindestens {0} Tage im Voraus beantragt werden",
    max_consecutive_error: "Sie können bis zu {0} Urlaubstage auf einmal beantragen",
    min_consecutive_error: "Sie können {0} Urlaubstage auf einmal beantragen",
    leave_request_exist_error: "Es liegt ein Urlaubsantrag für diesen Zeitraum vor",
    total_leave_balance_error1: "Ihre Restbilanz ist nicht ausreichend für diesen Urlaubszeitraum",
    total_leave_balance_error2:
      "Ihre Restbilanz ist nicht ausreichend für diesen Urlaubszeitraum. Sie können ein Maximum von {0} restlichen Urlaubstagen aus Ihrer Bilanz entnehmen.",
    network_error: "Netzwerkfehler",
    no_record_found: "No Records Found",
    max_range_error: "You can choose {0} days at most!"
  },
  global: {
    comment: "Beschreibung",
    description: "Beschreibung",
    day: "Tag",
    note: "Notiz",
    employee: "Arbeitnehmer",
    address: "Adresse",
    explanation: "Grund/Beschreibung",
    hour: "Stunde",
    month: "Monat",
    phone: "Telefon",
    status: "Status",
    file: "Datei",
    greeting: "Guten Tag, {0}",
    search_for_employee: "Mitarbeiter suchen...",
    others: "Sonstiges",
    importance_enum: {
      LOW: "Gering",
      MEDIUM: "Mittel",
      HIGH: "Hoch"
    },
    importance: "Priorität",
    date: "Datum",
    loading_message: "Wird geladen …",
    birth_date: "Geburtsdatum",
    min_character_message: "Es müssen mindestens {0} Zeichen angegeben werden",
    no_employee_message: "Unter den angegeben Kriterien wurde kein Mitarbeiter gefunden.",
    employees: "Mitarbeiter",
    beginDate: "Startdatum",
    endDate: "Enddatum",
    beginTime: "Startzeit",
    endTime: "Endzeit",
    year: "Jahr",
    message: "Message",
    country: "Country",
    active_employees: "Active Employees",
    passive_employees: "Passive Employees",
    maxEmployeeCount: "Max Employee Count",
    years_old: "years old",
    print: "Print",
    expense_management: "Expense Management",
    paymentType: "Zahlungsart",
    employee_selected: "Select employee...|1 employee selected|{count} employees selected",
    shift_selected: "Select shift...|1 shift selected|{count} shifts selected",
    shifts_type: "{0} Shifts",
    total_hours: "Gesamtstunden",
    name: "Name",
    reason: "Reason",
    organization: "organization",
    minute: "Minute",
    from: "From",
    to: "To",
    shift: "Shift",
    uploaded_files: "Hochgeladene Dateien",
    manager: "Manager"
  },
  assessment: {
    very_poor: "ungenügend",
    poor: "nicht zufriedenstellend",
    normal: "Normal",
    good: "gut",
    very_good: "sehr gut",
    na: "keine Bewertung"
  },
  buttons: {
    home: "Startseite",
    ok: "OK",
    delete: "Löschen",
    back: "Zurück",
    save: "Speichern",
    update: "Aktualisieren",
    edit: "Bearbeiten",
    cancel: "Abbrechen",
    close: "Schließen",
    dont_save: "Abbrechen und Schließen",
    dont_save_continue: "Nicht Speichern Weiter",
    save_changes: "Speichern und Weiter",
    evaluation_finish: "Bewertung abschließen",
    assign: "beauftragen",
    add: "{0} hinzufügen",
    add_and_new: "Neu hinzufügen",
    refresh: "aktualisieren",
    filter: "Filter",
    export: "exportieren",
    create: "{0} erstellen ",
    create_and_new: "Neu erstellen",
    download: "downloaden",
    yes: "Ja",
    no: "Nein",
    clear: "Löschen",
    filter_results: "Ergebnisse filtern",
    delete_document: "Dokument löschen",
    scan: "Scannen",
    send_message: "Nachricht senden",
    send: "senden",
    apply: "ausführen",
    info: "Information",
    prev: "Zurück {0}",
    next: "Weiter {0}",
    list_table: "Tabellenmodus",
    list_card: "Kartenmodus",
    continue: "Weiter",
    show_all: "alle anzeigen",
    cancel_leave: "abbrechen und verlassen",
    select_all: "alle auswählen",
    upload: "hochladen",
    file_upload: "Datei hochladen",
    file_download: "Datei herunterladen",
    import: "importieren",
    upload_template: "Template hochladen",
    download_template: "Template herunterladen",
    trigger: "Trigger",
    action: "Aktion",
    send_registration_credentials: "Zugangsdaten senden",
    on: "An",
    off: "Aus",
    uploads: {
      employee: "Personal importieren",
      leave: "Abwesenheiten importieren"
    },
    multiple_delete_shift: "Bulk Shift Delete",
    multiple_upload_shift: "Bulk Shift Upload",
    multiple_actions: "Bulk Actions",
    send_email_and_sms_notification: "E-Mail und SMS-Benachrichtigungen senden",
    add_expense: "Kosten hinzufügen",
    add_imprest: "Zahlstelle hinzufügen",
    add_overtime: "Überstunden hinzufügen",
    add_leave_request: "Urlaubsantrag hinzufügen",
    evaluatee: "Gutachter:In",
    add_skill: "Skill hinzufügen",
    sign: "Zeichen",
    skip: "überspringen",
    back_to_home: "Back to Home",
    please_select: "Please select...",
    preview: "Vorschau"
  },
  response: {
    success: {
      ok: "Die Bedienun gist erfolgreich",
      update: "erfolgreich aktualisiert",
      delete: "erfolgreich gelöscht",
      send_sms_password_code: "Der Code zum Zurücksetzen des Passwortes wurde in einer SMS-Nachricht verschickt "
    },
    error: {
      user_not_found: "Benutzername oder Passwort nicht korrekt",
      network_error: "Netzwerkfehler"
    }
  },
  request_status: {
    PENDING: "Bestätigung ausstehend",
    pending: "Bestätigung ausstehend",
    approved: "bestätigt",
    APPROVED: "bestätigt",
    denied: "abgelehnt",
    DENIED: "abgelehnt",
    cancelled: "abgebrochen",
    CANCELLED: "abgebrochen",
    NEED_EXTRA_DETAIL: "Es werden zusätzliche Details benötigt",
    need_extra_detail: "Es warden zusätzliche Details benötigt",
    undefined: "undefiniert",
    STOPPED: "Stopped",
    stopped: "Stopped",
    not_come: "Not Come",
    NOT_COME: "Not Come"
  },
  shift_types: {
    FIX: "festgelegt",
    FLEXIBLE: "flexibel",
    OFF_DAY: "arbeitsfreier Tag",
    OFFDAY: "arbeitsfreier Tag",
    HOLIDAY: "Ferien",
    WORKDAY: "Arbeitstag",
    BREAK: "Pause",
    OVERTIME: "Überstunden",
    OVERTIME_SHIFT: "Überstunden",
    ON_LEAVE: "Im Urlaub"
  },
  shift: {
    overtime: "Überstunden",
    missing_time: "fehlende Stunden",
    shift_parameters: "Shift Parameters",
    break: "Pause",
    sub_events: "Sub Events",
    included_calculation: "In den Berechnungen enthalten",
    not_included_calculation: "Nicht in den Berechnungen enthalten",
    included_overtime_calculation: "Überstunden sind in den Berechnungen nicht enthalten",
    not_included_overtime_calculation: "Überstunden sind in den Berechnungen enthalten",
    not_included_missing_time_calculation: "Fehlende Arbeitsstunden sind in den Berechnungen nicht enthalten",
    included_missing_time_calculation: "Fehlende Arbeitsstunden sind in den Berechnungen enthalten",
    work_day: "Arbeitstag",
    overtime_day: "Tag mit Überstunden",
    full_time: "Vollzeit",
    absent: "abwesend",
    unpaid_leave: "unbezahlter Urlaub",
    paid_leave: "bezahlter Urlaub",
    public_holiday: "gesetzlicher Ferientag",
    on_leave: "im Urlaub",
    off_day: "arbeitsfreier Tag",
    break_day: "Pause",
    incalculable: "Schichtzeit lässt sich nicht erschließen"
  },
  sensors: {
    NFC: "NFC",
    FACE: "Gesicht",
    RFID: "RFID",
    FINGER: "Fingerabdruck",
    BLUETOOTH: "Bluetooth",
    GATEWARE: "Gateware",
    BEACON: "Beacon",
    GPS: "Standort",
    WIFI: "Wi-Fi",
    ID: "ID",
    QR: "QR",
    QR_CODE: "QR Code",
    PASSWORD: "Passwort",
    PIN: "PIN",
    CARD: "Karte",
    FIXED: "fixiert",
    EXTERNAL: "extern",
    JAN: "JAN",
    WEB: "Web"
  },
  identity_type: {
    FACE: "Gesicht",
    PASSWORD: "Passwort",
    PIN: "PIN",
    FINGER: "Fingerabdruck",
    QR_CODE: "QR Code",
    CARD: "Karte",
    EXTERNAL: "extern"
  },
  event_types: {
    BREAK_IN: "Pause Beginn",
    BREAK_OUT: "Pause Ende",
    LUNCH_IN: "Mittagspause Beginn",
    LUNCH_OUT: "Mittagspause Ende",
    DUTY_IN: "Dienstbeginn",
    DUTY_OUT: "Dienstende",
    CLOCK_IN: "Clock In",
    CLOCK_OUT: "Clock Out",
    UNKNOWN: "In oder Out",
    undefined: "unbekannt"
  },
  currency: {
    amount: "Menge",
    code: "Code",
    requested_amount: "angefragte Menge",
    approved_amount: "bestätigte menge",
    codes: {
      TRY: "TRY",
      USD: "USD",
      EUR: "EUR"
    }
  },
  titles: {
    expense: {
      create: "Ausgaben hinzufügen",
      detail: "Ausgabendetails"
    },
    imprest: {
      create: "Zahlstellen hinzufügen",
      detail: "Zahlstellendetails"
    }
  },
  language: {
    tr: "Türkisch",
    en: "Englisch",
    de: "Deutsch",
    TURKISH: "Türkisch",
    ENGLISH: "Englisch",
    GERMAN: "Deutsch"
  },
  status: {
    active: "Aktiv",
    passive: "Passiv",
    enable: "Aktiviert",
    disable: "Deaktiviert"
  },
  gender: {
    male: "männlich",
    female: "weiblich",
    other: "divers",
    others: "andere Angaben",
    MALE: "männlich",
    FEMALE: "weiblich",
    OTHER: "divers"
  },
  marital_status: {
    married: "verheiratet",
    single: "ledig",
    divorced: "geschieden"
  },
  education: {
    student: "Student:In",
    primary_school: "Grundschule",
    secondary_school: "weiterführende Schule",
    high_school: "Gymnasiale Oberstufe",
    collage: "Fachhochschule",
    university: "Universität",
    master: "Master",
    phd: "PhD",
    graduate: "Graduate",
    course: "Course",
    seminar: "Seminar",
    workshop: "Workshop",
    other: "Other"
  },
  education_languages: {
    turkish: "Türkisch",
    english: "Englisch",
    german: "Deutsch",
    french: "Französisch",
    dutch: "Niederländisch",
    spanish: "Spanisch",
    italian: "Italienisch",
    arabic: "Arabisch",
    russian: "Russisch",
    persian: "Persisch",
    chinese: "Chinesisch",
    japanese: "Japanisch",
    hindi: "Hindi",
    latin: "Latein",
    portuguese: "Portugiesisch",
    korean: "Koreanisch"
  },
  military_service: {
    done: "gedient",
    undone: "nicht gedient",
    exempt: "freigestellt",
    suspended: "suspendiert"
  },
  academic_title: {
    lecturer: "Dozent:In",
    research_assistant: "Research Assistant",
    assistant_professor: "Assistant Professor",
    associate_professor: "Associate Professor",
    professor: "Professor"
  },
  employment_type: {
    full_time: "Vollzeit",
    part_time: "Halbtags",
    casual: "nicht vertragsgebunden",
    contracted: "vertragsgebunden",
    freelance: "selbstständig",
    intern: "Intern",
    mobile: "mobil",
    retired: "Retired",
    trial: "Trial",
    seasonal: "Seasonal",
    disabled: "Disabled"
  },
  collar_type: {
    white: "weiß",
    blue: "blau",
    gray: "grau"
  },
  quite_job_reasons: {
    resignation: "Kündigung",
    cancellation_of_labor_contract: "Aufhebungsvertrag",
    military_service: "Militärdienst",
    other: "Andere Gründe"
  },
  blood_type: {
    o_rh_negative: "0-",
    o_rh_positive: "0+",
    a_rh_negative: "A-",
    a_rh_positive: "A+",
    b_rh_negative: "B-",
    b_rh_positive: "B+",
    ab_rh_negative: "AB-",
    ab_rh_positive: "AB+"
  },
  time_duration: {
    hour: "Stunde",
    minute: "Minute",
    second: "Sekunde"
  },
  date: {
    year: "Jahr",
    month: "Monat",
    day: "Tag",
    time: "Zeit",
    week: "Woche",
    minute: "Minute"
  },
  validations: {
    messages: {
      phone: "The {_field_} field must be a valid phone number",
      password_match: "Passwords don't match",
      integer: "The {_field_} field must be an integer"
    }
  },
  nationalities: {
    Afghan: "Afghan",
    Albanian: "Albanian",
    Algerian: "Algerian",
    American: "American",
    Andorran: "Andorran",
    Angolan: "Angolan",
    Antiguans: "Antiguans",
    Argentinean: "Argentinean",
    Armenian: "Armenian",
    Australian: "Australian",
    Austrian: "Austrian",
    Azerbaijani: "Azerbaijani",
    Bahamian: "Bahamian",
    Bahraini: "Bahraini",
    Bangladeshi: "Bangladeshi",
    Barbadian: "Barbadian",
    Barbudans: "Barbudans",
    Botswana: "Botswana",
    Belarusian: "Belarusian",
    Belgian: "Belgian",
    Belizean: "Belizean",
    Beninese: "Beninese",
    Bhutanese: "Bhutanese",
    Bolivian: "Bolivian",
    Bosnian: "Bosnian",
    Brazilian: "Brazilian",
    British: "British",
    Bruneian: "Bruneian",
    Bulgarian: "Bulgarian",
    Burkinabe: "Burkinabe",
    Burmese: "Burmese",
    Burundian: "Burundian",
    Cambodian: "Cambodian",
    Cameroonian: "Cameroonian",
    Canadian: "Canadian",
    "Cape Verdean": "Cape Verdean",
    "Central African": "Central African",
    Chadian: "Chadian",
    Chilean: "Chilean",
    Chinese: "Chinese",
    Colombian: "Colombian",
    Comoran: "Comoran",
    Congolese: "Congolese",
    "Costa Rican": "Costa Rican",
    Croatian: "Croatian",
    Cuban: "Cuban",
    Cypriot: "Cypriot",
    Czech: "Czech",
    Danish: "Danish",
    Djibouti: "Djibouti",
    Dominican: "Dominican",
    Dutch: "Dutch",
    "East Timorese": "East Timorese",
    Ecuadorean: "Ecuadorean",
    Egyptian: "Egyptian",
    Emirian: "Emirian",
    "Equatorial Guinean": "Equatorial Guinean",
    Eritrean: "Eritrean",
    Estonian: "Estonian",
    Ethiopian: "Ethiopian",
    Fijian: "Fijian",
    Filipino: "Filipino",
    Finnish: "Finnish",
    French: "French",
    Gabonese: "Gabonese",
    Gambian: "Gambian",
    Georgian: "Georgian",
    German: "German",
    Ghanaian: "Ghanaian",
    Greek: "Greek",
    Grenadian: "Grenadian",
    Guatemalan: "Guatemalan",
    "Guinea-Bissauan": "Guinea-Bissauan",
    Guinean: "Guinean",
    Guyanese: "Guyanese",
    Haitian: "Haitian",
    Herzegovinian: "Herzegovinian",
    Honduran: "Honduran",
    Hungarian: "Hungarian",
    "I-Kiribati": "I-Kiribati",
    Icelander: "Icelander",
    Indian: "Indian",
    Indonesian: "Indonesian",
    Iranian: "Iranian",
    Iraqi: "Iraqi",
    Irish: "Irish",
    Israeli: "Israeli",
    Italian: "Italian",
    Ivorian: "Ivorian",
    Jamaican: "Jamaican",
    Japanese: "Japanese",
    Jordanian: "Jordanian",
    Kazakhstani: "Kazakhstani",
    Kenyan: "Kenyan",
    "Kittian and Nevisian": "Kittian and Nevisian",
    Kuwaiti: "Kuwaiti",
    Kyrgyz: "Kyrgyz",
    Laotian: "Laotian",
    Latvian: "Latvian",
    Lebanese: "Lebanese",
    Liberian: "Liberian",
    Libyan: "Libyan",
    Liechtensteiner: "Liechtensteiner",
    Lithuanian: "Lithuanian",
    Luxembourger: "Luxembourger",
    Macedonian: "Macedonian",
    Malagasy: "Malagasy",
    Malawian: "Malawian",
    Malaysian: "Malaysian",
    Maldivan: "Maldivan",
    Malian: "Malian",
    Maltese: "Maltese",
    Marshallese: "Marshallese",
    Mauritanian: "Mauritanian",
    Mauritian: "Mauritian",
    Mexican: "Mexican",
    Micronesian: "Micronesian",
    Moldovan: "Moldovan",
    Monacan: "Monacan",
    Mongolian: "Mongolian",
    Moroccan: "Moroccan",
    Mosotho: "Mosotho",
    Motswana: "Motswana",
    Mozambican: "Mozambican",
    Namibian: "Namibian",
    Nauruan: "Nauruan",
    Nepalese: "Nepalese",
    "New Zealander": "New Zealander",
    Nicaraguan: "Nicaraguan",
    Nigerian: "Nigerian",
    Nigerien: "Nigerien",
    "North Korean": "North Korean",
    "Northern Irish": "Northern Irish",
    Norwegian: "Norwegian",
    Omani: "Omani",
    Pakistani: "Pakistani",
    Palauan: "Palauan",
    Panamanian: "Panamanian",
    "Papua New Guinean": "Papua New Guinean",
    Paraguayan: "Paraguayan",
    Peruvian: "Peruvian",
    Polish: "Polish",
    Portuguese: "Portuguese",
    Qatari: "Qatari",
    Romanian: "Romanian",
    Russian: "Russian",
    Rwandan: "Rwandan",
    "Saint Lucian": "Saint Lucian",
    Salvadoran: "Salvadoran",
    Samoan: "Samoan",
    "San Marinese": "San Marinese",
    "Sao Tomean": "Sao Tomean",
    Saudi: "Saudi",
    Scottish: "Scottish",
    Senegalese: "Senegalese",
    Serbian: "Serbian",
    Seychellois: "Seychellois",
    "Sierra Leonean": "Sierra Leonean",
    Singaporean: "Singaporean",
    Slovakian: "Slovakian",
    Slovenian: "Slovenian",
    "Solomon Islander": "Solomon Islander",
    Somali: "Somali",
    "South African": "South African",
    "South Korean": "South Korean",
    Spanish: "Spanish",
    "Sri Lankan": "Sri Lankan",
    Sudanese: "Sudanese",
    Surinamer: "Surinamer",
    Swazi: "Swazi",
    Swedish: "Swedish",
    Swiss: "Swiss",
    Syrian: "Syrian",
    Taiwanese: "Taiwanese",
    Tajik: "Tajik",
    Tanzanian: "Tanzanian",
    Thai: "Thai",
    Togolese: "Togolese",
    Tongan: "Tongan",
    "Trinidadian or Tobagonian": "Trinidadian or Tobagonian",
    Tunisian: "Tunisian",
    Turkish: "Turkish",
    Tuvaluan: "Tuvaluan",
    Ugandan: "Ugandan",
    Ukrainian: "Ukrainian",
    Uruguayan: "Uruguayan",
    Uzbekistani: "Uzbekistani",
    Venezuelan: "Venezuelan",
    Vietnamese: "Vietnamese",
    Welsh: "Welsh",
    Yemenite: "Yemenite",
    Zambian: "Zambian",
    Zimbabwean: "Zimbabwean"
  },
  disability_level: {
    first_level: "First Level",
    second_level: "Second Level",
    third_level: "Third Level"
  },
  social_security_type: {
    SS_4A: "4A",
    SS_4B: "4B",
    SS_4C: "4C"
  },
  clock_ip_error: "IP-Adresse gehört nicht zu den zulässigen IP-Adressen",
  days: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday"
  },
  api_error: {
    756: "Zwischen den von Ihnen gewünschten Daten liegt ein Urlaubsantrag vor",
    766: "Ihr Passwort muss mindestens 8 und höchstens 16 Zeichen lang sein",
    767: "Ihr Passwort muss Kleinbuchstaben, Großbuchstaben, Zahlen und Sonderzeichen enthalten: .-/@#$%^&_+=()",
    768: "Ihr Passwort darf nicht Ihren Vor- und Nachnamen oder Ihre Personalnummer enthalten",
    769: "Dieses Passwort wurde bereits verwendet. Bitte geben Sie ein anderes Passwort als Ihre letzten {0} Passwörter ein"
  },
  http: {
    200: "Successfully send",
    201: "Successfully",
    202: "Successfully saved",
    401: "Unauthorized",
    404: "Not found",
    409: "Data conflict",
    415: "Unsupportted Media Type",
    204: "Successfully",
    304: "Not Modified",
    500: "Internel Server Error",
    503: "Service Unavailable"
  },
  expense_items: "Expense Items",
  expense_item_name: "Expense Item Name",
  update_expense_item: "Update Expense Item",
  add_expense_item: "Add Expense Item",
  file_format_error: "File Format Error",
  file_size_error: "File Size Error",
  not_selected_expense_item_error_message: "You did not add an expense item",
  expense_description: "Expense Description",
  abolisher_employee: "Abolisher Employee",
  expense_manager: "Expense Manager",
  event_date: "Event Date",
  cancelled_date: "Cancellation Date",
  approval_history: "Approval History",
  request_processes: {
    super_admin: "Genehmigt vom Super Admin",
    approved: "Approved the request",
    pending: "Pending for approval",
    stopped: "Approval process stopped because the request was rejected",
    waiting: "It's not time for approval",
    denied: "Request denied"
  },
  sso_forgot_password_warning: {
    title: "Warning",
    message: "Please log in to Idenfit using your Accenture email."
  },
  time_abbr: {
    hour: "hr",
    minute: "min",
    second: "sec"
  },
  vedubox_user_not_found: "Vedubox User Not Found",
  gdpr: {
    acceptance: {
      title: "KVKK Açık Rıza Metni Onayı",
      checkbox: "KVKK Açık Rıza metnini onaylıyorum.",
      save: "Kaydet ve Devam Et",
      formError: "Devam edebilmek için KVKK Açık Rıza metnini onaylamanız gerekmektedir."
    }
  },
  web_terminal_loading: "Web terminal is loading",
  web_terminal_not_found: "Web terminal is not found",
  multiple_assigned_shift_warning: "You have more than one assigned shift. Please choose one of the shifts below.",
  paper_approval: "Papierfreigabe",
  paperApprovalProcessWarningMessage: "Sie müssen die Datei herunterladen oder in der Vorschau anzeigen",
  minLength: "Mindestens 8 Zeichen",
  hasLowerCaseAndUpperCase: "Enthält Ziffern, Groß- und Kleinbuchstaben",
  hasSpecialCharacter: "Enthält Sonderzeichen",
  passwordExpireNotificationMessage:
    "Es verbleiben 0 Tage bis zum Ablauf Ihres Passworts | Es verbleiben 1 Tag bis zum Ablauf Ihres Passworts | Es verbleiben {count} Tage bis zum Ablauf Ihres Passworts",
  pendingRequests: "Ausstehende Anfragen",
  allPendingRequests: "Alle ausstehenden Anfragen",
  maxImprestAmountMessage: "Maximales Limit, das angefordert werden kann: {0}",
  or: "oder",
  continueWithMicrosoft: "Weiter mit Microsoft"
};
