import "@babel/polyfill";
import Vue from "vue";
import App from "./App.vue";
import store from "@store";
import apollo from "./plugins/apollo";
import i18n from "./plugins/i18n";
import registerGlobalComponents from "./components";
import serviceContext from "./http/api/services/serviceContext";
import hiringService from "./http/api/services/hiringService";
import registerPlugins from "./plugins";
import registerRouter from "./router";
import registerConfig from "./base/config";
import registerHelpers from "./helpers";
import http from "./http";
import "@assets/scss/app.sass";
import "@assets/material-design/css/materialdesignicons.min.css";

const app = {
  store,
  render: h => h(App),
  beforeCreate() {
    http.setup();
  }
};

registerPlugins({ app, Vue });
registerRouter({ app, Vue });
registerHelpers({ Vue });
serviceContext({ Vue });
hiringService({ Vue });
registerConfig({ Vue });
registerGlobalComponents({ Vue });
apollo({ app, Vue });
i18n({ app });
new Vue(app).$mount("#app");
