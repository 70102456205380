import HttpStatusEventBus from "../httpStatusEventBus";
import { NO_CONTENT } from "http-status-codes";
import { eventBus } from "../../../../plugins/event-bus";

function noContent() {
  HttpStatusEventBus.$on(NO_CONTENT.toString(), response => {
    if (response.config.url.includes("/storage/multiple")) return;
    let notification_credentials = {
      text: "deleted_successfully",
      color: "success",
      type: "success"
    };
    eventBus.$emit("snack-bar-notification", notification_credentials);
  });
}

export default noContent();
