import HttpStatusEventBus from "../../httpStatusEventBus";
import { CONSTRAINT_VIOLATION } from "../index";
import { eventBus } from "../../../../../plugins/event-bus";

function constraint_violation() {
  HttpStatusEventBus.$on(CONSTRAINT_VIOLATION.toString(), response => {
    let notification_credentials = {
      text: "constraint_violation",
      color: "error",
      type: "error"
    };
    eventBus.$emit("snack-bar-notification", notification_credentials);
  });
}

export default constraint_violation();
